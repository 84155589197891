%link-header {
  border: none;
  background-color: transparent;
  color: $color-link-header;
  font-family: $font-family-body;
  font-size: $font-size-header-m;
  font-weight: 700;
  line-height: 1.6;
  text-transform: uppercase;
  text-align: left;
  transition: all 0.5s ease;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: $color-link-header-hover;
  }

  @include media-breakpoint-up(xl) {
    font-size: $font-size-header-s;
  }
}

%link-subheader {
  @extend %link-header;
  color: $color-link-subheader;
  font-size: $font-size-subheader;
  font-weight: 400;
  text-transform: capitalize;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: $color-link-subheader-hover;
  }
}

%link-topheader {
  @extend %link-subheader;
  color: $color-link-topheader;
  font-size: $font-size-topheader;
  line-height: 1.7;
  display: block;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: $color-link-topheader-hover;
  }
}
//==============================================================================
// TEXT & HEADING COMPONENTS
//==============================================================================

%text-footer {
  color: $color-text-footer;
  font-family: $font-family-footer;
  font-size: $font-size-footer-m;
  font-weight: 400;
  line-height: 1.6;
}

%text-footer-heading {
  @extend %text-marketing-h4;
  color: $color-text-footer;
}

%text-footer-nav,
%text-footer-links {
  @extend %text-footer;
}

%text-footer-legal,
%text-footer-copyright {
  @extend %text-footer;
  font-size: $font-size-footer-s;
}

//==============================================================================
// LINK & BUTTON COMPONENTS
//==============================================================================

%link-footer-nav {
  color: $color-link-footer;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: $color-link-footer-hover;
  }
}

%link-footer-links {
  @extend %link-marketing;
  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: $color-link-footer-links-hover;
  }
}

%link-footer-social {
  color: $color-link-footer-social;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: $color-link-footer-social-hover;
  }
}

%link-footer-legal {
  color: $color-link-footer-legal;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: $color-link-footer-legal-hover;
  }
}

//==============================================================================
// GENERAL COMPONENTS
//==============================================================================

%image-marketing {
  display: block;
  max-width: 100%;
  height: auto;
  object-fit: cover;
  width: 100%;
}

%image-marketing-max {
  @extend %image-marketing;
  width: 100%;
}

//==============================================================================
// SIZE COMPONENTS
//==============================================================================

%width-marketing {
  max-width: $max-width-page;
}

%width-marketing-max {
  @extend %width-marketing;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@mixin width-marketing-centered($breakpoint: lg) {
  @include media-breakpoint-up($breakpoint) {
    margin-left: auto;
    margin-right: auto;
  }
}

%width-marketing-margin {
  @extend %width-marketing;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;

  @include media-breakpoint-up(sm) {
    width: calc(100% - 4rem);
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @include media-breakpoint-up(lg) {
    width: $width-content;
  }

  @include width-marketing-centered;
}

%width-marketing-content {
  max-width: $max-width-content;

  @include media-breakpoint-up(sm) {
    width: $width-content;
    margin-left: auto;
    margin-right: auto;
  }
}

%width-marketing-hero-home {
  @extend %width-marketing-max;
}

%width-marketing-hero-top {
  @extend %width-marketing-max;
  position: relative;

  .ms-content-block__image {
    position: absolute;
    width: 100%;
  }

  img {
    @extend %image-marketing-max;
    height: 500px;

    @include media-breakpoint-up(sm) {
      height: 575px;
    }

    @include media-breakpoint-up(md) {
      height: 675px;
    }

    @include media-breakpoint-up(lg) {
      height: 775px;
    }
    @include media-breakpoint-up(xl) {
      height: 875px;
    }
  }
}

%width-marketing-hero-collection {
  @extend %width-marketing-max;
  position: relative;

  img {
    @extend %image-marketing-max;
    height: auto;
    min-height: 250px;

    @include media-breakpoint-up(sm) {
      min-height: 350px;
    }

    @include media-breakpoint-up(md) {
      height: 100vh;
      min-height: 500px;
      object-position: 65% top;
    }

    @include media-breakpoint-up(lg) {
      min-height: 600px;
      object-position: center;
    }

    @include media-breakpoint-up(xl) {
      min-height: 700px;
    }
  }
}

%height-marketing {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

//==============================================================================
// TEXT & HEADING COMPONENTS
//==============================================================================

%text-marketing {
  color: $color-text-body;
  font-family: $font-family-body;
  font-size: $font-size-body-s;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0;
  text-transform: none;

  @include media-breakpoint-up(xl) {
    font-size: $font-size-body-m;
  }

  &-inverse {
    @extend %text-marketing;
    color: $color-text-inverse;
  }
}

%text-marketing-featured {
  color: $color-text-body;
  font-family: $font-family-featured;
  font-size: $font-size-featured-m;
  font-weight: 400;

  @include media-breakpoint-up(xl) {
    font-size: $font-size-heading-m;
  }

  &-inverse {
    @extend %text-marketing-featured;
    color: $color-text-inverse;
  }
}

%text-marketing-h5,
%text-marketing-h6 {
  @extend %text-marketing-featured;

  &-inverse {
    @extend %text-marketing-featured-inverse;
  }
}

%text-marketing-h5,
%text-marketing-h5-inverse {
  font-size: $font-size-featured-m;
  line-height: 1.2;

  @include media-breakpoint-up(xl) {
    font-size: $font-size-featured-l;
  }
}

%text-marketing-h6,
%text-marketing-h6-inverse {
  font-size: $font-size-featured-s;
  line-height: 1.6;
}

%text-marketing-heading-title {
  color: $color-heading-title;
  font-family: $font-family-heading;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;

  &-inverse {
    @extend %text-marketing-heading-title;
    color: $color-heading-title-inverse;
  }
}

%text-marketing-h1,
%text-marketing-h3 {
  @extend %text-marketing-heading-title;

  &-inverse {
    @extend %text-marketing-heading-title-inverse;
  }
}

%text-marketing-h1,
%text-marketing-h1-inverse {
  font-size: $font-size-heading-m;
  line-height: 1.1;

  @include media-breakpoint-up(xl) {
    font-size: $font-size-heading-xl;
  }
}

%text-marketing-h3,
%text-marketing-h3-inverse {
  font-size: $font-size-heading-s;
  line-height: 1.3;
  letter-spacing: 1px;
}

%text-marketing-heading-subtitle {
  color: $color-heading-subtitle;
  font-family: $font-family-heading;
  font-size: $font-size-heading-m - 4px;
  font-weight: 400;
  line-height: 1.25;

  @include media-breakpoint-up(xl) {
    font-size: $font-size-heading-m;
  }

  &-inverse {
    @extend %text-marketing-heading-subtitle;
    color: $color-heading-subtitle-inverse;
  }
}

%text-marketing-h2 {
  @extend %text-marketing-heading-subtitle;

  &-inverse {
    @extend %text-marketing-heading-subtitle-inverse;
  }
}

%text-marketing-heading-appellation {
  color: $color-heading-appellation;
  font-family: $font-family-heading;
  font-size: $font-size-heading-xs;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 1px;
  text-transform: uppercase;

  &-inverse {
    @extend %text-marketing-heading-appellation;
    color: $color-heading-appellation-inverse;
  }
}

%text-marketing-h4 {
  @extend %text-marketing-heading-appellation;

  &-inverse {
    @extend %text-marketing-heading-appellation-inverse;
  }
}

//==============================================================================
// LINK & BUTTON COMPONENTS
//==============================================================================

%btn-marketing {
  display: inline-block;
  padding: 0.8rem 2rem;
  border: 1px solid $color-button-border;
  background-color: $color-button-background;
  color: $color-button-text;
  font-family: $font-family-link;
  font-size: $font-size-link-m;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 1.2;
  text-transform: uppercase;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
    border-color: $color-button-border-hover;
    box-shadow: none;
    background-color: $color-button-background-hover;
    color: $color-button-text-hover;
  }
}

%btn-marketing-inverse {
  @extend %btn-marketing;
  border-color: $color-button-inverse-border;
  background-color: $color-button-inverse-background;
  color: $color-button-inverse-text;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
    border-color: $color-button-inverse-border-hover;
    background-color: $color-button-inverse-background-hover;
    color: $color-button-inverse-text-hover;
  }
}

%link-marketing {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 0;
  border: none;
  background-color: transparent;
  color: $color-link;
  font-family: $font-family-link;
  font-size: $font-size-link-m;
  font-weight: 700;
  letter-spacing: 0.75px;
  line-height: 1.5;
  text-transform: uppercase;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
    background-color: transparent;
    color: $color-link-hover;
  }
}

%link-marketing-inverse {
  @extend %link-marketing;
  color: $color-link-inverse;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: $color-link-inverse-hover;
  }
}

%link-shop {
  padding: 0;
  border: 0;
  background-color: transparent;
  color: $color-link--shop;
  font-family: $font-family-body;
  font-size: $font-size-body-s;
  letter-spacing: 0;
  text-transform: none;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: $color-link-hover--shop;
  }
}

%link-shop-subfooter {
  @extend %link-shop;
  font-size: $font-size-body-xs;
  font-weight: bold;
}

%link-shop-muted {
  @extend %link-shop;
  color: $color-link-muted;

  &:hover,
  &:focus,
  &:active {
    color: $color-link-muted-hover;
  }
}

%text-shop-heading-container {
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 2rem;
  border-bottom: 1px solid $color-border--shop;
}

%text-shop-heading-primary {
  color: $color-heading-title--shop;
  font-family: $font-family-heading--shop;
  font-size: $font-size-heading-shop-l;
  text-transform: uppercase;
}

%text-shop-heading-secondary {
  @extend %text-shop-heading-primary;
  font-size: $font-size-heading-shop-m;
  text-transform: none;
}

%text-shop-heading-tertiary {
  @extend %text-shop-heading-secondary;
  font-size: $font-size-heading-shop-s;
}

%text-shop-subheading {
  font-size: $font-size-body-s;
  text-transform: uppercase;
}

%text-shop-strong {
  color: $color-text-body--shop;
  font-family: $font-family-body;
  font-size: $font-size-body-m;
  font-weight: bold;
  text-transform: none;
}

%input-shop {
  min-height: 30px;
  padding: 0.25rem 0.5rem;
  border: 1px solid $color-input-border;
  border-radius: 0;
  background-color: $color-input-background;
  color: $color-input-text;
  font-family: $font-family-body;
  font-size: $font-size-body-m;
}

%input-shop-qty {
  @extend %input-shop;
  flex: none;
  width: 50px;
  height: 30px;
  padding: 0.25rem;
  text-align: center;
}
%col-12 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 100%;
  max-width: 100%;
}
%default-btn {
  display: inline-block;
  font-weight: 400;
  color: $color-black;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 0;

  &:not(:disabled) {
    cursor: pointer;
  }
}
%input-error {
  font-family: $font-family-body;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-transform: none;
  border-radius: 0.25rem;
  position: relative;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  border: 1px solid transparent;
  color: $color-font-error;
  background-color: $color-bg-error;
  border-color: $color-border-error;
  margin-top: 8px;
  display: block;
}
%input-warning {
  @extend %input-error;
  color: $warning-alert-color;
  background-color: $warning-alert-background-color;
  border-color: $warning-alert-border-color;
}
%error-alert {
  position: relative;
  padding: $error-alert-padding;
  margin-bottom: $error-alert-margin-bottom;
  border: 1px solid transparent;
  border-radius: $error-alert-border-radius;
  color: $error-alert-color;
  background-color: $error-alert-background-color;
  border-color: $error-alert-border-color;
  font-size: $font-size-body-m;
}
%warning-alert {
  @extend %error-alert;
  color: $warning-alert-color;
  background-color: $warning-alert-background-color;
  border-color: $warning-alert-border-color;
}