$tile-padding-bottom-mobile: 48px;
$tile-first-margin-left: 0px;
$tile-last-margin-right: 0px;
$tile-last-padding-bottom-mobile: 0px;
$tile-link-margin-left-right: 24px;

.product-widget {
  .ms-content-block__cta {
    margin-top: 1rem;
    & a:link,
    & a:visited {
      color: $color-pampas;
      letter-spacing: 0.85px;
      transition: all 0.3s;
      display: inline-block;
      font-weight: 400;
      border: 1px solid $color-lightchoco;
      line-height: 1.5;
      padding: 0.4rem 0.8rem 0.5rem;
      background-color: $color-lightchoco;
      font-size: 12.5px;
    }
    & a:hover {
      background-color: $color-pharlap;
      border-color: $color-pharlap;
      color: $color-pampas;
      font-size: 12.5px;
    }
    & a:active {
      background-color: $color-woodybrown;
      border-color: $color-woodybrown;
      color: $color-pampas;
      font-size: 12.5px;
    }
  }
}

.ms-content-block.texttheme__light {
  

  &[data-m-layout="tile"] {
    margin: auto;
    width: fit-content;
    .ms-content-block__details,
    &.textplacement__left .ms-content-block__details {
      text-align: left;
      .ms-content-block__cta {
        a[class^="msc-cta__"] {
          margin-left: $tile-link-margin-left-right;
          &:first-child {
            margin-left: $tile-first-margin-left;
          }
        }
      }
    }
    &.textplacement__right .ms-content-block__details {
      text-align: right;
      .ms-content-block__cta {
        a[class^="msc-cta__"] {
          margin-right: $tile-link-margin-left-right;
          &:last-child {
            margin-right: $tile-last-margin-right;
          }
        }
      }
    }
    &.textplacement__center .ms-content-block__details {
      text-align: center;
      .ms-content-block__cta {
        a[class^="msc-cta__"] {
          margin-left: $tile-link-margin-left-right / 2;
          margin-right: $tile-link-margin-left-right / 2;
          &:first-child {
            margin-left: $tile-first-margin-left;
          }
          &:last-child {
            margin-right: $tile-last-margin-right;
          }
        }
      }
    }
    @media (max-width: $msv-breakpoint-m) {
      padding-bottom: $tile-padding-bottom-mobile;
      width: 100%;
      & .ms-content-block__image img {
        width: 100%;
      }
    }
  }
}

.col-sm:first-child .ms-content-block[data-m-layout="tile"]:first-child {
  margin-left: $tile-first-margin-left;
}

.col-sm:last-child .ms-content-block[data-m-layout="tile"]:last-child {
  margin-right: $tile-last-margin-right;
}

@media (max-width: $msv-breakpoint-m) {
  &.col-sm:last-child .ms-content-block[data-m-layout="tile"]:last-child {
    padding-bottom: $tile-last-padding-bottom-mobile;
  }
}
