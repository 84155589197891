.ms-order-details__wine-club {
    &-pickup {

        .ms-order-details__address-full-address,
        .ms-order-details__tracking-info {
            display: none;
        }
    }

    &-group {

        .msc-cart-line__product-image,
        .msc-cart-line__quantity,
        .ms-order-details__sales-line-btn-buy-it-again,
        .msc-cart-line__product-variants {
            display: none !important;
        }

        .ms-order-details__sales-status,
        .msc-cart-line__content {
            margin-left: 0 !important;
        }

        .ms-order-details__sales-status {
            display: block;
            padding-bottom: 1.5rem;

            @media (min-width: $msv-breakpoint-m) {
                padding-top: 1rem;
                padding-bottom: 0;
            }
        }

        .ms-order-details__sales-line {
            padding-bottom: 0;

            @media (min-width: $msv-breakpoint-m) {
                padding-bottom: 1rem;
            }
        }

        .msc-cart-line__product-title {
            pointer-events: none;
        }
    }
    &-value {
        margin-left: 5px;
    }
}