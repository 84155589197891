footer {
  @extend %text-footer;
  background-color: $color-background-footer;
}

.footer {
  @extend %width-marketing-content;
  padding: 2rem 1rem 0;

  @include media-breakpoint-up(sm) {
    padding: 3.6rem 0 0;
  }

  p {
    margin: 0;
  }
}
ul.footer-top {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  @include media-breakpoint-up(lg) {
    margin-bottom: 3rem;
  }
  li.footer-top {
    flex: 0 0 100%;
    max-width: 100%;
    &:first-child {
      @include media-breakpoint-up(md) {
        margin-bottom: 1.5rem;
      }
      @include media-breakpoint-up(lg) {
        max-width: 33%;
        flex: 0 0 33%;
        margin-bottom: 0;
        margin-right: 25px;
        top: 0.3rem;
        right: 0;
      }
      @include media-breakpoint-up(xl) {
        // max-width: 27.2%;
        // flex: 0 0 27.2%;
        flex: 0 1 75%;
        max-width: 75%;
      }
    }
    &:not(:first-child) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include media-breakpoint-up(lg) {
        // flex: 0 1 75%;
        // max-width: 75%;
        max-width: 22.5%;
        flex: 0 0 22.5%;        
        margin-right: auto;
      }
    }
    &:nth-child(3) {
      margin-top: 1rem;
      flex: 0 1 75%;
      max-width: 75%;
    }
  }
  .footer-mailing-list {
    max-width: 100%;
    flex: 0 0 100%;
    @include media-breakpoint-up(sm) {
      max-width: 50%;
      flex: 0 0 50%;
    }
    @include media-breakpoint-up(md) {
      max-width: 40%;
      flex: 0 0 40%;
    }
    @include media-breakpoint-up(lg) {
      max-width: 100%;
      flex: 0 0 100%;
    }
    h5 {
      color: $color-heading-title-inverse-accent;
      font-size: 1.25rem;
      text-transform: uppercase;
      margin-top: 1rem;
    }
    p {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  ul.footer-nav {
    @include media-breakpoint-up(md) {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    li.footer-nav {
      &:first-child {
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 1rem;
        @include media-breakpoint-up(lg) {
          flex: 0 1 30%;
          max-width: 30%;
          margin-bottom: 0;
        }
      }
      &:not(:first-child) {
        @include media-breakpoint-up(md) {
          flex: 0 1 30%;
          max-width: 30%;
        }
        @include media-breakpoint-up(lg) {
          flex: 0 1 20%;
          max-width: 20%;
        }
      }
      h4 {
        color: $color-kabul;
        @extend %text-footer-heading;
        margin: 1.5rem 0 0.75rem;

        @include media-breakpoint-up(md) {
          margin: 0 0 0.65rem;
        }
      }
      .ms-footer__item {
        margin-top: 0.5rem;
        @include media-breakpoint-up(md) {
          @include space(padding, 0.35rem 0);
          border-width: 0 0 1px;
          border-style: solid;
          border-color: $color-border-inverse;
          margin-top: 0;
        }
        a {
          @extend %link-footer-nav;
        }
        span {
          color: $color-link-footer;
          @extend %text-footer;
          @extend %link-footer-nav;
        }
      }
      .ms-footer__list-item a {
        @extend %link-footer-nav;
      }
    }
  }
}
ul.footer-bottom {
  position: relative;
  padding: 1.5rem 0;
  border-top: 1px solid $color-swisscoffee;
}
.footer-connect {
  display: flex !important;
}
ul.footer-connect {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include media-breakpoint-up(lg) {
    width: 100%;
  }
}
li.footer-connect {
  flex: 0 0 100%;
  a {
    @extend %link-footer-links;
    color: $color-darklimedoak;
    font-size: $font-size-link-m;
    letter-spacing: 0.5px;
    margin-top: 0.5rem;
    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active:focus {
      color: $color-link-footer-links-hover;
    }
    @include media-breakpoint-up(lg) {
      margin: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    flex: 0 1 auto;
  }
  &:first-child {
    order: 1;
    @include media-breakpoint-up(lg) {
      flex: 0 1 auto;
    }
  }
  &:nth-child(2) {
    display: flex;
    flex-wrap: wrap;
    order: 3;
    margin-top: 1rem;
    @include media-breakpoint-up(lg) {
      order: 2;
      margin-top: 0;
      margin-left: 1rem;
    }
    .ms-footer__link {
      margin: 0 1rem 0 0;
    }
  }
  &:last-child {
    order: 2;
    @include media-breakpoint-up(lg) {
      order: 3;
    }
  }
}
ul.footer-bottom {
  position: relative;
  padding: 1.5rem 0 0.5rem 0;
  border-top: 1px solid $color-border-inverse;
  li.footer-bottom {
    ul.footer-links {
      @extend %text-footer-legal;
      li.footer-links {
        margin-bottom: 0.5rem;
      }
      @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
        li.footer-links {
          margin-bottom: 0;
        }
      }
      span {
        @extend %link-footer-legal;
        cursor: pointer;
        margin-right: 1rem;
      }
    }
  }
  ul.copy-right {
    font-size: 12.5px;
  }
  li.footer-bottom {
    &:last-child {
      margin-top: 1rem;
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 1.5rem;
      }
    }
    &:nth-child(2) {
      margin-top: 0.25rem;
    }
  }
  li.footer-enjoy {
    img {
      max-width: 140px;
      height: auto;
    }
  }
}
li.visit-us {
  @include media-breakpoint-up(lg) {
    padding: 0.35rem 0;
    p {
      padding: 0.35rem 0;
    }
  }
}
.shop-footer {
  ul.footer-bottom {
    li.footer-bottom {
      &:last-child {
        position: relative;
      }
    }
  }
}
