.lp-msc-carousel-strip-landing {
    background-color: #ffffff;
}
.lp-content-marketing-double-right {
    margin: 6rem 20rem;
    max-width: 80%;
}
.lp-landing-hero {
    text-align: right;
}
.lp-content-block-with-title-text {
    font-size: 22px;
}
.lp-ms-content-block__text {
    font-size: 22px;
    color:#93989c;
}
.lp-msc-ss-carousel-strip .msc-flipper {
    color: $color-white;
    top: -50px;
}
.lp-msc-carousel-strip,
.lp-msc-ss-carousel-strip {
    background-color:white;
    width: 100%;
    height: auto;
}
.lp-content-marketing-promo-double .container-with-header-slots {
    background-color: #f1ece9;
}

.lp-msc-ss-carousel-strip.ms-product-collection {
    @extend %content-marketing-promo;
    background-color: $color-background-body--shop;
    padding: 1rem 1.6rem 0;
    margin-top: 5rem;
    margin-bottom: 0;

    .ms-product-collection__item:first-child {
        padding-left: 0;
    }

    .msc-ss-carousel {
        padding-top: 0;

        &-slide {
            margin-bottom: 0;
        }

        &-item {
            white-space: normal;
        }
    }
}
