$font-age-gate: "Arial", "Helvetica", sans-serif;
$color-age-gate: rgb(20, 20, 20);
$color-age-gate-hover: #646464;
$color-age-gate-button: #6c757d;
$color-age-gate-link: #141414;
$age-gate-container-max-width: 555px;
$age-gate-container-max-height: 615px;
$age-gate-dialogue-max-width: 915px;
$age-gate-dialogue-padding-mobile: 2rem 3rem;
$age-gate-dialogue-padding-desktop: 2rem 5rem;
$width-page: 100%;

.modal-open .age-gate-modal .modal-dialog {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50.1%) !important;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  min-height: 0;
  max-width: $age-gate-dialogue-max-width;
  max-height: $age-gate-container-max-height;
  background-color: $color-white;
  padding: $age-gate-dialogue-padding-mobile;
  @include media-breakpoint-up(md) {
    padding: $age-gate-dialogue-padding-desktop;
  }
  .modal-content {
    border: 0;
    .modal-body {
      padding: 0;
    }
  }
}
.age-gate-container {
  text-align: center;
  padding-top: 20px;
  max-width: $age-gate-container-max-width;
  max-height: $age-gate-container-max-height;

  h4,
  .h4 {
    font-size: 14px;
    color: $color-age-gate;
    font-family: $font-age-gate;
  }

  h2,
  .h2 {
    font-size: 18px;
    color: $color-age-gate;
    font-family: $font-age-gate;
    text-transform: uppercase;
    font-weight: bold;
  }
}
.age-gate-logo {
  max-width: $width-page;
  height: auto;
  margin-bottom: 25px;
}
.age-gate-button {
  margin-top: 15px;
  width: 150px;
  padding: 10px 30px;
  font-weight: bold;
  font-family: $font-age-gate;
  line-height: 1.4;
  border: 1px solid $color-age-gate-button;
  color: $color-age-gate-button;
  &:hover {
    color: $color-white;
    .age-gate-button-text {
      color: $color-white;
    }
  }
}
.age-gate-button-text {
  font-size: 12px;
  font-weight: normal;
  text-transform: uppercase;
  display: block;
  color: $color-age-gate-button;
}

.age-gate-links {
  display: flex;
  flex-direction: column;
  color: $color-age-gate-link;
  font-family: $font-age-gate;
  font-size: 11px;
  line-height: 1.75;

  &:not(:empty) {
    margin-top: 1.5rem;
  }

  a {
    color: $color-age-gate-link;
    text-decoration: underline;
    transition: all 0.3s ease;

    &:hover,
    &:focus,
    &:active {
      color: $color-age-gate-hover;
      text-decoration: underline;
    }
  }
}