.mailing-list-popup {
  text-align: center;

  .ms-content-block {
    min-height: 0;
    padding: 3rem 0 0;
  }

  img {
    margin: 0 auto 2rem;
  }

  .msc-modal__dialog {
    max-width: 500px !important;
    background-color:  $color-background-inverse !important;
  }

  .msc-modal__content {
    background-color: $color-background-inverse !important;
    .msc-modal__header {
      padding: 0;
    }

    .msc-modal__body {
      padding: 0 3rem 3rem;
    }
    .msc-modal__close-button {
        right: 5px !important;
    }
  }

  .mailing-list-form-marketing-description {
    font-size: $font-size-body-m;
    .content-title {
        color: $color-text-accent--shop;
        font-size: 1.5rem;
    }
    p {
        color: $color-text-inverse;
    }
  }

  .mailing-list-form-marketing-title,
  .mailing-list-form-marketing-subtitle {
    color: $color-link;
  }
  .text-input-validation-error,
  .mailing-list-success-message p {
    @extend .alert-custom;
  }

  .text-input-validation-error {
    @extend .alert-custom-danger;
    padding: 0.5rem 0.75rem 0.3rem;
    margin-top: 0.5rem;
    font-size: $font-size-body-m;
  }

  .mailing-list-success-message p {
    display: inline-block;
  }

  .text-input-group {
    text-align: left;

    .form-control {
      @include form-input-el();
    }

    label {
      margin-bottom: 0.25rem;
      color: $color-text-inverse;
      font-weight: bold;
    }
  }

  .input-group-mailing-list-button {
    @extend .btn;
    @extend .btn-primary;
    margin-top: 1rem;
  }

  .popup-modal-close-text {
    margin-top: 1.5rem;
    display: block;
    color: $color-text-muted;
    font-size: $font-size-body-m;
    cursor: pointer;
  }
}
