.tasting-tours {
    .errorBox {
        &[hidden] {
            display: block !important;
        }

        &:empty {
            display: none !important;
        }
    }

    &-hidden {
        display: none;
    }

    &-button {
        @extend %btn-marketing-inverse;
        display: block;
        transition: all 0.3s ease;
    }

    &-consent-legal {
        font-size: $font-size-body-xxs;
    }
}
