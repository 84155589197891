.ms-header {
  background-color: $color-background-header;
  &__container {
    position: relative;
    background-color: $color-background-header;
    max-width: $max-width-content;
    padding: 0;

    @include media-breakpoint-up(lg) {
      width: $width-content;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__topbar {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 0;
  }
  &__logo {
    position: absolute;
    z-index: 55;
    top: $msv-logo-top-margin;
    left: 0;
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
  &__account-info {
    .msc-popover {
      z-index: 60;
      background-color: $color-white;
      top: 9px !important;
    }
    .msc-popover-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0.5rem 0.75rem;
      .msc-btn, .ms-signin-info__account-link-button {
        @extend %link-subheader;
        color: $color-limedoak !important;
        &:hover,
        &:focus,
        &:active,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):focus,
        &:not(:disabled):not(.disabled):active:focus {
          color: $color-link-footer-hover !important;
          text-decoration: none;
        }
      }
    }
  }
  .mobile-vp {
    @include media-breakpoint-up(lg) {
      display: none;
    }
    .ms-header {
      &__logo {
        display: block;
        top: 3.1rem;
        left: 1rem;
        img {
          max-width: 150px;
          height: auto;
        }
      }
    }
  }
  .ms-header-content {
    display: flex;
  }
  .ms-store-hours {
    display: none;
    @include media-breakpoint-up(lg) {
      margin-right: 0.5rem;
      padding: 0.375rem;
      display: block;
      font-size: $font-size-topheader;
      color: $color-thatch;
      line-height: 1.7;
    }
  }
  .ms-search {
    display: flex;
    justify-content: flex-end;
    flex-basis: 0;
    flex-grow: 1;
    .bx-hide {
      display: none;
    }
    &__icon {
      &:before {
        @include msv-icon();
        content: $msv-magnifying-glass;
        margin-right: 0.35rem;
        font-size: $font-size-search-icon;
      }
    }
    .ms-search__form-submitSearch {
      padding: 0 0.175rem;
      margin: 0 0.175rem;
    }
    .ms-search__form-cancelSearchNoSumbit {
      margin-left: -27px;
      @include media-breakpoint-up(lg) {
        margin-left: -26px;
      }
      padding: 0.15rem 0.55rem 0 !important;
      line-height: 0.8;
      color: $color-oil !important;
      font-size: $font-size-icon-xs;
    }
  }
  .msc-cart-icon {
    > * {
      float: left;
    }
    &::before {
      @extend %fa-icon;
      @extend .fas;
      content: fa-content($fa-var-shopping-cart);
      margin-right: 0.35rem;
      font-size: 16px;
      float: left;
      line-height: 1.2;
    }
  }
  .msc-btn {
    @extend %link-topheader;
    color: $color-thatch !important;
    padding: 0.375rem 0.75rem;
  }
  .ms-header {
    &__nav-icon {
      display: block;
      float: right;
      padding: 1rem 2.4rem 0.95rem;
      z-index: 80;
      &:before {
        @include msv-icon();
        content: $msv-GlobalNavButton;
        font-size: 1.25rem;
        color: $color-white;
        vertical-align: text-bottom;
        text-align: center;
      }
      &:hover {
        text-decoration: none;
      }
      &:focus {
        outline: 0;
      }
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }
}
.msc-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
  top: calc(-0.5rem - 1px);
  left: calc(50% - 5px) !important;
  &::before,
  &::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    width: 0;
    transform: translateX(-50%);
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $color-white;
  }
}

.ms-header__account-info {
  // csm - from override 11/15/2022
  @include media-breakpoint-up(lg) {
    &::before {
      content: "" !important;
      min-height: 34px;
    }
  }
  .ms-header__profile-button {
    &:hover,
    &:focus {
      color: $color-pampas;
      text-decoration: none;
      border: none;
      box-shadow: none;
      background-color: transparent;
    }
  }
}

//Account Header Hide Sign-in Button
.account-header {
  .ms-header__account-info {
    &::before {
      margin: 0;
    }
    .ms-header__signin-button {
      display: none;
    }
  }
  .ms-header__topbar {
    @include media-breakpoint-down(md) {
      height: 50px;
    }
  }
}
