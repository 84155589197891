$msv-modal-width: 640px;
$msv-modal-padding: 20px;
$msv-modal-button-padding: 5px;
$msv-modal-horizontal-alignment-right: 1.75rem 1.5rem auto auto;
$msv-modal-horizontal-alignment-center: 1.75rem auto;
$msv-modal-horizontal-alignment-left: 1.75rem auto auto 1.5rem;
$msv-modal-vertical-alignment-top: flex-start;
$msv-modal-vertical-alignment-center: center;
$msv-modal-vertical-alignment-bottom: flex-end;
$msv-modal-z-index: 1050;

$msv-size-m: 360px;

.modal-open .msc-modal {
  overflow-x: hidden;
  overflow-y: auto;
  .msc-modal__dialog {
    max-width: 915px;
    max-height: 615px;
    padding: 0;
  }
}
.msc-modal {
  display: none;
  height: 100%;
  left: 0;
  overflow: hidden;
  outline: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $msv-modal-z-index;

  &.show .msc-modal__dialog {
    transform: none;
  }

  &.fade .msc-modal__dialog {
    transition: transform 0.3s ease-out;
  }

  & .msc-modal__dialog {
    align-items: $msv-modal-vertical-alignment-top;
    pointer-events: none;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50.1%) !important;
    max-width: 780px;
    min-height: 330px;
    margin: auto;
    background-color: $color-white;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  & .msc-modal__content {
    background-clip: padding-box;
    background-color: $color-white;
    border: 0;
    display: flex;
    flex-direction: column;
    outline: 0;
    pointer-events: auto;
    position: relative;
    width: 100%;
    border-radius: 0;
    outline: 0;

    & .msc-modal__header {
      padding: $msv-modal-padding;
      align-items: flex-start;
      border-color: transparent;
      display: flex;
      justify-content: space-between;
      position: relative;

      .msc-modal__title {
        @include font-content-xl();
        margin: 0;
        text-transform: none;

        @media screen and (max-width: $msv-size-m) {
          font-size: 22px;
        }
      }

      .msc-modal__close-button {
        @include add-icon($msv-x-shape);
        @include vfi();
        background-color: transparent;
        border: 0;
        color: $color-limedoak;
        font-size: 1rem;
        line-height: 1;
        padding: $msv-modal-button-padding;
        position: absolute;
        right: 18px;
        top: 55%;
        opacity: 0.5;
        &:before {
          font-weight: $font-weight-600;
        }
        &:hover {
          opacity: 1;
        }
      }
    }

    & .msc-modal__body {
      padding-top: 24px;
    }

    & .msc-modal__footer {
      padding: $msv-modal-padding;
    }
    .product-iframe {
      width: 576px;
      height: 551px;
      @media screen and (min-width: 720px) {
        width: 760px;
      }
    }
    .iframe-module-content {
      width: 100%;
      border: none;
      height: 100%;
    }
  }
}

.msc-modal__backdrop {
  background-color: $color-black;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: $msv-modal-z-index - 10;

  &.show {
    opacity: 0.5;
  }
}

@media (prefers-reduced-motion: reduce) {
  .msc-modal.fade .msc-modal__dialog {
    transform: none;
  }
}
.youtube-modal {
  .msc-modal__dialog,
  .msc-modal__content {
      background-color: transparent;
  }

  .msc-modal__dialog {
    width: 100%;
    max-width: 900px !important;
    height: 90.4vh;
    max-height: 600px !important;

      &:focus {
          outline: none;
      }
  }

  .msc-modal__content {
      height: 100%;
      border: 0;
  }

  .msc-modal__header {
    padding: 0 !important;
    margin-top: 2.5rem;
  }
  .msc-modal__body {
    padding-top: 0 !important;
    height: 100%;
    border: 0;
  }
  .msc-modal__close-button {
      color: $color-white !important;
      padding: 1rem 1rem !important;
      margin: -1rem -1rem -1rem auto !important;
      float: right;
      font-size: 2rem !important;
      font-weight: 700;
      line-height: 1 !important;
      text-shadow: 0 1px 0 $color-white;
      opacity: .5;
      position: static !important;
      @include modal-close-button;
      &:before {
        position: relative;
        top: 1px;
      }
  }

  iframe {
      width: 100%;
      height: 100%;
  }
}

