.gift-card-balance-container {
    .container-with-header-slots {
        display: flex;
        flex-wrap: wrap;

        > * {
            flex: 0 0 100%;
        }

        > *:first-child {
            margin: 0;

            @include media-breakpoint-up(md) {
                flex: 1 1 30%;
            }

            .hero-picture img {
                width: 100%;
                height: auto;
            }
        }

        > *:last-child {
            padding: 2rem;

            @include media-breakpoint-up(md) {
                flex: 1 1 70%;
            }

            .gift-card-balance-label {
                margin-bottom: 0;
            }

            .gift-card-balance-input {
                margin-bottom: 0.5rem;

                &-text {
                    @extend %input-shop;
                    width: 100%;
                }
            }

            .gift-card-balance-error,
            .gift-card-balance-message {
                font-size: $font-size-body-m;
            }

            .gift-card-balance-button {
                margin-top: 0.5rem;
                display: initial;
            }
        }
    }
    .container-with-header-title {
        color: $color-kabul;
    }
    .ms-content-block__image {
        img {
            width: 100%;
            height: auto;
        }
    }
    .ms-content-block__details {
        padding: 0.25rem;
    }
}