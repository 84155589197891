%breadcrumb-link {
    font-family: $font-family-link;
    font-size: $font-size-link-m;
    line-height: 1;
}

// @FIXME/vo: Change class name when breadcrumb class in custom module has been renamed.
.ms-breadcrumb {
    display: none;
    padding-bottom: 1rem !important;
    background: none;
    padding: 0;
    margin: 0;
    @include media-breakpoint-up(sm) {
        display: block;
    }
    &-cta {
        @extend %breadcrumb-link;
        vertical-align: initial;
        padding: 0 0.75rem;
        border: 0;
        color: $color-breadcrumb--shop;
        &:first-child {
            padding-left: 0;
        }
        &:hover,
        &:focus,
        &:active {
            color: $color-breadcrumb-hover--shop;
        }
        .call-to-action {
            &__glyph {
                display: none;
            }
        }
    }
    &-data {
        @extend %breadcrumb-link;
        display: inline-flex;
    }
    &-data-divider {
        color: $color-breadcrumb--shop;
    }
    &-data-text {
        padding-left: 0.75rem;
        color: $color-breadcrumb-active--shop;
    }
}