body {
  width: 100%;
  background-color: $color-background-body;
  color: $color-text-body;
  overflow-wrap: break-word;
  @include media-breakpoint-up(sm) {
    overflow-wrap: normal;
  }
}

.shop {
  body {
    background-color: $color-background-body--shop;
    color: $color-text-body--shop;
  }
}