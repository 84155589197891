.subfooter {
    padding: 2rem 1rem 1rem;
    border-top: 1px solid $color-border--shop;

    > .row > div {
        flex: 0 0 100%;

        &:not(:first-child) {
            margin-top: 1rem;
        }

        @include media-breakpoint-up(lg) {
            flex: 1 1 calc(100% / 3);
            margin-top: 0;
            padding: 0 1rem;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }

            &:not(:first-child) {
                margin-top: 0;
            }
        }

        > div > .row > div:not(:first-child) {
            margin-top: 1rem;
        }
    }

    &-social {
        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        .container-with-header-slots > div > .row > div {
            display: inline-flex;
            flex: none;
            width: auto;
            padding-right: 0;
        }
    }

    &-shipping .shipping-map {
        width: 120px;
        margin-right: 0.5rem;
        float: left;
    }

    h4.content-block-with-title-title,
    h4.container-with-header-title {
        @extend %text-shop-heading-tertiary;
        font-size: $font-size-heading-shop-s + 2px;
    }

    p {
        margin-bottom: 0;
        line-height: 1.35;
    }

    a {
        @extend %link-shop-subfooter;
    }

    .shipping-map {
        &-svg {
            max-width: 100%;
            max-height: 90px;
        }

        &-list,
        &-legend {
            display: none;
        }
    }
}