$msv-account-profile-edit-width: 100%;
$msv-account-profile-edit-width-m: 80%;

$msv-account-profile-edit-message-margin-top: 20px;
$msv-account-profile-edit-message-padding: 20px;

$msv-account-profile-edit-account-item-margin-top: 20px;
$msv-account-profile-edit-input-margin-top: 4px;

$msv-account-profile-edit-button-group-spacing: 32px;
$msv-account-profile-edit-save-button-margin-top: 1rem;
$msv-account-profile-edit-cancel-button-margin-top: 0.5rem;

.ms-account-profile-edit {
  @include font-content-m();
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__container {
    width: $msv-account-profile-edit-width;
  }

  &__heading {
    @include account-heading();
  }

  &__account-item {
    margin-top: $msv-account-profile-edit-account-item-margin-top;
    &-label {
      margin-bottom: 0;
      color: $color-dovegray;
      font-size: $font-size-body-m;
      line-height: 1.6;
    }
    &-input {
      @include form-input-el();
      height: 36.5px;
      width: 100%;
    }
  }

  &__page-success {
    @include page-success();
  }

  &__page-error {
    @include page-error();
  }

  &__save-button {
    @include primary-button();
    margin-top: $msv-account-profile-edit-save-button-margin-top;
    width: 100%;
    line-height: 1.5;
    border-color: $color-limedoak;
  }

  &__cancel-button {
    @include secondary-button();
    margin-top: $msv-account-profile-edit-cancel-button-margin-top;
    width: 100%;
    line-height: 1.5;
  }

  &__verifying-modal,
  &__verifying-modal-message {
    display: none;
  }
}
