@mixin slate-font(
  $font-weight: $font-weight-normal,
  $font-size: 14px,
  $line-height: 18px,
  $text-transform: none,
  $font-family: $font-family-body
) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  text-transform: $text-transform;
}

@mixin font-content(
  $font-weight: $font-weight-normal,
  $font-size: 14px,
  $line-height: 18px,
  $text-transform: none
) {
  @include slate-font($font-weight, $font-size, $line-height, $text-transform);
}

@mixin font-content-s($font-weight: $font-weight-normal) {
  @include font-content($font-weight);
}

@mixin font-content-s-uppercase($font-weight: $font-weight-normal) {
  @include font-content($font-weight, 14px, 18px, uppercase);
}

@mixin font-content-s-height-l($font-weight: $font-weight-normal) {
  @include font-content($font-weight, 14px, 24px);
}

@mixin font-content-m($font-weight: $font-weight-normal) {
  @include font-content($font-weight, 16px, 21px);
}

@mixin font-content-m-underline($font-weight: $font-weight-normal) {
  text-decoration-line: underline;
  @include font-content($font-weight, 16px, 21px);
}

@mixin font-content-l-strikethrough($font-weight: $font-weight-normal) {
  text-decoration-line: line-through;
  @include font-content($font-weight, 18px, 22px);
}

@mixin font-content-l($font-weight: $font-weight-normal) {
  @include font-content($font-weight, 18px, 24px);
}

@mixin font-content-xl($font-weight: $font-weight-normal) {
  @include font-content($font-weight, 24px, 32px);
}

@mixin font-content-xxl($font-weight: $font-weight-normal) {
  @include font-content($font-weight, 48px, 63px);
}

@mixin slate-display(
  $font-size: 24px,
  $line-height: 32px,
  $font-family: $font-family-body
) {
  @include slate-font(
    $font-weight-normal,
    $font-size,
    $line-height,
    uppercase,
    $font-family
  );
}

@mixin font-display-xs() {
  @include slate-display();
}

@mixin font-display-s() {
  @include slate-display(36px, 46px);
}

@mixin font-display() {
  letter-spacing: 0.05em;
  @include slate-display(100px, 134px, $font-cinzel);
}

@mixin font-display-l() {
  letter-spacing: 0.05em;
  @include slate-display(160px, 215px, $font-cinzel);
}

@mixin font-glyph-standard() {
  font-size: 24px;
}

@mixin font-glyph-small() {
  font-size: 16px;
}

@mixin make-container-padding(
  $paddings: $grid-gutter-widths,
  $breakpoints: $grid-breakpoints
) {
  @each $breakpoint, $padding in $paddings {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      padding-left: $padding;
      padding-right: $padding;
    }
  }
}

@mixin basicTruncation {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin basic-outline-offset(
  $outline-width: 1px,
  $outline-style: dashed,
  $outline-color: $black,
  $outline-offset: -1px
) {
  outline: $outline-width $outline-style $outline-color;
  outline-offset: $outline-offset;
}

@mixin default-outline {
  @include basic-outline-offset(1px, solid, $color-white);
}

@mixin font-awesome-icon($icon: "") {
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  content: $icon;
  display: inline;
}

@mixin screen-reader() {
  position: absolute !important;
  overflow: hidden !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

// Form elements

@mixin error-message() {
  @include font-content-m($font-weight-bold);
  display: block;
  color: $red;
}

@mixin form-input-label() {
  @include font-content-l();
  display: block;
  margin-bottom: 4px;
}

@mixin form-input-checkbox() {
  height: 15px;
  width: 15px;
}

@mixin form-input-el() {
  width: 100%;
  min-height: 30px;
  padding: 0.25rem 0.5rem;
  border: 1px solid $color-swisscoffee;
  border-radius: 0;
  background-color: $color-pampas;
  color: $color-dovegray;
  height: 36.5px;
}

@mixin form-input-el-error() {
  border: 1px dashed $color-white;
  border-left: 3px solid $color-white;
}

@mixin form-input-error-message() {
  @include error-message();
  margin-bottom: 4px;
  &:before {
    @include font-awesome-icon($alert-icon);
    margin-right: 8px;
  }
}

@mixin alert {
  background-color: $pink;
  border: 1px solid $red;
  max-width: 512px;
  padding: 8px;
}

@mixin alert-title {
  @include error-message();
  margin-bottom: 10px;
  margin-top: 2px;
  &:before {
    @include font-awesome-icon($alert-icon);
    margin-right: 8px;
  }
}

@mixin button-default() {
  @include font-content-l();
  padding: 12px 20px;
  height: 48px;
  min-width: 160px;
}

@mixin button-icon {
  width: 48px;
  height: 48px;
}

@mixin vfi {
  &:focus {
    outline: 0;
  }
}
@mixin vfi-focus($color: white) {
  &:focus-visible {
    outline: 1px dashed #{$color};
  }
}

@mixin vfi-psuedo($selector: after, $offset: -2px, $position: relative) {
  position: $position;

  &:focus {
    outline: 0;

    &:#{$selector} {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      outline: 0;
      outline-offset: 0;
    }
  }
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin primary-button {
  padding: 0.4rem 0.8rem 0.5rem;
  border-color: $color-button-primary-border;
  background-color: $color-button-primary-background;
  color: $color-button-primary-text;
  font-family: $font-family-link;
  font-size: $font-size-link-m;
  letter-spacing: 0.85px;
  text-transform: uppercase;
  border: 1px solid;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
    border-color: $color-button-primary-border-hover;
    background-color: $color-button-primary-background-hover;
    color: $color-button-primary-text-hover;
  }
}
@mixin secondary-button {
  @include primary-button();
  border-color: $color-button-secondary-border;
  background-color: $color-button-secondary-background;
  color: $color-button-secondary-text;
  border: 1px solid;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
    border-color: $color-button-secondary-border-hover;
    background-color: $color-button-secondary-background-hover;
    color: $color-button-secondary-text-hover;
  }
}
@mixin link-nav-primary {
  border: none;
  background-color: transparent;
  color: $color-link-secondary;
  font-family: $font-family-link;
  font-size: $font-size-link-l;
  text-transform: uppercase;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: $color-link-secondary-hover;
  }
}
//14 hands mixins
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
@mixin form-control-14hands {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  min-height: 30px;
  border: 1px solid $color-swisscoffee;
  border-radius: 0;
  font-family: $font-family-body;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  background-color: $color-pampas;
  color: $color-kabul;
  padding: 0.25rem 0.5rem;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

@mixin text-shop-heading-primary {
  color: $color-heading-title--shop;
  font-family: $font-family-heading--shop;
  font-size: $font-size-heading-shop-m;
}

@mixin slide-horse-icon {
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 5px;
  top: -35px;
  width: 100%;
  height: 70px;
  background-image: url(https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MA1NSf);
  background-size: auto 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
}
//Layout Mixins
// Limit the max width of an element with margin on either side of the element
@mixin msv-layout-page-clamp {
  max-width: $msv-layout-page-clamp-width;
  margin: auto;
}

// Prevent content from touch the edge of the screen with padding on either side of the element
@mixin msv-layout-page-side-padding {
  padding-left: $msv-layout-h-padding-s;
  padding-right: $msv-layout-h-padding-s;

  @media screen and (min-width: $msv-breakpoint-m) {
    padding-left: $msv-layout-h-padding-m;
    padding-right: $msv-layout-h-padding-m;
  }

  @media screen and (min-width: $msv-breakpoint-l) {
    padding-left: $msv-layout-h-padding-l;
    padding-right: $msv-layout-h-padding-l;
  }

  @media screen and (min-width: $msv-breakpoint-xl) {
    padding-left: $msv-layout-h-padding-xl;
    padding-right: $msv-layout-h-padding-xl;
  }
}

@mixin msv-layout-content-block-padding {
  padding: $msv-layout-v-padding-s $msv-layout-h-padding-s;

  @media screen and (min-width: $msv-breakpoint-m) {
    padding: $msv-layout-v-padding-m $msv-layout-h-padding-m;
  }

  @media screen and (min-width: $msv-breakpoint-l) {
    padding: $msv-layout-v-padding-l $msv-layout-h-padding-l;
  }

  @media screen and (min-width: $msv-breakpoint-xl) {
    padding: $msv-layout-v-padding-xl $msv-layout-h-padding-xl;
  }
}
//hidden
@mixin visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}

// Margin and padding
@mixin space($type, $v) {
  @if (length($v) == 4) {
    @if ($text-direction == rtl) {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    }
  } @else {
    #{$type}: $v;
  }
}

//Responsive Font Size
@mixin font-size($fs-map) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      font-size: $fs-font-size;
    } @else {
      @media screen and (min-width: $fs-breakpoint) {
        font-size: $fs-font-size;
      }
    }
  }
}

//screen reader view only
@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@mixin image-placeholder($size) {
  @include image-placeholder-url($size);
  height: $size;
  width: $size;
}

@mixin image-placeholder-url($size) {
  background-image: str-replace(
    url("data:image/svg+xml,%3Csvg width='#{$size}' height='#{$size}' viewBox='0 0 150 150' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3C!-- background --%3E%3Cpath d='M150 0H0V150H150V0Z' fill='white'/%3E%3C!-- line --%3E%3Cpath d='M100 56.25V93.75H50V56.25H100ZM53.125 59.375V75.9033L64.0625 64.9902L79.6875 80.6152L85.9375 74.3652L96.875 85.2783V59.375H53.125ZM53.125 90.625H85.2783L64.0625 69.3848L53.125 80.3467V90.625ZM96.875 90.625V89.7217L85.9375 78.7598L81.8848 82.8125L89.7217 90.625H96.875ZM89.0625 68.75C88.6393 68.75 88.2731 68.5954 87.9639 68.2861C87.6546 67.9769 87.5 67.6107 87.5 67.1875C87.5 66.7643 87.6546 66.3981 87.9639 66.0889C88.2731 65.7796 88.6393 65.625 89.0625 65.625C89.4857 65.625 89.8519 65.7796 90.1611 66.0889C90.4704 66.3981 90.625 66.7643 90.625 67.1875C90.625 67.6107 90.4704 67.9769 90.1611 68.2861C89.8519 68.5954 89.4857 68.75 89.0625 68.75Z' fill='%23D1D1D1'/%3E%3C/svg%3E%0A"),
    "#",
    "%23"
  );
}

@mixin page-success {
  color: #004085;
  background-color: #cce5ff;
  border: 1px solid #b8daff;
  display: none;
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  p {
    line-height: 1.2;
  }
}

@mixin page-error {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  display: none;
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  p {
    line-height: 1.2;
  }
}
@mixin account-heading {
  font-size: 32px;
  color: $color-kabul;
  border-bottom: 1px solid $color-silver;
  padding-bottom: 1rem;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

@mixin modal-close-button {
  &:before {
    content: $msv-modal-close !important;
    font-family: $font-family-system !important;
    font-weight: 700 !important;
  }
}
@mixin quantity-alert() {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  font-size: 12px;
  font-weight: normal;
  padding: 0.5rem 1rem 0.4rem;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  border-radius: 3px;
  line-height: 1.5;
  @include media-breakpoint-up(lg) {
    font-size: 14px;
  }
}
