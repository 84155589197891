/**
 * Typography
 * =================================
 * - Base
 * - Paragraphs
 * - Headings
 * - Type sizes
 * - Misc
 * - Utilities
 *
 * Body font size, leadings etc have been set in _variables.scss
 *
 */
 body {
    font-family: $font-family-body;
    @include font-size($body-font-sizes);
    font-weight: $font-weight-400;
    line-height: 1.6;
    
  }
  .shop {
    body {
      font-family: $font-family-body--shop;
      @include font-size($body-font-sizes);
      font-weight: $font-weight-400;
      line-height: 1.9;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $font-family-heading--shop;
      color: $color-kabul;
    }
  
    a {
      color: $color-link--shop;
  
      &:hover,
      &:focus,
      &:active {
        color: $color-link-hover--shop;
        text-decoration: none;
      }
    }
  }
  /**
   * Headings
   */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-family-heading;
    color: $color-heading-title;
  }
  /**
   * Link
   */
  a {
    color: $color-link;
  
    &:hover,
    &:focus,
    &:active {
      color: $color-link-hover;
      text-decoration: none;
    }
  }
  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }
  
  /**
   * List
   */
  ul {
    padding-left: 1rem;
  }
  /* Remove default padding */
  ul[class],
  ol[class] {
    padding: 0;
    list-style: none;
  }
  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  /**
   * Miscellaneous
   */
  
  
  em,
  i {
    font-style: italic;
  }
  /* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}