.aad {
  max-width: $max-width-page--aad;
  margin: $margin-page-mobile--aad;
  font-size: $font-size-body-m;

  @include media-breakpoint-up(lg) {
    margin: $margin-page--aad;
  }

  .row,
  .row > div {
    padding: 0;
    margin: 0;
  }

  .account-aad {
    max-width: none;
    margin: 0;
  }

  &-message {
    padding: 1.5rem 2rem;
    background-color: $color-background-inverse--shop;

    .hero-heading {
      display: none;
    }

    h5 {
      color: $color-heading-title-inverse--shop;
    }

    p {
      margin-bottom: 0;
      color: $color-text-inverse--shop;
      line-height: 1.5;
    }

    a {
      color: $color-link-inverse--shop;

      &:hover,
      &:focus,
      &:active {
        color: $color-link-inverse-hover--shop;
      }
    }
  }
}

.account-aad {
  max-width: $max-width-page--aad;
  font-size: $font-size-body-m;
  padding: 1.5rem 2rem;
  margin: $margin-page-mobile--aad;
  background-color: $color-background-container--aad;
  color: $color-text-body--shop;

  @include media-breakpoint-up(sm) {
    padding: 3rem 4rem;
  }

  @include media-breakpoint-up(lg) {
    margin: $margin-page--aad;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-heading-title--shop;
    font-family: $font-family-heading--shop;
  }

  .ms-sign-in__sign-in-heading,
  .ms-sign-up__heading,
  .ms-password-reset-verification__heading,
  .ms-password-reset__heading,
  .ms-account-profile-edit__heading {
    @extend %text-shop-heading-primary;
    @extend %text-shop-heading-container;
    padding-top: 0;
    font-size: $font-size-heading-shop-l;
    text-transform: initial !important;
    /*Override inline style inject by AAD so we need important*/
    .ms-sign-up__page-success[aria-hidden="false"],
    .ms-password-reset-verification__page-success[aria-hidden="false"],
    .ms-sign-up__page-error[aria-hidden="false"],
    .ms-password-reset-verification__page-error[aria-hidden="false"] {
      display: block !important;
    }
    &__page-success {
      color: #004085;
      background-color: #cce5ff;
      border: 1px solid #b8daff;
      display: none;
      margin: 0.5rem 0;
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      p {
        line-height: 1.2;
      }
    }
    &__page-error,
    &__item-error {
      color: #721c24;
      background-color: #f8d7da;
      border: 1px solid #f5c6cb;
      display: none;
      margin: 0.5rem 0;
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      p {
        line-height: 1.2;
      }
      p::before {
        font-family: $msv-icon-font-family;
        font-weight: $msv-solid-icon-weight;
        content: $msv-Warning;
        margin-right: 0.5rem;
      }
    }
    // remove icons from email and password error messages
    .ms-sign-up__account-item:nth-child(3),
    .ms-sign-up__account-item:nth-child(4) {
      p::before {
        content: "";
        margin-right: 0;
      }
    }
  }

  .entry-item {
    margin-top: 1rem;
  }

  label {
    margin-bottom: 0;
  }

  .form-control {
    @extend %input-shop;
  }

  .btn {
    width: 100%;
  }

  .sign-in-login,
  .sign-up-create,
  .sign-up-resend,
  .password-reset-verification-send,
  .password-reset-verification-verify,
  .password-reset-verification-edit,
  .password-reset-save,
  .account-profile-edit-save {
    margin-top: 1rem;
  }

  .sign-up-cancel,
  .password-reset-verification-cancel,
  .password-reset-verification-resend,
  .password-reset-verification-continue,
  .password-reset-cancel,
  .account-profile-edit-cancel {
    margin-top: 0.5rem;
  }

  .password-reset-verification-continue:disabled {
    display: none;
  }

  .sign-in-forget-password {
    @extend %link-shop;
    width: auto;
  }

  .error,
  .alert {
    display: none;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;

    &:not(:empty)[aria-hidden="false"] {
      display: block !important;
    }

    .alert-glyph {
      float: left;
      margin-right: 0.5rem;
    }

    p {
      margin-bottom: 0;
      line-height: 24px;
    }
  }

  .sign-in-create-account-description {
    position: relative;
    padding: 1rem 0;
    margin: 1rem 0;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: 1px;
      background-color: $color-border;
    }

    &::after {
      content: "or";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0 0.5rem;
      background-color: $color-background-container--aad;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .sign-in-social-accounts,
  .sign-in-create-account-heading {
    display: none;
  }
}
.account-header {
  .ms-header {
    &__account-info {
      flex-direction: row;
      display: flex;
      @include media-breakpoint-up(lg) {
        &::before {
          margin-right: 0.5rem;
          padding: 0.375rem;
          content: "Open by appointment 12-5pm Thurs-Sun";
          display: block;
          font-size: $font-size-topheader;
          color: $color-thatch;
          line-height: 1.6;
        }
      }
    }
  }
}
