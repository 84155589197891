.shipping-service {
  .container-heading {
    @extend %text-shop-heading-secondary;
    @extend %text-shop-heading-container;
    border-color: $color-silver;
    font-size: 32px;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }

  > .row {
    padding: 0 1rem;

    > div:first-of-type {
      flex: 0 0 100%;
      padding: 0 15px;

      @include media-breakpoint-up(lg) {
        flex: 1 1 25%;
        width: 25%;
        padding-left: 0;
        padding-right: 15px;
      }

      .container-with-header-title {
        @extend %text-shop-heading-tertiary;
        font-size: $font-size-body-l;
      }

      .content-block-with-title-title {
        @extend %text-shop-subheading;
      }
    }

    > div:last-of-type {
      flex: 0 0 100%;
      margin-top: 2rem;
      padding: 0 0 0 1rem !important;

      @include media-breakpoint-up(lg) {
        flex: 1 1 75%;
        width: 75%;
        padding-right: 0;
        margin-top: 0;
        padding-left: 15px;
      }

      .ms-accordion-header-section-container h3 {
        @extend %text-shop-heading-secondary;
        font-size: $font-size-heading-shop-m;
        text-transform: uppercase;
      }
      .ms-accordion-header-section h2 {
        @extend %text-shop-heading-secondary;
    }
      .ms-accordion-header-section {
        margin-top: 2rem;

        @include media-breakpoint-up(sm) {
          display: flex;
          justify-content: space-between;
          margin-top: 0;
          align-items: center;
        }

        &-toggle-button-container button {
          @extend %link-shop;
          text-transform: lowercase;

          &:first-child {
            margin-right: 0.25rem;
          }

          &:last-child {
            margin-left: 0.25rem;
          }
        }
      }

      .ms-accordion-item button {
        padding: 1rem 0 0;
        margin: 2rem 0;
        color: $color-kabul;
        line-height: 1.6;

        border: {
          top: 1px solid $color-silver;
          right: 0;
          bottom: 0;
          left: 0;
        }

        border-radius: 0;
        font-family: $font-family-heading--shop;
        font-size: $font-size-heading-shop-s;
        text-align: left;
        display: block;
        background-color: transparent;

        &:focus {
          box-shadow: none;
        }
      }

      .ms-accordion-item .content-block,
      .ms-accordion-item .content-item {
        padding: 0;
      }
    }
    .ms-accordion-item-content {
      h2 {
        font-size: 1.25rem;
      }
    }
  }

  .container-with-header-title {
    margin-bottom: 2rem;
  }
  .content-block-with-title {
    &-text {
      font-size: 14px;
      h5 {
        color: $color-kabul;
      }
    }
  }
  .container-with-header-slots {
    h3 {
      color: $color-kabul;
    }
  }
}
