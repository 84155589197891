$msv-address-form-item-margin-top: 1rem;
$msv-address-detail-item-margin-right: 0.25rem;
$msv-address-select-item-margin-top: 10px;
$msv-address-select-item-margin-bottom: 10px;

$msv-alert-danger-color: $color-font-error;
$msv-alert-danger-background-color: $color-bg-error;
$msv-alert-danger-border-color: $color-border-error;

$msv-alert-font-family: $font-family-body;
$msv-alert-font-weight: $font-weight;
$msv-alert-font-size: 14px;
$msv-alert-line-height: 21px;
$msv-alert-border-radius: 0.25rem;
$msv-alert-padding: 0.5rem 1rem;
$msv-alert-margin: 0.5rem 0;
$msv-alert-border: 1px solid transparent;

$msv-alert-icon: $msv-IncidentTriangle;
$msv-alert-icon-margin: 8px;

$msv-address-input-min-height: 30px;
$msv-address-input-border-color: $color-swisscoffee;
$msv-address-input-font-size: 14px;
$msv-address-input-line-height: 1.5;

$msv-address-button-save-margin-right: 1rem;

$msv-account-management-address-margin-bottom: 0;
$msv-account-management-address-list-button-add-empty-margin-bottom: 16px;
$msv-account-management-address-list-primary-border-color: $color-dovegray;
$msv-account-management-address-list-primary-margin-top: 2rem;
$msv-account-management-address-list-primary-heading-margin-bottom: 12px;
$msv-account-management-address-list-primary-list-border-color: $color-silver;
$msv-account-management-address-list-primary-list-address-detail-item-margin-right: 0.25rem;
$msv-account-management-address-list-primary-list-address-detail-item-newline-margin-right: 0px;
$msv-account-management-address-list-primary-list-button-edit-background-color: transparent;
$msv-account-management-address-list-primary-list-button-edit-border-color: transparent;
$msv-account-management-address-list-primary-list-phone-icon-margin-right: 0.25rem;
$msv-account-management-address-list-button-primary-margin-top: 15px;

.msc-address-form {
  &__item {
    margin-top: $msv-address-form-item-margin-top;
  }

  &__item-county {
    display: none;
  }

  &__label {
    display: inline-block;
    font-size: $msv-address-input-font-size;
    line-height: $msv-address-input-line-height;
    font-weight: 400;
    margin-bottom: 0px;
    color: $color-dovegray;
  }

  &__input-text {
    padding: 6px 8px;
  }

  &__input,
  &__dropdown {
    @include vfi();
    @include form-control-14hands;
    @include form-input-el();
  }
  &__dropdown {
    font-size: $font-size-body-m;
  }
  &__alert {
    display: block;
  }

  &__button-save {
    @extend .btn-primary;
    margin-right: $msv-address-button-save-margin-right;
    @include media-breakpoint-down(lg) {
      width: auto;
      margin-right: 1rem;
      float: left;
      margin-left: 0;
      margin-bottom: 1.5rem;
    }
  }

  &__button-cancel {
    @extend .btn-secondary;
    @include media-breakpoint-down(lg) {
      float: left;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
  .address-form__item-invalid &__alert {
    @extend %input-error;
    &::before {
      display: none;
    }
  }
  .msc-address-form__warning:empty {
    display: none;
  }
  .msc-address-form__warning {
    @extend %input-warning;
  }
}

.msc-address-detail {
  &__item {
    margin-right: $msv-address-detail-item-margin-right;
    font-size: 14px;
    color: $color-dovegray;
  }

  &__item-newline,
  &__item-county,
  &__item-phone {
    display: block;
  }
  &__item-address-detail_Phone {
    display: block;
  }
  &__item-city::after {
    content: ",";
  }
  &__item-phone {
    @include add-icon($msv-Phone);
    display: inline-block;
    margin-right: 0.2rem;
    font-size: 12px;

    &-label {
      @include visually-hidden();
    }

    &:before {
      margin-right: $msv-account-management-address-list-primary-list-phone-icon-margin-right;
    }

    &.msc-address-detail__item-empty {
      display: none;
    }
  }
}

.msc-address-select {
  &__item {
    display: flex;
    align-items: center;
    margin-top: $msv-address-select-item-margin-top;
    margin-bottom: $msv-address-select-item-margin-bottom;
  }

  &__input {
    @include vfi();
    @include form-input-checkbox();
  }

  &__button-add {
    @include vfi();
    @extend .btn-primary;
  }
}

// mixin
@mixin address-button {
  cursor: pointer;
}
.ms-account-management-address {
  margin-bottom: 0;
  padding: 2rem 2rem 5.5rem;
  @media (min-width: $msv-breakpoint-l) {
    padding: 2rem;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: $msv-account-management-address-margin-bottom;
  }
  .ms-address-list {
    margin-bottom: 2rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: $msv-account-management-address-margin-bottom;
    }
  }

  .msc-address-list__heading {
    font-family: $font-family-body;
    font-weight: $font-weight-normal;
    margin-bottom: 0.5rem;
    font-size: 24px;
    color: $color-kabul;
  }

  .msc-address-list__add-empty {
    margin-bottom: $msv-account-management-address-list-button-add-empty-margin-bottom;
  }

  .msc-address-list__button-add {
    @extend .btn;
    @extend .btn-primary;
  }

  .msc-address-list__primary {
    margin-top: $msv-account-management-address-list-primary-margin-top;
    display: flow-root;

    .msc-address-list__primary-heading {
      margin-bottom: 1rem;
      font-size: 18px;
      font-weight: bold;
      text-transform: none;
      color: $color-kabul;
    }
    .msc-address-list__primary-list {
      border-bottom: 1px solid
        $msv-account-management-address-list-primary-list-border-color;
      padding-bottom: 1rem;
      margin-top: 1rem;

      &::nth-child(2) {
        padding-top: 0px;
      }

      &::last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
      .msc-address-detail {
        .msc-address-detail__item-newline,
        .msc-address-detail__item-county {
          display: block;
        }
      }
      .msc-address-list__button-edit,
      .msc-address-list__button-primary {
        @extend %link-shop;
        margin-top: 0.5rem;
      }
      .msc-address-list__button-edit {
        float: left;
        width: auto;
        margin-right: 1rem;
      }
    }
    .msc-address-list__primary-list:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}
