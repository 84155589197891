$wineclub-item-width: 48%;
$wineclub-item-image-height: 300px;

$wineclub-main-bg-color: $color-white;

$wineclub-heading-font-size: 26px;
$wineclub-heading-font-size-mobile: 20px;
$wineclub-title-color: $color-kabul;
$wineclub-text-color: $color-kabul;

$wineclub-accent-bg-color: $color-pampas;
$wineclub-accent-border-color: $color-darklimedoak;

$wineclub-button-color: $color-darklimedoak;
$wineclub-button-color-accent: $color-white;

$wineclub-color-dark: $color-oil;

%wineclub-button {
    @extend .btn;
    @extend .btn-secondary;
    color: $wineclub-button-color;
    border: 1px solid $wineclub-button-color;
    background-color: transparent;
    letter-spacing: 0.85px;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

    &:disabled {
        opacity: 1;
        background-color: $wineclub-button-color;
        color: $wineclub-button-color-accent;
    }
}

%wineclub-link {
    @include vfi-focus($wineclub-text-color);
    color: $wineclub-text-color;
    text-decoration: underline;
    transition: ease-in-out 0.2s color;

    &:hover,
    &:focus {
        color: darken($color: $wineclub-text-color, $amount: 15%);
    }
}

@mixin wineclub-title {
    font-size: $wineclub-heading-font-size-mobile;
    color: $wineclub-title-color;
    text-align: center;

    @include breakpoint(l) {
        font-size: $wineclub-heading-font-size;
    }
}

%wineclub-title {
    font-size: $wineclub-heading-font-size;
    color: $wineclub-title-color;
    text-align: center;
}

//---------- Placeholder and Variables END ---------- //


//---------------------------------//
//          Main Container         //
//---------------------------------//
.join-vrc {
    &.modal-open {
        position: fixed;
        left: 0;
        right: var(--windowScrollBarWidth);
        top: var(--windowScrollY) !important;

        #attentive_overlay {
            display: none;
        }
    }

    .container-heading {
        width: 100%;
        margin: 30px 0;
        text-align: center;
    }
}

//---------------------------------//
//     WineClub Signup Container   //
//---------------------------------//
.wine-club-signup {
    background-color: $wineclub-main-bg-color;
    border-radius: 0 0 1px 1px;

    //---------------------------------//
    //    WineClub Signup - Benefits   //
    //---------------------------------//

    &-benefits-button {
        padding-top: 30px;
        background-color: $wineclub-main-bg-color;
        border-radius: 1px 1px 0 0;
        text-align: center;

        .overflow-container-btn {
            @include vfi-focus($wineclub-color-dark);
            position: relative;
            background-color: transparent;
            border: none;
            color: $wineclub-color-dark;
            font-size: 20px;
            margin-bottom: -6px;

            &::after {
                content: '';
                border-width: 0 10px 10px 10px;
                border-color: transparent transparent $wineclub-button-color transparent;
                border-style: solid;
                height: 0;
                width: 0;
                margin: 10px auto 5px;
                display: block;
                transform: rotate(180deg);
            }
        }

        &.overflow-container-closed {
            padding-bottom: 15px;
        }

        &.overflow-container-opened .overflow-container-btn::after {
            transform: rotate(0deg);
        }

        &.overflow-container-closed .ms-content-block__details {
            display: none;
        }

        &.overflow-container-opened .ms-content-block__details {
            display: block;
        }
    }

    &-benefits-content .ms-content-block {
        &__title {
            @include wineclub-title;
            font-size: 18px;
            margin-bottom: 20px;
        }

        &__details {
            @include fadeIn;
            background-color: $wineclub-accent-bg-color;
            padding: 30px 30px 15px;
        }

        &__text {
            width: 90%;
            margin: 0 auto;

            @media (min-width: 1200px) {
                width: 75%;
            }

            ul {
                column-count: 2;
                column-width: 260px;
                column-gap: 30px;
                text-align: left;
                color: $wineclub-text-color;
                display: flex;
                flex-flow: column wrap;

                @media (min-width: $msv-breakpoint-m) {
                    display: block;
                }
            }
        }
    }

    //---------------------------------//
    //      WineClub Signup - Club     //
    //---------------------------------//
    &-club {
        &-title {
            @include wineclub-title;
            padding: 30px;
            margin: 0;
        }

        &-items {
            padding: 0 30px 30px;
            display: block;

            @media (min-width: 400px) {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                grid-gap: 30px;
            }

            @media (min-width: ($msv-breakpoint-ipad + 1px)) {
                grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
            }

            @media (min-width: 1200px) {
                padding: 0 60px 30px;
                grid-template-columns: repeat(auto-fit, minmax(var(--clubItemWidth), 1fr));
            }

        }

        &-item {
            background-color: $wineclub-accent-bg-color;
            border: 1px solid $wineclub-accent-border-color;
            border-radius: 2px;
            margin-bottom: 30px;
            transition: box-shadow ease-in-out 0.3s;

            @media (min-width: (400px)) {
                margin-bottom: 0;
            }

            &-content {
                margin-top: 15px;
                padding-left: 20px;
                padding-right: 20px;

                @include breakpoint(l) {
                    margin-top: 20px;
                }
            }

            &.active {
                box-shadow: 0 0 0 1px transparentize($color: $wineclub-accent-border-color, $amount: 0);
            }
        }

        &-item-image {
            display: none;

            @include breakpoint(l) {
                min-height: ($wineclub-item-image-height - 150);
                display: block;
                overflow: hidden;
            }

            img {
                min-width: 100%;
                width: 100%;
                height: auto;
                object-fit: contain;
                object-position: top;
            }
        }


        &-item-title {
            margin-bottom: 5px;
            font-size: 20px;
            color: $wineclub-title-color;
            text-align: center;

            @media (min-width: $msv-breakpoint-m) {
                font-size: 22px;
            }

            @include breakpoint(l) {
                font-size: 26px;
            }
        }

        &-item-subtitle {
            margin-bottom: 10px;
            color: $wineclub-text-color;
            text-align: center;
            font-size: 0.938rem;
        }

        &-item-content-body {
            color: $wineclub-text-color;
            text-align: center;

            p {
                margin-bottom: 0;
            }
        }

        &-item-additional-benefits {
            border-top: 1px solid darken($color: $wineclub-accent-bg-color, $amount: 10%);
            border-bottom: 1px solid darken($color: $wineclub-accent-bg-color, $amount: 10%);
            margin: 0 15px 30px 15px;

            &.empty {
                display: none;
            }

            &-button {
                @include vfi-focus($wineclub-accent-bg-color);
                position: relative;
                width: 100%;
                padding: 15px;
                background-color: transparent;
                border: none;
                outline: none;
                color: $wineclub-button-color;

                &.closed::after,
                &.open::after {
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translateY(-50%);
                    font-size: 24px;
                    font-weight: bold;

                }

                &.closed::after {
                    content: '+';
                }

                &.open::after {
                    content: '-';
                }
            }

            &-content {
                &.closed {
                    display: none;
                }

                &.open {
                    margin-bottom: 15px;
                    display: block;
                }
            }
        }

        &-item-button {
            button {
                @include add-icon($msv-Selected);
                @extend %wineclub-button;
                margin-bottom: 20px;
                margin-left: auto;
                margin-right: auto;
                font-size: 1rem;
                min-width: 40%;
                max-width: 170px;

                @include breakpoint(l) {
                    margin-bottom: 30px;
                }

                &::before {
                    opacity: 0;
                    width: 0;
                    transition: ease-in-out 0.2s opacity, ease-in-out 0.2s width;
                }

                &:disabled {
                    &::before {
                        opacity: 1;
                        width: 16px;
                        margin-right: 10px;
                    }
                }
            }
        }

        &-item-pricing,
        &-item-description {
            margin-bottom: 15px;
        }

        &-item-pricing-cost-notif {
            display: none;
        }
    }

    //-------------------------------------//
    //  WineClub Signup - Wine Preference  //
    //-------------------------------------//
    &-wine-preference {
        padding: 0 30px 30px;

        &-title {
            @include wineclub-title;
            padding: 0 0 20px;
            margin: 0;
        }

        &-buttons {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 15px;

            button {
                @include add-icon($msv-Selected);
                @extend %wineclub-button;
                font-size: 1rem;
                min-width: 130px;

                &::before {
                    opacity: 0;
                    width: 0;
                    transition: ease-in-out 0.2s opacity, ease-in-out 0.2s width;
                }

                &:disabled {
                    &::before {
                        opacity: 1;
                        width: 16px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    //-------------------------------------//
    //    WineClub Signup - Wine Variant   //
    //-------------------------------------//
    &-variant {
        &-title {
            @include wineclub-title;
            padding: 0 30px 20px;
            margin: 0;
        }

        &.empty {
            display: none;
        }

        &-expand-button {
            @include vfi-focus($wineclub-button-color);
            width: 95%;
            padding: 10px;
            margin: 0 auto 20px;
            background-color: transparent;
            border: none;
            outline: none;
            color: $wineclub-button-color;
            border-top: 1px solid darken($color: $wineclub-main-bg-color, $amount: 10%);
            border-bottom: 1px solid darken($color: $wineclub-main-bg-color, $amount: 10%);
            display: flex;
            justify-content: center;
            align-items: center;

            @include breakpoint(l) {
                display: none;
            }

            &.closed::after,
            &.open::after {
                margin-left: 15px;
                display: inline-block;
                font-size: 24px;
                font-weight: bold;

            }

            &.closed::after {
                content: '+';
            }

            &.open::after {
                content: '-';
            }
        }

        &-info-container {
            &.closed {
                display: none;

                @include breakpoint(l) {
                    display: flex;
                }
            }

            &.open {
                display: flex;
            }

            @include fadeIn;
            background-color: $wineclub-accent-bg-color;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
            padding: 30px 0 15px;
        }

        &-image,
        &-info {
            min-width: ($wineclub-item-width + 42);
            width: ($wineclub-item-width + 42);

            @include breakpoint(l) {
                min-width: $wineclub-item-width;
                width: $wineclub-item-width;
            }
        }

        &-image {
            margin: 0 auto;
            display: none;

            @include breakpoint(l) {
                display: block;
            }

            img {
                min-width: 100%;
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }

        &-info {
            margin: 0 auto;
            padding: 0 15px;

            @include breakpoint(l) {
                padding: 0 30px 0 15px;
            }

            &-title {
                color: $wineclub-title-color;
                font-size: 1.125rem;
                text-align: left;
                margin-bottom: 10px;
            }

            &-subtitle {
                display: none;
                margin-bottom: 10px;
                font-size: 1.75rem;
                color: $wineclub-text-color;
            }

            &-description {
                h3 {
                    margin-bottom: 10px;
                    font-size: 1.75rem;
                    font-weight: bold;
                    color: $wineclub-text-color;
                }

                p {
                    margin-bottom: 10px;
                    color: $wineclub-text-color;
                    font-weight: bold;
                    font-size: 1rem;
                }

                ul {
                    margin-bottom: 0;

                    li {
                        color: $wineclub-text-color;

                        a {
                            @extend %wineclub-link;
                        }
                    }
                }
            }
        }
    }

    //-------------------------------------//
    //     WineClub Signup - Membership    //
    //-------------------------------------//
    &-membership {
        background-color: $wineclub-accent-bg-color;
        padding: 15px 30px 40px;

        &-title {
            @include wineclub-title;
            margin-bottom: 20px;

            @include breakpoint(l) {
                font-size: 22px;
            }
        }

        &-buttons {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 15px;

            button {
                @extend %wineclub-button;
                font-size: 1rem;
                min-width: 130px;

            }
        }
    }

    //-------------------------------------//
    //     WineClub Signup - Shipping      //
    //-------------------------------------//
    &-shipping {
        background-color: $wineclub-main-bg-color;
        padding: 30px;
        text-align: center;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;

        &-title {
            @include wineclub-title;
        }

        select {
            border: 1px solid $wineclub-accent-border-color;
            background-color: $wineclub-main-bg-color;
            color: $wineclub-button-color;
            padding: 8px 30px;
            font-size: 16px;
            text-transform: uppercase;
            margin-bottom: 15px;
            width: 100%;

            @include breakpoint(xxs) {
                width: auto;
            }
        }

        &-pickup {
            color: $wineclub-text-color;

            &-address {
                margin-bottom: 10px;
            }
        }

        &-description {
            color: $wineclub-color-dark;
        }

        .shipping-popup {
            margin-top: 15px;

            button {
                @include vfi-focus($wineclub-color-dark);
                padding: 5px;
                background-color: transparent;
                border: none;
                color: $wineclub-color-dark;
                text-decoration: underline;

            }
        }
    }

    //-------------------------------------//
    //  WineClub Signup - Shipping Modal   //
    //-------------------------------------//
    &-shipping-modal {
        .msc-modal__dialog {
            width: 80%;

            @media (min-width: $msv-breakpoint-m) {
                width: auto;
            }
        }

        .msc-modal__close-button {
            color: $wineclub-button-color;
            opacity: 1;
        }

        &-title {
            @include wineclub-title;
            margin-bottom: 15px;
        }

        &-content {
            padding: 30px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
        }

        &-map {
            flex: 0 1 100%;
            max-width: 100%;
            min-width: 100%;
            padding-bottom: 30px;

            @media (min-width: $msv-breakpoint-m) {
                padding: 0 0 30px 30px;
            }

            @media (min-width: $max-landing-page-width) {
                flex: 0 1 50%;
                max-width: 50%;
                min-width: 50%;
            }

            @media (min-width: ($max-landing-page-width + 370px)) {
                flex: 0 1 60%;
                max-width: 60%;
                min-width: 60%;
                padding: 0 30px 30px;
            }

            .shipping-map-svg {
                height: 100%;

                @media (min-width: $max-landing-page-width) {
                    height: auto;
                }
            }

            .shipping-map-legend-item {
                color: $wineclub-text-color;
            }
        }

        &-description {
            color: $wineclub-text-color;
            flex: 0 1 100%;
            max-width: 100%;
            min-width: 100%;

            @media (min-width: $msv-breakpoint-m) {
                padding: 0 30px;
            }

            @media (min-width: $max-landing-page-width) {
                flex: 0 1 45%;
                max-width: 45%;
                min-width: 45%;
                padding: 0;
            }

            @media (min-width: ($max-landing-page-width + 370px)) {
                flex: 0 1 35%;
                max-width: 35%;
                min-width: 35%;
            }
        }

        ul {
            column-count: 2;
            column-width: 120px;
            column-gap: 20px;
        }
    }


    //-------------------------------------//
    //       WineClub Signup - Terms       //
    //-------------------------------------//
    &-terms {
        background-color: $wineclub-accent-bg-color;
        padding: 30px;

        &-title {
            @include wineclub-title;
        }

        .page-tos {
            max-width: 900px;
            margin: 0 auto;
            color: $wineclub-text-color;

            &-text a {
                @extend %wineclub-link;
            }

            &-checkbox {
                padding-top: 15px;
                font-size: 14px;
                text-transform: uppercase;
                display: flex;
                align-items: center;

                &-checkbox {
                    min-width: 16px;
                    min-height: 16px;
                }

                @include breakpoint(l) {
                    justify-content: flex-start;
                    font-size: 18px;
                }

                &-text {
                    margin-left: 15px;
                    margin-bottom: 0;
                }
            }
        }
    }

    //-------------------------------------//
    //      WineClub Signup - Signup       //
    //-------------------------------------//
    &-signup {
        padding: 0 30px;

        &-button {
            @extend %wineclub-button;
            font-size: 16px;
            min-width: 120px;
            transition: ease-in-out 0.3s opacity;

            &:disabled {
                color: $wineclub-button-color;
                border: 1px solid $wineclub-button-color;
                background-color: transparent;
                opacity: 0.4;
            }
        }
    }

    &-signup-message {
        padding: 15px 30px;
        color: $wineclub-text-color;
    }

    &-signup,
    &-signup-message {
        background-color: $wineclub-accent-bg-color;
        text-align: center;
        justify-content: center;
        display: flex;
    }

    //-------------------------------------//
    //     WineClub Signup - Questions     //
    //-------------------------------------//
    &-questions {
        .row {
            background-color: transparent;
            padding: 30px 30px 15px;
            text-align: center;

            .container-heading {
                @include wineclub-title;
                margin-top: 0;
                margin-bottom: 15px;
                color: $wineclub-accent-bg-color;
            }

            .ms-text-block {
                color: $wineclub-accent-bg-color;

                a {
                    @extend %wineclub-link;
                    color: $wineclub-accent-bg-color;

                    &:hover,
                    &:focus {
                        color: darken($color: $wineclub-accent-bg-color, $amount: 10%);
                    }
                }
            }
        }
    }
}