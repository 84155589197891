$search-result-container-page-item-previous-padding-right: 10px;
$search-result-container-page-item-previous-padding-left: 4px;
$search-result-container-page-item-padding-right: 10px;
$search-result-container-page-item-next-padding-right: 4px;
.sort_and_filter_desktop_view {
  max-width: none;
  padding: 0;
  .col-lg-3 {
    display: none;
  }

  .col-lg-9 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }
}

.sort_and_filter_mobile_view {
  max-width: none;
  padding: 0;
  .sort-and-filter-container {
    display: none;
  }
}

.products-by-category,
.ms-search-result-container {
  .list-unstyled {
    display: grid;
    grid-template-columns: 100%;
    @include media-breakpoint-up(md) {
      grid-template-columns: 50% 50%;
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: 33.33% 33.33% 33.33%;
    }
  }
  .ms-search-result__collection-title-text {
    font-size: $font-size-heading-shop-l;
    text-transform: uppercase;
    color: $color-kabul;
  }

  .category-description {
    margin-bottom: 1rem;
    color: $color-text-body--shop;
    font-size: $font-size-body-xs;
    line-height: 1.4;
  }

  .product-placement__item,
  .ms-product-search-result__item {
    position: relative;
    z-index: 1;
    display: inline-block;
    max-width: 100%;
    height: 330px;
    padding: 0;
    margin-right: 2px;
    border-top: solid 1px $color-border--shop;
    vertical-align: top;
    line-height: 18px;

    &:focus {
      outline: none;
    }

    a,
    a:hover,
    a:visited {
      text-decoration: none;
      color: #3e280d;
      padding: 0;
    }

    .product-details {
      width: 100%;

      .fa-chevron-right {
        color: $color-link--shop;
        float: right;
        margin: 50px 10px 0px 0px;
      }

      .product-details-header {
        background-color: $color-background-accent--shop;
        padding: 30px;
        height: 130px;
      }

      .appellation {
        display: none;
        color: $color-text-body--shop;
        font-size: $font-size-body-xs;
        font-style: italic;
        text-transform: uppercase;
      }

      .description {
        display: none;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding: 20px 15px 0 90px;
        color: $color-text-body--shop;
        font-size: $font-size-body-xs;
        line-height: 1.5;
        -webkit-line-clamp: 4;
      }

      .acclaim-list {
        padding: 8px 0 0 90px;
        max-width: 240px;
        min-height: 85px;
      }

      .acclaim-entry {
        float: left;
        padding-top: 10px;
      }

      .acclaim-source {
        font-size: $font-size-body-xxs - 1px;
      }
    }

    .product-placement__item-image,
    img {
      display: block;
      margin: auto;
      position: absolute;
      left: 0;
      bottom: 15px;
      width: 100%;
      max-height: 184px;
      object-fit: cover;
    }

    .product-type-bottled-wine,
    .product-type-canned-wine,
    .product-type-small-bottle {
      .product-details {
        .product-attribute.appellation {
          display: block;
        }

        .product-attribute.description {
          display: -webkit-box;
        }

        .product-details-header {
          padding-left: 90px;
        }
      }

      .product-placement__item-image,
      img {
        bottom: 53px;
        width: auto;
        object-fit: fill;
      }
    }

    .product-type-bottled-wine {
      .product-placement__item-image,
      img {
        max-width: 57px;
        max-height: 260px;
        left: 18px;
      }
    }

    .product-type-canned-wine {
      .product-placement__item-image,
      img {
        max-width: 57px;
        max-height: 120px;
        left: 18px;
      }
    }

    .product-type-small-bottle {
      .product-placement__item-image,
      img {
        max-width: 40px;
        max-height: 135px;
        left: 26px;
      }
    }

    .product-type-assembly {
      .product-placement__item-image,
      img {
        object-fit: contain;
      }
      
      .product-details-header {
        padding: 48px 30px 30px 90px;
      }
      // csm - from override 11/15/2022
      .product-details {
        padding: unset;
      }
    }

    .product-placement__item-title {
      top: 0;
      height: 75px;
      color: $color-link--shop;
      font-family: $font-family-heading;
      font-size: $font-size-heading-shop-xs;

      &:hover,
      &:focus,
      &:active {
        color: $color-link-hover--shop;
      }
    }

    .product-lightbox {
      display: none;
      position: absolute;
      bottom: 50px;
      right: 15px;

      @include media-breakpoint-up(sm) {
        display: block;
      }

      .launch-lightbox-button {
        width: 55px;
        text-align: center;
        border: 1px solid $color-border--shop;
        padding: 3px 0 2px;
        background-color: $color-background-accent--shop;
        color: $color-link--shop;
        font-size: $font-size-link-s;
        line-height: 1.3;

        &:hover,
        &:focus,
        &:active {
          box-shadow: none;
          border-color: $color-border--shop;
          background-color: $color-background-accent--shop;
          color: $color-link-hover--shop;
        }
      }
    }
  }
}
nav.ms-search-result-container__pagination {
  width: 100%;
}

.msc-pagination {
  display: flex;
  list-style: none;
  padding-left: 0;
  width: 100%;
}

.previous.msc-page-item {
  display: list-item;
  flex-grow: 1;
  padding-right: $search-result-container-page-item-previous-padding-right;
  text-align: left;

  .msc-pagination__prev {
    padding-left: 4px;
    display: inline-block;
  }
  .prev-text {
    margin-left: 0.25rem;
  }
  .msc-page-link {
    &:before {
      @include msv-icon();
      content: "";
    }

    .ms-search-result__pagination-left {
      @include add-icon($msv-ChevronLeft, before);
    }
  }
}

.msc-page-item {
  padding-right: $search-result-container-page-item-padding-right;
}

.next.msc-page-item {
  display: list-item;
  flex-grow: 1;
  padding-right: $search-result-container-page-item-next-padding-right;
  text-align: right;

  .msc-pagination__next {
    padding-right: 4px;
    display: inline-block;
  }
  .next-text {
    margin-right: 0.25rem;
  }

  .msc-page-link {
    padding-right: 4px;

    &:after {
      @include msv-icon();
      content: "";
    }

    .ms-search-result__pagination-right {
      @include add-icon($msv-ChevronRight, after);
    }
  }
}

.msc-page-link {
  @include vfi();
}

//override merchandise products
.merchandise-product {
  .ms-search-result-container .ms-product-search-result__item {
    img {
      left: 0;
      bottom: 15px;
      width: 100%;
      max-width: 100%;
      max-height: 205px;
      object-fit: cover;
    }
    .product-details .product-details-header {
      padding: 30px;
    }
  }
}
.gifts {
  .ms-search-result-container {
    .ms-product-search-result__item {
      img {
        display: block;
        margin: auto;
        position: absolute;
        left: 0;
        bottom: 15px;
        width: 100%;
        max-width: none;
        max-height: 205px;
        object-fit: cover;
      }
      .product-details {
        .product-details-header {
          padding: 30px;
        }
      }
    }
  }
}
