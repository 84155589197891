$msv-password-reset-width-m: 100%;
$msv-password-reset-button-group-spacing: 32px;
$msv-password-reset-button-margin-top-large: 1rem;
$msv-password-reset-button-margin-top-small: 0.5rem;
.ms-password-reset {
    &__continue-button {
        @include primary-button();
        margin-top: $msv-password-reset-button-margin-top-large;
        width: 100%;
    }
    &__cancel-button {
        @include secondary-button();
        margin-top: $msv-password-reset-button-margin-top-small;
        width: 100%;
    }
    &__verifying-modal,
    &__verifying-modal-message {
        display: none;
    }
    &__account-items {
        div:first-of-type p {
            line-height: 21px;
        }
    }
    @media screen and (max-width: $msv-breakpoint-m) {
        &__container {
            width: $msv-password-reset-width-m;
        }
    }
    input {
        @include form-input-el();
    }
}

.password-reset-requirements {
    div {
        padding: revert;
    }

    .ms-password-reset {
        margin-top: 0;
    }
}
