%shop-container {
    max-width: $max-width-page--shop;
    padding: 1rem;
    background-color: $color-background-body--shop;

    @include media-breakpoint-up(sm) {
        width: $width-page--shop;
        padding: 1.5rem 2rem;
    }
}

.shop {
    min-width: 100%;
    background-image: $asset-background-shop;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $color-background-inverse--shop;
    color: $color-text-body--shop;
    font-size: $font-size-body-s;
    line-height: 1.5;

    @include breakpoint(small) {
        min-width: $min-width-page--shop;
    }

    #renderPage header {
        margin-bottom: 1rem;
        @media (max-width: ($msv-breakpoint-l - 1)) {
            background-color: $color-oil;
        }

        .ms-header__container {
            max-width: $max-shop-page-width;
        }
        .ms-header {
            .ms-header__topbar {
                margin: 0;
            }
            .ms-nav.desktop-vp {
                padding: 0;
            }
        }
    }

    #main {
        @extend %shop-container;
        margin-top: 2rem;
        background-image: none;

        @include media-breakpoint-up(sm) {
            margin: 2rem auto;
        }

        @include media-breakpoint-up(lg) {
            margin: 1rem auto;
            margin-bottom: 2rem;
        }
    }

    #subfooter {
        @extend %shop-container;
        margin-top: -1px;

        @include media-breakpoint-up(sm) {
            margin: -3rem auto 2rem;
        }
    }
}
.sort_and_filter_desktop_view {
    .col-lg-3 {
        display: none;
    }

    .col-lg-9 {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
    }
}

.sort_and_filter_mobile_view {
    .sort-and-filter-container {
        display: none;
    }
}

.products-by-category {
    .category-title {
        font-size: $font-size-heading-shop-l;
    }

    .category-description {
        margin-bottom: 1rem;
        color: $color-dovegray;
        font-size: $font-size-body-s;
        font-style: italic;
        line-height: 1.4;
    }

    .product-placement__item {
        position: relative;
        z-index: 1;
        display: inline-block;
        max-width: 317px;
        height: 330px;
        padding: 0;
        margin-right: 2px;
        border-top: solid 1px $color-silver;
        vertical-align: top;
        line-height: 18px;

        &:focus {
            outline: none;
        }

        a,
        a:hover,
        a:visited {
            text-decoration: none;
            color: #3e280d;
            padding: 0;
        }

        .product-details {
            width: 317px;

            .fa-chevron-right {
                color: $color-limedoak;
                float: right;
                margin: 55px 10px 0px 0px;
            }

            .product-details-header {
                background: $color-pampas;
                padding: 30px 30px 30px 90px;
                height: 130px;
            }

            .appellation {
                color: $color-silver;
                font-size: $font-size-body-xs;
                font-style: italic;
                text-transform: uppercase;
            }

            .description {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                padding: 20px 15px 0 90px;
                color: $color-silver;
                font-size: $font-size-body-s;
                line-height: 1.5;
                -webkit-line-clamp: 4;
            }

            .acclaim-list {
                padding: 8px 0 0 90px;
                max-width: 240px;
                min-height: 85px;
            }

            .acclaim-entry {
                float: left;
                padding-top: 10px;
            }
        }

        .product-placement__item-image {
            display: block;
            margin: auto;
            position: absolute;
            bottom: 52px;
            left: 18px;
            max-width: 60px;
            max-height: 260px;
        }

        .product-placement__item-title {
            top: 0;
            max-height: 60px;
            overflow: hidden;
            color: $color-limedoak;
            font-family: $font-family-heading;
            font-size: $font-size-heading-shop-xs;
            text-transform: uppercase;

            &:hover,
            &:focus,
            &:active {
                color: $color-kabul;
            }
        }

        .product-lightbox {
            display: none;
            position: absolute;
            bottom: 50px;
            right: 15px;

            @include media-breakpoint-up(sm) {
                display: block;
            }

            .launch-lightbox-button {
                width: 55px;
                text-align: center;
                border: 1px solid $color-silver;
                padding: 3px 0 2px;
                background-color: $color-pampas;
                color: $color-limedoak;
                font-size: $font-size-link-s;

                &:hover,
                &:focus,
                &:active {
                    box-shadow: none;
                    border-color: $color-silver;
                    background-color: $color-pampas;
                    color: $color-kabul;
                }
            }
        }
    }
}
