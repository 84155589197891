.msc-price {
  &.discount-value {
    .msc-price__strikethrough {
      display: inline-block;
      margin-right: 5px;
    }
  }

  &__strikethrough {
    color: $color-dustygray;
    margin-right: 5px;
    text-decoration: line-through;
  }
}
