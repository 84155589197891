.landing {
  background-image: none;
  background-color: $color-background-body--landing;
  color: $color-text--landing;
  font-family: $font-family-body--landing;
  font-size: $font-size-body--landing;
  line-height: 1.5;

  #renderPage header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: static;
    padding: 2rem 0;
    margin: 0;
    background-image: none;
    background-color: transparent;
    text-align: center;
    .ms-content-block__details {
      display: none;
    }
  }

  #main {
    width: auto;
    max-width: $max-width-hero--landing;
    padding: 0;
    margin: 0 auto;
    border: 0;
    outline: 0;
    background-image: none;
    background-color: transparent;
  }

  #renderPage footer {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    background-image: none;
    background-color: transparent;
  }

  %styles-landing {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $font-family-heading--landing;
    }

    h1 {
      color: $color-heading--landing;
      font-size: $font-size-heading--landing / 2;

      @include media-breakpoint-up(sm) {
        font-size: $font-size-heading--landing;
      }
    }

    h2 {
      color: $color-text--landing;
      font-size: $font-size-heading--landing / 2;

      @include media-breakpoint-up(sm) {
        font-size: $font-size-heading--landing;
      }
    }

    h5 {
      color: $color-appellation--landing;
      font-size: $font-size-appellation--landing;
      font-style: italic;
      text-transform: uppercase;
    }

    a {
      text-decoration: none;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }

    .btn,
    .msc-cta__primary {
      padding: 0.8rem 1.2rem;
      border-color: $color-button-border--landing;
      background-color: $color-button-background--landing;
      color: $color-button-text--landing;
      font-family: $font-family-button--landing;
      font-size: $font-size-button--landing;
      font-weight: bold;
      text-transform: uppercase;
      border: 0.8px solid $color-button-primary-border;
      letter-spacing: 0.85px;

      &:hover,
      &:focus,
      &:active {
        border-color: $color-button-border-hover--landing;
        background-color: $color-button-background-hover--landing;
        color: $color-button-text-hover--landing;
      }

      .fa-chevron-right {
        display: none;
      }
    }

    .hero-picture img,
    .ms-content-block__image img {
      width: 100%;
      height: auto;
      min-height: 250px;
      object-fit: cover;
    }
    
    .content-with-media-media-image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  #main > *,
  &-footer {
    @extend %styles-landing;
  }
  &-content-block{
    
    .ms-content-block__title {
      color:#564a43 !important;
    }
  }
  &-mail-form {
    padding-right: 4rem;
    button {
      color: #bba7a1;
      border-color: #bba7a1;
      background-color: rgba(0,0,0,0);
    }
  }
  &-headline {    
    text-align: center;
    margin-left: auto;
    margin-right: auto;    
  }
  &-hero {
    position: relative;
    height: auto;
    min-height: 0;
    margin: 0;

    .hero-text-alignment-container,
    .ms-content-block__details {
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 1rem;
      align-items: center;
      text-align: center;
      justify-content: flex-end;

      @include media-breakpoint-up(sm) {
        padding: 3rem 4rem;
      }

      .hero-heading,
      p {
        max-width: 430px;
        color: $color-white;
        text-shadow: 0 0 5px $color-black;
      }

      p {
        font-size: $font-size-body--landing * 1.1;
      }

      .hero-cta button,
      .hero-cta a {
        font-size: $font-size-button--landing * 1.2;
        box-shadow: 0 0 5px transparentize($color-black, 0.7);
      }

      &.x-left {
        align-items: flex-start;
      }

      &.x-right {
        align-items: flex-end;
        text-align: right;
      }

      &.x-center {
        align-items: center;
        text-align: center;
      }

      &.y-top {
        justify-content: flex-start;
      }

      &.y-bottom {
        justify-content: flex-end;
      }

      &.y-center {
        justify-content: center;
      }
    }
  }

  &-overview,
  &-featured,
  &-wine-finder,
  &-content-grid {
    width: 100%;
    max-width: $max-width-page--landing;
    margin: 1rem 0;

    @include media-breakpoint-up(sm) {
      width: $width-page--landing;
      margin: 3% auto;
    }
  }

  &-overview {
    padding: 0 1rem;
    margin: 2rem 0;
    text-align: center;

    @include media-breakpoint-up(sm) {
      padding: 2rem 3rem;
      margin: 0 auto;
    }
  }

  &-featured,
  &-wine-finder,
  &-promo {
    background-color: $color-background-container--landing;
  }

  &-featured > div,
  &-wine-finder > div,
  &-content-grid,
  &-content-grid > .row > div {
    padding: 0;
  }

  &-featured {
    > div {
      flex: auto;
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: 50%;
      }
    }

    > div:last-child:not(:only-child) {
      display: flex;
      align-items: center;
    }

    .content-with-media-media,
    .content-with-media-media-image {
      height: 100%;
    }

    .content-with-media-text {
      padding: 1rem 1.5rem;

      @include media-breakpoint-up(sm) {
        padding: 2rem 3rem;
      }
    }
  }

  &-wine-finder .ms-content-block__details {
    padding: 1rem 1.5rem;

    @include media-breakpoint-up(sm) {
      padding: 2rem 3rem;
    }
  }

  &-content-grid > .row {
    margin: 0;

    > div {
      flex: auto;
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: 48.5%;
      }
    }

    > div:empty {
      display: none;
    }

    > div:not(:first-child) {
      margin-top: 1rem;

      @include media-breakpoint-up(lg) {
        margin-top: 3%;
      }
    }

    > div:nth-child(2) {
      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }

    > div:nth-child(2n-1) {
      @include media-breakpoint-up(lg) {
        margin-right: 1.5%;
      }
    }

    > div:nth-child(2n) {
      @include media-breakpoint-up(lg) {
        margin-left: 1.5%;
      }
    }
  }

  &-promo {
    height: 100%;
    min-height: 0;
    margin: 0;

    .hero-text-alignment-container,
    .ms-content-block__details {
      position: static;
      padding: 1rem 1.5rem;

      @include media-breakpoint-up(sm) {
        padding: 2rem 3rem;
      }
    }
  }

  &-footer {
    display: block;
    padding: 2rem 0 0 !important;
    border: 0;
    color: $color-text-footer--landing;
    font-family: $font-family-body--landing;
    font-size: $font-size-body--landing;
    line-height: 1.5;
    text-align: center;
    max-width: $width-page--landing;
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
      text-align: left;
    }

    &-links,
    .ms-footer__list {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0;
      .footer-category-slot,
      .ms-footer__list-item {
        width: 100%;

        @include media-breakpoint-up(lg) {
          width: auto;
        }
      }

      .footer-item-link a,
      .ms-footer__item a {
        color: $color-link--landing;
        font-family: $font-family-link--landing;
        font-size: $font-size-link--landing;
        font-weight: bold;
        text-transform: uppercase;

        &:hover,
        &:focus,
        &:active {
          color: $color-link-hover--landing;
        }
      }
    }

    > div {
      padding: 0;

      .footer-category-slot,
      .ms-footer__list-item {
        padding: 0;

        &:not(:first-child) {
          margin-top: 0.5rem;

          @include media-breakpoint-up(lg) {
            margin: 0 0 0 1rem;
          }
        }
      }
    }
  }

  &-copyright {
    padding: 1rem 0 !important;

    @include media-breakpoint-up(lg) {
      padding: 0 !important;
      margin-bottom: 1rem;
    }
  }
  .landing-featured {
    display: flex;
  }
}

.landing-hero {
  &.textplacement__left {
    .ms-content-block__details {
      align-items: flex-start;
    }
  }
}
.landing-promo {
  a {
    color:$color-limedoak;
    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active:focus {
        color: $color-kabul;
    }
  }
}
