$msv-sign-up-width-m: 100%;
$msv-sign-up-email-verification-buttons-margin-top: 0.5rem;
$msv-sign-up-button-margin-top-large: 0;
$msv-sign-up-button-margin-top-small: 0.5rem;
.ms-sign-up {
  &__heading {
    @include account-heading();
    text-transform: initial !important;
  }
  &__account-item-input {
    @include form-input-el();
  }
  label {
    color: $color-dovegray;
    margin-bottom: 2px;
  }
  &__create-button {
    margin-top: 1rem !important;
    &:disabled {
      opacity: 0.65;
      background-color: $color-gallery;
      border-color: $color-silver;
      color: $color-silver;
    }
  }
  &__page-error,
  &__item-error {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    display: none;
    margin: 0.5rem 0;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    p {
      line-height: 1.2;
      margin-bottom: 0;
    }
    p::before {
      font-family: $msv-icon-font-family;
      font-weight: $msv-solid-icon-weight;
      content: $msv-Warning;
      margin-right: 0.5rem;
    }
    &:not(:empty)[aria-hidden="false"] {
      display: block !important;
    }
  }
  &__email-verification-buttons {
    margin-top: $msv-sign-up-email-verification-buttons-margin-top;
  }
  &__verify-email-verify-button,
  &__create-button {
    @include primary-button();
    margin-top: $msv-sign-up-button-margin-top-large;
    width: 100%;
    border: 1px solid;
  }
  &__verify-email-send-button {
    @include primary-button();
    width: 100%;
    border: 1px solid;
  }
  &__verify-email-resend-button {
    @include secondary-button();
    margin-top: 1rem;
    width: 100%;
  }
  &__verify-email-edit-button {
    @include secondary-button();
  }
  &__cancel-button {
    @include secondary-button();
    margin-top: $msv-sign-up-button-margin-top-small;
    width: 100%;
    border: 1px solid;
  }
  &__account-item-givenName,
  &__account-item-surname,
  &__account-item-email,
  &__account-item-newPassword,
  &__account-item-reenterPassword {
    // need classes to raise specificity
    .ms-sign-up__item-error .ms-sign-up__item-error-text {
      line-height: 1.5;
    }
  }
  @media screen and (max-width: $msv-breakpoint-m) {
    &__container {
      width: $msv-sign-up-width-m;
    }
  }
}
