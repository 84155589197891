$border-bottom: 1px solid $color-silver;

$msv-order-history-padding-bottom: 0;

$msv-order-history-order-information-margin-top: 20px;
$msv-order-history-order-information-margin-bottom: 13px;

$msv-order-history-header-padding-bottom: 0;
$msv-order-history-header-border-bottom: $border-bottom;

$msv-order-history-order-padding: 0 2rem;

$msv-order-history-sales-order-border-bottom: $border-bottom;

$msv-order-history-order-information-sales-id-padding-right: 5px;
$msv-order-history-order-information-sales-id-border-right: $border-bottom;

$msv-order-history-sales-lines-margin-top: 8px;

$msv-order-history-sales-line-picture-margin-top: 0.3rem;
$msv-order-history-sales-line-picture-margin-right: 3px;
$msv-order-history-sales-line-picture-height: 120px;
$msv-order-history-empty-image-width:81px;

$msv-order-history-btn-order-details-margin-bottom: 0;

$msv-order-history-order-information-created-date-padding-right: 5px;
$msv-order-history-order-information-created-date-padding-left: 5px;
$msv-order-history-order-information-created-date-border-right: $border-bottom;

$msv-order-history-order-information-count-padding-right: 5px;
$msv-order-history-order-information-count-padding-left: 5px;
$msv-order-history-order-information-count-border-right: $border-bottom;

$msv-order-history-order-information-amount-padding-left: 5px;

$msv-container-padding-left: 60px;
$msv-container-padding-right: 60px;
$msv-container-width: 100%;

$msv-order-history-group-margin-bottom: 20px;

$msv-order-history-group-delivery-heading-margin-right: 3px;

$msv-order-history-btn-keep-shopping-margin-bottom: 20px;
$msv-order-history-alert-margin-top: 1rem;
$msv-order-history-alert-margin-bottom: 1rem;
$msv-order-history-alert-margin-left: 2rem;
$msv-account-profile-heading-margin-bottom: 32px;
$msv-account-keep-shopping-padding: 0.4rem 0.8rem 0.6rem;
$msv-order-history-keep-shopping-margin-left: 2rem;

.ms-order-history {
    padding-bottom: $msv-order-history-padding-bottom;

    &__heading {
        padding: 0.8rem 1rem 0.5rem;
        background-color: $color-pampas;
        font-family: $font-family-body !important;
        font-size: 18px;
        margin-bottom: $msv-account-profile-heading-margin-bottom;
        width: 100%;
    }

    &__header {
        padding-bottom: $msv-order-history-header-padding-bottom;
    }

    &__order-count {
        display: inline-block;
        padding: $msv-order-history-order-padding;
    }

    &__sales {
        &-order {
            position: relative;
         padding: $msv-order-history-order-padding;
        }

        &-status {
            display: none;
        }
    }

    &__order-information {
        margin: 0.5rem 0;
    }

    &__order-information-sales-id {
        padding-right: $msv-order-history-order-information-sales-id-padding-right;
        border-right: $msv-order-history-order-information-sales-id-border-right;
    }

    &__order-information-channel-reference-id {
        display: block;
    }

    &__sales-lines {
        margin-top: $msv-order-history-sales-lines-margin-top;
        display: flex;
        flex-wrap: wrap;
    }

    &__sales-line-picture {
        margin-top: $msv-order-history-sales-line-picture-margin-top;
        margin-right: $msv-order-history-sales-line-picture-margin-right;
        margin-bottom: 10px;
        .msc-empty_image {
            display: none !important;
        }

        img {
            height: 100%;
            max-height: 120px;
            max-width: 100px;
            width: auto;
        }
    }

    &__btn-order-details {
        @extend .btn-secondary;
        display: inline-block;
        margin: 15px 0 30px;
    }

    &__order-information-created-date {
        padding-right: $msv-order-history-order-information-created-date-padding-right;
        padding-left: $msv-order-history-order-information-created-date-padding-left;
        border-right: $msv-order-history-order-information-created-date-border-right;
    }

    &__order-information-count {
        padding-right: $msv-order-history-order-information-count-padding-right;
        padding-left: $msv-order-history-order-information-count-padding-left;
        border-right: $msv-order-history-order-information-count-border-right;
    }

    &__order-information-amount {
        padding-left: $msv-order-history-order-information-amount-padding-left;
    }

    &__group {
        margin-bottom: $msv-order-history-group-margin-bottom;

        &-delivery {
            &-heading {
                font-weight: $font-weight-bold;
                margin-right: $msv-order-history-group-delivery-heading-margin-right;
            }

            &-processing-label {
                display: block;
            }
        }
    }

    &__address {
        &-header {
            display: none;
        }

        &-name {
            margin-right: 5px;
            display: block;
        }
    }

    &__empty-message,
    &__alert {
        display: block;
        margin-top: $msv-order-history-alert-margin-top;
        margin-bottom: $msv-order-history-alert-margin-bottom;
        margin-left: $msv-order-history-alert-margin-left;
    }

    &__btn-keep-shopping {
        @extend .btn-primary;
        margin-left: $msv-order-history-keep-shopping-margin-left;
        width: auto;
        display: inline-block !important;
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__btn-order-details {
            width: 100%;
            margin-bottom: $msv-order-history-btn-order-details-margin-bottom;
            position: unset;
        }
    }

    .msc-empty_image {
        @include image-placeholder($msv-order-history-empty-image-width);
    }
}
