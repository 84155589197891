.btn,
.shop .btn {
    border-radius: 0;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }

    .fa-chevron-right {
        display: none;
    }
}

.btn-link {
    color: $color-link;

    &:hover,
    &:focus,
    &:active {
        color: $color-link-hover;
    }
}

.shop .btn-link {
    color: $color-link--shop;

    &:hover,
    &:focus,
    &:active {
        color: $color-link-hover--shop;
    }
}

.btn-primary,
.shop .btn-primary {
    padding: 0.4rem 0.8rem 0.5rem;
    border-color: $color-button-primary-border;
    background-color: $color-button-primary-background;
    color: $color-button-primary-text;
    font-family: $font-family-link;
    font-size: $font-size-link-m;
    letter-spacing: 0.85px;
    text-transform: uppercase;
    border: 0.8px solid $color-button-primary-border;
    @media (max-width: $msv-breakpoint-l - 1) {
        display: block;
        text-align: center;
    }
    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active:focus {
        border-color: $color-button-primary-border-hover;
        background-color: $color-button-primary-background-hover;
        color: $color-button-primary-text-hover;
    }
}

.btn-secondary,
.shop .btn-secondary {
    @extend .btn-primary;
    border-color: $color-button-secondary-border;
    background-color: $color-button-secondary-background;
    color: $color-button-secondary-text;

    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active:focus {
        border-color: $color-button-secondary-border-hover;
        background-color: $color-button-secondary-background-hover;
        color: $color-button-secondary-text-hover;
    }

    &:focus {
        box-shadow: none;
        outline: 3px solid $color-darkgray;
    }
}

.btn-primary.disabled,
.btn-primary:disabled,
.btn-secondary.disabled,
.btn-secondary:disabled,
.msc-btn.disabled,
.shop .btn-primary.disabled,
.shop .btn-primary:disabled,
.shop .btn-secondary.disabled,
.shop .btn-secondary:disabled {
    border-color: $color-button-disabled-border;
    background-color: $color-button-disabled-background;
    color: $color-button-disabled-text;
    pointer-events: none;
}


.row {
    margin: 0;
}

%grid-column {
    padding: 0;
}
.msc-cta__primary {
    @extend %link-marketing;
}