.add-to-cart-header {
    background-color: $color-oil;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 0;
    margin-top: 3rem;
    margin-bottom: 3rem;
    text-align: center;
    max-width: 1000px;
}

.add-by-url,
.add-by-url-working {
    background-color: $color-oil;
    padding: 2rem;
    max-width: 1000px;
    text-align: center;
    color: $color-thatch;
}

.add-by-url-cta .call-to-action {
    margin-top: 1.5rem;
    @extend .btn-primary;
}

@media (min-width: 576px) {
    .add-to-cart-header {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .add-by-url,
    .add-by-url-working {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}