$msv-order-summary-font: $font-family-link;
$msv-order-summary-padding: 32px;
$msv-order-summary-primary-bg-color: $color-pampas;
$msv-order-summary-heading-font-size: 24px;
$msv-order-summary-heading-font-weight: 600;
$msv-order-summary-heading-line-height: 28px;
$msv-order-summary-heading-padding-bottom: 7px;
$msv-order-summary-label-font-size: 14px;
$msv-order-summary-label-line-height: 1.5;
$msv-order-summary-line-padding: 5px 0;

.msc-order-summary {
  &-wrapper {
    align-items: center;
    background-color: $msv-order-summary-primary-bg-color;
    display: flex;
    flex: 0 0 368px;
    flex-direction: column;
    margin: 0 auto;
    height: fit-content;

    @media only screen and (min-width: $msv-breakpoint-l) {
      margin-right: 0;
    }

    @media only screen and (max-width: $msv-breakpoint-l) {
      width: 100%;
      margin: 0;
      flex: 1;
    }
  }

  @media screen and (max-width: $msv-breakpoint-s) {
    margin: unset;
  }

  &__heading {
    @include text-shop-heading-primary;
    font-size: $msv-order-summary-heading-font-size;
    font-family: $msv-order-summary-font;
    font-weight: 500;
    line-height: $msv-order-summary-heading-line-height;
    width: 100%;
    margin-bottom: 1rem;
  }

  &__items {
    border-radius: 2px;
    font-size: $msv-order-summary-label-font-size;
    line-height: $msv-order-summary-label-line-height;
    width: 100%;
    text-align: left;

    .msc-promo-code {
      &-heading {
        font-weight: $msv-order-summary-heading-font-weight;
      }

      &__group {
        display: flex;
        padding: 10px 0;
      }

      &__input-box {
        flex: auto;
        margin-right: 20px;
        padding-left: 8px;
      }
    }
  }

  &__line {

    &-sub-total,
    &-tax-amount,
    &-shipping,
    &-loyalty,
    &-gift-card,
    &-total-discounts {
      margin-bottom: 0.25rem;
    }

    &-total {
      padding: 15px 0 20px;
      border-top: 1px solid $color-silver;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 28px;
      box-sizing: content-box;
      height: 0;
      margin-top: 1rem;
      margin-bottom: .3rem;
    }
  }

  &__value {
    float: right;
    text-align: right;
  }
}

@media screen and (min-width: $msv-breakpoint-l) {
  .msc-order-summary-wrapper {
    margin: 0;
    margin-left: auto;
    flex-basis: 50%;
    background-color: $color-white;
    padding: 0;
  }
}