$msv-account-profile-heading-margin-bottom: 32px;

$msv-account-profile-section-margin-bottom: 40px;
$msv-account-profile-section-heading-font-weight: 600;
$msv-account-profile-section-heading-margin-bottom: 0.2rem;
$msv-account-profile-section-description-margin-bottom: 6px;

$msv-account-profile-preference-heading-font-weight: 600;
$msv-account-profile-preference-margin-top: 20px;
$msv-account-profile-preference-heading-margin-bottom: 10px;
$msv-account-profile-preference-description-margin-bottom: 10px;

$msv-account-profile-toggle-button-icon-size: 24px;
$msv-account-profile-toggle-button-padding: 0 5px;

.ms-account-profile {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid $color-kabul;

  &__section {
    display: flex;
    flex-wrap: wrap;
    padding: 0 2rem;
    &:last-child {
      margin-bottom: 1.5rem;
    }
    &-heading,
    h4 {
      font-family: $font-family-body !important;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 0;
      text-transform: none;
      color: $color-dovegray;
      line-height: 1;
    }

    &-description {
      margin-left: 0.5rem;
      line-height: 1;
      margin-bottom: 0;
    }

    &-links {
      width: 100%;
      margin-top: 1px;
    }

    &-link {
      @include vfi();
    }
  }

  &__preference {
    margin-top: $msv-account-profile-preference-margin-top;
    display: none;
    &-heading {
      margin-bottom: 0;
      @include font-content-m(
        $msv-account-profile-preference-heading-font-weight
      );
      margin-bottom: $msv-account-profile-preference-heading-margin-bottom;
    }

    &-description {
      margin-bottom: $msv-account-profile-preference-description-margin-bottom;
    }
  }

  &__toggle-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &__toggle-button {
    font-size: $msv-account-profile-toggle-button-icon-size;
    background-color: transparent;
    padding: $msv-account-profile-toggle-button-padding;

    &:focus {
      @include basic-outline-offset();
    }
  }

  &__toggle-enable {
    .ms-account-profile__toggle-enable-text {
      font-weight: bold;
    }
  }

  &__toggle-disable {
    .ms-account-profile__toggle-disable-text {
      font-weight: bold;
    }
  }

  &__toggle-enable-button {
    @include add-icon($msv-ToggleLeft);
    color: $color-kabul;
    border: none;
  }

  &__toggle-disable-button {
    @include add-icon($msv-ToggleRight);
  }

  .ms-account-loyalty-tile__heading {
    padding: 0.8rem 1rem 0.5rem;
    background-color: $color-pampas;
    font-family: $font-family-body !important;
    font-size: 18px;
    color: $color-kabul;
    margin-bottom: $msv-account-profile-heading-margin-bottom;
    width: 100%;
  }
}
.ms-order-history {

  &__btn-keep-shopping {
    @include primary-button();
    color: $color-pampas !important;
    width: auto;
    display: inline-block;
  }
}
.msc-address-form__button-save {
  margin-top: 1.5rem;
}
