$msv-checkout-gift-card-max-flex-basis: 100%;
$msv-checkout-gift-card-margin: 30px;

$msv-checkout-gift-card-input-label-margin-bottom: 12px;

$msv-checkout-gift-card-button-height: 32px;
$msv-checkout-gift-card-button-border-radius: 2px;
$msv-checkout-gift-card-button-margin-left: 1rem;

$msv-checkout-gift-card-input-height: 35px;
$msv-checkout-gift-card-input-border-radius: 2px;
$msv-checkout-gift-card-input-padding: 0.5rem 1rem;
$msv-checkout-gift-card-input-width: 40%;

$msv-checkout-gift-card-error-max-width: 512px;
$msv-checkout-gift-card-error-padding: 7px 15px;
$msv-checkout-gift-card-error-margin-right: 12px;
$msv-checkout-gift-card-error-margin-bottom: 0;
$msv-checkout-gift-card-error-icon-margin-right: 8px;

$msv-checkout-gift-card-item-text-width: 60%;

$msv-checkout-gift-card-btn-remove-border: none;
$msv-checkout-gift-card-btn-remove-padding: 0;

$msv-checkout-gift-card-remove-button-margin-left: 10px;

.ms-checkout {
  &-gift-card__form {
    display: flex;
    flex-flow: row wrap;
    margin-top: 0;
    margin-bottom: 20px;
    .ms-checkout-gift-card__input-fields {
      width: 100%;
    }
  }

  &-gift-card {
    margin-bottom: $msv-checkout-gift-card-margin;
    margin-top: ($msv-checkout-gift-card-margin - 10);
    &__item {
      line-height: 0;
    }
    &__title {
      font-weight: $font-weight-600;
      font-size: $font-size-body-s;
      line-height: 20px;
      color: $color-dovegray;
      text-transform: uppercase;
    }
    &__input-label {
      font-size: 14px;
      flex-basis: $msv-checkout-gift-card-max-flex-basis;
    }

    &__input-text {
      border: 1px solid $color-swisscoffee;
      box-sizing: border-box;
      border-radius: $msv-checkout-gift-card-input-border-radius;
      min-height: $msv-checkout-gift-card-input-height;
      padding: $msv-checkout-gift-card-input-padding;
      width: 100%;
      background-color: $color-pampas;
      font-size: 14px;
    }

    &__btn-apply {
      @extend .btn-primary;
      margin-top: 1rem;
    }

    &__input-error {
      background-color: $color-bg-error;
      border: 1px solid $color-border-error;
      padding: $msv-checkout-gift-card-error-padding;
      font-size: 14px;
      color: $color-font-error;
      margin-bottom: $msv-checkout-gift-card-error-margin-bottom;
      width: 100%;
      border-radius: 5px;
      display: block;
    }

    &__item-text {
      padding-right: 0.5rem;
      font-size: $font-size-body-s;
      font-weight: 400;
      line-height: 20px;
      color: $color-dovegray;
    }

    &__btn-remove {
      @extend .btn-link;
      margin: 0;
      padding: 0;
      border: 0;
      background: none;
      font-size: $font-size-body-s;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
