$msv-feature-body-color: $color-dovegray;
$buybox-product-title-size: 34px;
$buybox-font-size: 14px;
$buybox-content-items-padding-top: 20px;
$buybox-content-items-padding-top-small: 12px;
$buybox-margin-top: 2rem;
$buybox-button-height: 32px;
$buybox-quantity-width: 50px;
$buybox-find-in-store-description-padding-bottom: 7px;
$buybox-product-title-line-height: 1.2;
$buybox-quantity-label-line-height: 20px;
$buybox-label-margin-bottom: 5px;
$buybox-title-margin-bottom: 0.5rem;
$buybox-dropdown-height: 30px;
$buybox-ratings-margin-top: 10px;
$gutter-width: 40px;
$media-gallery-width: 28%;

// mixin
@mixin buybox-button {
  width: 100%;
}
.msc-ss-carousel-vert {
  display: none;
}
.ms-buybox {
  padding-top: $buybox-margin-top;
  display: flex;
  flex-wrap: wrap;
  flex-flow: column;
  @include media-breakpoint-up(md) {
    flex-flow: row;
  }
  // content styles
  &__content {
    color: $color-dovegray;
    font-size: $buybox-font-size;
    flex-grow: 1;
    padding: 0;
    max-width: 100%;
    @include media-breakpoint-up(md) {
      padding: 0 2rem;
      max-width: 87%;
    }
  }

  &__product-attribute-apellation {
    color: $color-dovegray;
    font-size: 14px;
    font-style: italic;
    text-transform: uppercase;
  }

  &__product-title,
  h1 {
    font-size: $buybox-product-title-size;
    line-height: $buybox-product-title-line-height;
    margin-bottom: $buybox-title-margin-bottom;
    color: $color-limedoak;
    font-family: $font-family-heading;
  }

  &__dropdown,
  &__quantity {
    padding-top: none;
  }
  &__quantity {
    label {
      margin: 0 !important;
    }
    input {
      background-color: $color-white;
      border: 1px solid $color-swisscoffee;
      @media (min-width: $msv-breakpoint-m) {
        background-color: $color-pampas;
      }
    }
  }
  .product-attribute-nv-value {
    display: none;
  }
  .col-12 {
    .product-attribute-nv-value {
      display: block;
    }
    .product-attribute-event-description-intro, .product-attribute-event-description {
      margin-top: 1rem;
    }
  }
  &__find-in-store-heading,
  &__find-in-store-description {
    display: none;
  }

  &__ratings-link {
    @include vfi();
    display: inline-block;
  }

  .msc-price__actual,
  .ms_buybox__dropdown-quantity-label,
  .ms_buybox__product-quantity-label-heading {
    color: $color-dovegray;
    font-weight: $font-weight-600;
    line-height: $buybox-quantity-label-line-height;
  }

  &__media-gallery {
    display: block;
    max-width: 100%;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid $color-silver;
    position: relative;
    width: 100%;
    @include media-breakpoint-up(sm) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    @include media-breakpoint-up(md) {
      flex: 0 0 66.66667%;
      flex-basis: $media-gallery-width;
      max-width: $media-gallery-width;
      margin: 0;
      border: none;
    }
    .ms-media-gallery {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 1rem;
      height: auto;
      .msc-carousel__item {
        img {
          max-width: 100%;
          @include media-breakpoint-up(sm) {
            max-height: 387px;
          }
          @include media-breakpoint-up(lg) {
            max-height: 700px;
          }
        }
        .non-wine-product {
          height: auto !important;
        }
      }
      .product-link {
        display: none;
      }
    }
  }
  .message-success-text {
    font-size: $font-size-body-s;
    font-weight: normal;
  }
  .acclaim-list {
    padding: 0;
  }
  .acclaim-entry {
    padding: 1rem 0;
  }
  .msc-no-ratings {
    margin-top: $buybox-ratings-margin-top;
  }
  .msc-dropdown__select {
    @include vfi();
    height: $buybox-dropdown-height;
    width: 100%;
  }

  .msc-quantity-input {
    @include vfi();
    height: $buybox-dropdown-height;
    text-align: center;
    width: $buybox-quantity-width;
    &:focus {
      @include default-outline;
    }
  }

  &__add-to-cart-container {
    .msc-alert-danger {
      @include quantity-alert();
      .msc-alert__header {
        display: inline;
        float: left;
        margin-right: 0.5rem;
      }
      .msc-alert__line {
        display: flex;
        margin: 0;
      }
    }
    .msc-add-to-cart {
      @include primary-button();
      font-size: $font-size-link-m;
      border: none;
      box-shadow: none;
      text-transform: uppercase;
      width: 100%;
      @media (max-width: $msv-breakpoint-m - 1) and (min-width: 300px) {
        position: relative;
        left: -27%;
      }
      @include breakpoint(sm) {
        left: -15%;
      }
      &:disabled,
      &.disabled {
        font-size: 12.5px;
        line-height: 1;
        padding: 0.4rem 0.5rem 0.5rem;
        cursor: default;
        border: 1px solid $color-silver;
        background-color: $color-gallery;
        color: $color-silver;
        opacity: 0.65;
        text-align: center;
        &:before {
          display: none;
        }
      }
      @include media-breakpoint-down(sm) {
        @include add-icon($msv-ShoppingCart, before, 900);
        &:before {
          margin-right: 0.25rem;
        }
        &:focus {
          @include default-outline;
        }
      }
      @include media-breakpoint-up(lg) {
        display: flex;
        @include add-icon($msv-ShoppingCart, before, 900);
        &:before {
          margin-right: 0.25rem;
        }
        &:focus {
          @include default-outline;
        }
      }

      @media (min-width: $msv-breakpoint-m) {
        width: auto;
        border: none;
        background-color: transparent;
        color: $color-limedoak;
        text-shadow: none;
        &:hover,
        &:focus,
        &:active,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):focus,
        &:not(:disabled):not(.disabled):active:focus {
          color: $color-kabul;
          border: none;
          background-color: transparent;
        }
      }
    }
  }

  &__add-to-wishlist-container {
    padding-top: $buybox-content-items-padding-top-small;

    .msc-add-to-wishlist {
      &:after {
        color: $color-limedoak;
      }
      border-color: transparent;
      background-color: transparent;
      width: $buybox-button-height;
    }
  }

  &__find-in-store-description {
    padding-bottom: $buybox-find-in-store-description-padding-bottom;
  }

  &__find-in-store {
    padding-top: $buybox-content-items-padding-top;
    .ms-buybox__find-in-store-button {
      @include vfi();

      &:not(:disabled) {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  //mobile vp
  @media (max-width: $msv-breakpoint-m) {
    .ms-media-gallery {
      width: 100%;
      .msc-carousel__item {
        img {
          margin: 0 auto;
          height: auto;
          max-height: 387px;
        }
        .non-wine-product {
          height: auto !important;
        }
      }
    }
  }
  .sku-selector-product-title {
    width: auto;

    @media (min-width: $msv-breakpoint-m) {
      width: 30%;
    }
    @media (min-width: $msv-breakpoint-l) {
      width: 40%;
    }
  }
  .product-attribute-apellation {
    color: $color-dovegray;
    font-size: 14px;
    text-transform: uppercase;
  }

  label {
    display: block;
    margin-bottom: $buybox-label-margin-bottom;
    font-weight: $font-weight-600;
  }
  .product-attribute {
    &-download {
      display: flex;
      align-items: center;
      margin: 1rem 0;
      i {
        color: $color-link--shop;
        font-size: $font-size-icon-s;
      }
      .btn-link {
        @extend %text-shop-subheading;
        padding: 0;
        margin-left: 0.5rem;
        border: 0;
        color: $color-link--shop;
        font-weight: bold;

        &:hover,
        &:focus,
        &:active {
          color: $color-link-hover--shop;
        }
      }
    }
    &-nv {
      h4 {
        color: $color-kabul;
        font-weight: bold;
      }
      &-name {
        @extend %text-shop-subheading;
        margin-top: 1.5rem;
        font-weight: bold;
      }
    }
    &-wine {
      display: flex;
      &-key {
        margin-right: 0.25rem;
      }
    }
    &-event-description > *:not(:first-child) {
      margin-top: 1rem;
    }
    &-event-description-intro {
      font-size: $font-size-body-m + 2px;
      font-weight: bold;
    }
  }
  .msc-product-attribute-download-cta {
    @include add-icon($msv-arrow-right, before, 900);
    font-size: 12px;
    &:before {
      margin-right: 0.25rem;
      transform: rotate(90deg);
      border: 2px solid $color-limedoak;
      border-radius: 50%;
    }
    a {
      &:focus {
        @include default-outline;
      }
    }
    @media (min-width: $msv-breakpoint-m) {
      width: auto;
      border: none;
      background-color: transparent;
      color: $color-limedoak;
      text-shadow: none;
      &:hover,
      &:focus,
      &:active,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):focus,
      &:not(:disabled):not(.disabled):active:focus {
        color: $color-kabul;
        border: none;
        background-color: transparent;
      }
    }
    @media (min-width: $msv-breakpoint-s) {
      width: auto;
      border: none;
      background-color: transparent;
      color: $color-limedoak;
      text-shadow: none;
      &:hover,
      &:focus,
      &:active,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):focus,
      &:not(:disabled):not(.disabled):active:focus {
        color: $color-kabul;
        border: none;
        background-color: transparent;
      }
    }
  }
  .sku-selector-container {
    padding-top: 3rem;
    margin: 0;
    border-bottom: 1px solid $color-silver;
    position: relative;
  }
  .table-responsive {
    overflow-x: hidden;
    @media (min-width: $msv-breakpoint-m) {
      overflow-x: auto;
    }
  }
  table {
    display: inline-flex;
    padding: 1rem;
    background-color: $color-pampas;
    overflow: hidden;
    @media (min-width: $msv-breakpoint-m) {
      display: table;
      table-layout: fixed;
      width: 100%;
      padding: 0;
      background: transparent;
    }
  }
  thead {
    display: inline-block;
    width: 40%;
    @media (min-width: $msv-breakpoint-m) {
      display: table-header-group;
      width: auto;
    }
  }
  .table {
    th,
    td {
      padding: 0.5rem;
      vertical-align: middle;
    }
    th {
      @extend %text-shop-subheading;
      font-weight: normal;
      text-align: right;

      @include media-breakpoint-up(md) {
        color: $color-text-muted--shop;
        text-align: initial;
      }
    }
    td {
      color: #212529;
      font-size: 14px;
      font-family: $font-family-body;
      font-weight: 400;
      line-height: 1.5;
      @media (min-width: $msv-breakpoint-m) {
        line-height: 1;
        &:last-child {
          padding-right: 0;
        }
      }
      &.sku-selector-add-to-cart {
        @include breakpoint(md) {
          position: static;
        }
      }
    }
    tr {
      display: inline-grid;
      width: 100%;
      @media (min-width: $msv-breakpoint-m) {
        display: table-row;
        width: auto;
      }
      td,
      th {
        &:first-child {
          @include media-breakpoint-up(md) {
            padding-left: 0;
          }
        }
      }
      .sku-selector-product-quantity {
        .ms-buybox__quantity {
          label {
            display: none;
          }
        }
      }
      .sku-selector-product-title {
        width: auto;

        @include media-breakpoint-up(md) {
          width: 30%;
        }

        @include media-breakpoint-up(lg) {
          width: 40%;
        }
      }
      .sku-selector-product-price,
      .sku-selector-product-quantity {
        @include media-breakpoint-up(md) {
          text-align: center;
        }
      }
    }
    tbody {
      width: 70%;

      @include media-breakpoint-up(sm) {
        width: 60%;
      }

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }
  }
  &__media-gallery {
    .msc-carousel__item {
      height: unset;
      max-height: unset;
      img {
        max-height: 700px;
      }
      .non-wine-product {
        height: auto !important;
      }
    }
  }
}

.buybox-product-details-wrapper {
  display: flex;
  width: 100%;
  border-bottom: 1px solid lightgray;
  .buybox-product-details {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;
  }
  .ms-buybox__configure {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    .ms-buybox__dropdown {
      padding-right: 20px;
    }
  }
}
.product-widget > .row {
  min-height: 175px;
  margin: 2rem 0 0;

  > * {
    flex: 0 0 100%;
    padding: 1.5rem;
    background-color: $color-background-accent--shop;

    &:first-child:not(:last-child) {
      @include media-breakpoint-up(lg) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }

    &:nth-child(2):last-child {
      @include media-breakpoint-up(lg) {
        flex: 0 0 49.5%;
        max-width: 49.5%;
        margin-left: 0.5%;
      }
    }

    &:nth-child(2):not(:last-child),
    &:nth-child(3) {
      @include media-breakpoint-up(lg) {
        flex: 0 0 24.5%;
        max-width: 24.5%;
        margin-left: 0.5%;
      }
    }

    &:not(:first-child) {
      margin-top: 0.25rem;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }

    &:first-child {
      background-color: $color-background-inverse--shop;
    }

    h3 {
      color: $color-heading-title-inverse--shop;
      font-size: $font-size-heading-shop-s + 3px;
      text-transform: uppercase;
    }

    h4 {
      @extend %text-shop-subheading;
      color: $color-link--shop;
      line-height: 1.4;
    }

    h6 {
      color: $color-heading-subtitle-inverse-accent--shop;
      font-family: $font-family-body;
      font-size: $font-size-heading-shop-xs;
    }

    p {
      font-size: $font-size-body-xxs;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .hero-cta {
      margin-top: 1rem;

      a,
      button {
        background-color: darken($color-link-accent--shop, 5);
        border-color: darken($color-link-accent--shop, 5);

        &:hover,
        &:focus,
        &:active {
          background-color: $color-link-accent--shop;
          border-color: $color-link-accent--shop;
        }
      }
    }
  }
}
.price-width-adjust {
  @media (max-width: $msv-breakpoint-m) {
    line-height: 2.1rem;
    input {
      max-width: 100px;
    }
  }
  @media (min-width: $msv-breakpoint-m) {
    width: 100px;
    margin: 0 auto;
  }
}
.shop:not(.buyboxlite) {
  .merchandise {
    .col-12 {
      &:nth-last-child(2) {
        order: -1;
      }
    }
    display: flex;
    flex-wrap: wrap;
    .ms-buybox__product-title {
      margin-bottom: 1rem;
      order: -2;
    }
    .sku-selector-container {
      padding-top: 4rem;
      width: 100%;
    }
    .msc-alert-danger {
      top: -1rem;
    }
  }
}