$msv-forgot-password-color: $color-limedoak;
$msv-sign-in-font-size: 14px;
$msv-sign-in-line-height: 20px;
$msv-sign-in-link-font-size: 12px;
$msv-sign-in-link-line-height: 26px;
$msv-sign-in-heading-font-size: 24px;
$msv-sign-in-heading-font-weight: normal;
$msv-sign-in-heading-line-height: 32px;
$msv-sign-in-button-margin-bottom: 60px;
$msv-sign-in-button-margin-top: 1rem;
$msv-sign-in-facebook-color: #3b5998;
$msv-sign-input-height: 32px;
$msv-sign-in-account-item-margin-top: 1rem;
$msv-sign-in-input-margin-top: 4px;
$msv-sign-in-input-padding: 8px;
$msv-sign-in-section-spacing: 25px;
$msv-sign-in-section-spacing-m: 40px;
$msv-sign-in-social-accounts-padding: 40px 0;
$msv-sign-in-social-button-padding: 8px 16px;
$msv-sign-in-social-accounts-button-margin-bottom: 20px;
$msv-sign-in-social-accounts-text-margin-left: 16px;
$msv-sign-in-link-margin-top: 8px;
$msv-sign-in-sign-up-description-margin-top: 20px;
$msv-sign-in-sign-up-description-margin-bottom: 32px;
$msv-sign-in-message-padding: 20px;
$msv-sign-in-message-margin-top: 20px;
$msv-sign-in-error-border: 1px solid $color-white;
$msv-sign-in-error-text-color: $color-white;
$msv-sign-in-container-margin: 50px 100px;
$msv-sign-in-container-margin-m: 20px;
.ms-sign-in {
  font-family: $font-family-body;
  max-width: none;
  margin: 0;
  padding: 1.5rem 2rem;
  @media screen and (min-width: 576px) {
    padding: 3rem 4rem;
  }
  &__container {
    display: block;
    width: 100%;
    margin: 0;
    label {
      margin-bottom: 0;
      color: $color-dovegray;
    }
  }
  &__item-error-text,
  &__page-error-text {
    margin: 0;
  }
  &__social-account-picture {
    display: inline-block;
    vertical-align: middle;
  }
  &__sign-in-heading,
  &__sign-up-heading {
    @include account-heading();
    text-transform: initial !important;
  }
  &__sign-in-section {
    flex: 1;
    h2 {
      margin-bottom: 2rem;
    }
  }
  &__sign-up-section {
    flex: 1;
    h2 {
      display: none;
    }
  }
  &__account-item {
    margin-top: $msv-sign-in-account-item-margin-top;
    &-input {
      @include form-input-el();
    }
  }
  &__container-forget-password {
    text-align: center;
    margin: 1rem 0 0.5rem;
  }
  &__forget-password {
    display: inline-block;
    color: $msv-forgot-password-color;
    font-size: $font-size-body-s;
    &:hover,
    &:active,
    &:focus {
      color: $color-kabul;
    }
  }
  &__sign-in-button {
    @include primary-button();
    margin-top: $msv-sign-in-button-margin-top;
    width: 100%;
    padding: 0.4rem 0.8rem 0.55rem;
    border: none;
  }
  &__sign-up-link {
    @include secondary-button();
    width: 100%;
    display: block;
    text-align: center;
    padding: 0.4rem 0.8rem;
    margin-top: 1rem;
  }
  &__social-accounts {
    display: none;
  }
  &__sign-up-description {
    position: relative;
    padding: 0;
    margin: 1.25rem 0;
    color: transparent;
    display: none;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: 1px;
      background-color: $color-kabul;
    }
    &::after {
      content: "or";
      color: $color-dovegray;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0 0.5rem;
      background-color: $color-white;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
  &__page-error,
  &__item-error {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    display: none;
    margin: 0.5rem 0;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    p {
      line-height: 1.2 !important;
      margin-bottom: 0;
    }
    p::before {
      font-family: $msv-icon-font-family;
      font-weight: $msv-solid-icon-weight;
      content: $msv-Warning;
      margin-right: 0.5rem;
    }
    &:not(:empty)[aria-hidden="false"] {
      display: block !important;
    }
  }
}

.account {
  .ms-header__account-info {
    display: flex;
    justify-content: flex-end;
    flex-basis: 0;
    flex-grow: 1;
    @include media-breakpoint-up(lg) {
      &::before {
        margin-right: 0.5rem;
        padding: 0.375rem;
        content: "Open Daily 10:00am-5:00pm";
        display: block;
        font-size: $font-size-topheader;
        color: $color-thatch;
        line-height: 1.6;
      }
    }
  }
}
.aad-message {
  padding: 1.5rem 2rem;
  background-color: $color-oil;
  min-height: 0;
  .ms-content-block__title {
    display: none;
  }
  h5 {
    color: $color-pampas;
  }
  p {
    margin-bottom: 0;
    color: $color-thatch;
    line-height: 1.5;
    font-size: $font-size-body-m;
  }
  a {
    color: $color-limedoak;
    &:hover {
      color: $color-pampas;
    }
  }
}
.sign-in-show-password {
  float: right;
  margin: -30px 5px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  background: none;
  border: none;
}

.icon-show-password {
  @extend .fas;
  @extend .fa-eye;
}

.icon-hide-password {
  @extend .fas;
  @extend .fa-eye-slash;
}
