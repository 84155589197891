$msv-checkout-payment-instrument-min-height: 35px;
$msv-checkout-payment-instrument-card-title-margin-bottom: 8px;
$msv-checkout-payment-instrument-address-title-margin-top: 20px;

$msv-checkout-payment-instrument-loading-dimension: 100%;
$msv-checkout-payment-instrument-border: 0;
$msv-checkout-payment-instrument-margin-left: -8px;

$msv-checkout-payment-container-padding: 0px;
$msv-checkout-payment-container-margin-zero: 0px;

$msv-checkout-payment-ready-margin-bottom: 36px;
$msv-checkout-payment-ready-flex-basis: 100%;
$msv-checkout-payment-ready-margin-left: 12px;
$msv-checkout-payment-circular-size: 25px !important;

.ms-checkout-payment-instrument,
.checkout-payment-instrument {
  position: relative;
  min-height: $msv-checkout-payment-instrument-min-height;

  &__loading-background {
    display: flex;
    height: $msv-checkout-payment-instrument-loading-dimension;
    position: absolute;
    width: $msv-checkout-payment-instrument-loading-dimension;
    font-size: 1rem;

    .waiting {
      display: block;
      margin: auto;
      font-size: 1rem;
    }

    .msc-waiting-circular {
      width: $msv-checkout-payment-circular-size;
      height: $msv-checkout-payment-circular-size;
    }
  }

  &__iframe {
    border: $msv-checkout-payment-instrument-border;
    margin-left: $msv-checkout-payment-instrument-margin-left;
  }

  &__show {
    &-card-title {
      font-weight: $font-weight-600;
      margin-bottom: $msv-checkout-payment-instrument-card-title-margin-bottom;
    }

  &-billing-address-title {
      margin-bottom: $msv-checkout-payment-instrument-card-title-margin-bottom;
      margin-top: $msv-checkout-payment-instrument-address-title-margin-top;
    }

    &-card-name,
    &-card-number,
    &-card-expiration,
    &-billing-address-1,
    &-billing-address-2,
    &-billing-address-3 {
      margin-bottom: $msv-checkout-payment-container-margin-zero;
    }
  }

  &__card-type-id {
    display: none;
  }
  &__error {
    &-title {
      display: none;
    }
    &-message {
      @extend %error-alert;
      padding: 0.5rem 0.75rem;
      font-size: $font-size-body-s;
      &::before {
        margin-right: 0.5rem;
      }
    }
  }
}

.ms-checkout-section-container {
  padding-left: $msv-checkout-payment-container-padding;
  padding-right: $msv-checkout-payment-container-padding;

  &__item {
    &[data-type-name="smwe-checkout-gift-card"] {
      margin-top: -1rem !important;
    }
  }
}

.ms-checkout__guided-card.ready {
  .ms-checkout-payment-instrument {
    &__show {
      display: flex;
      flex-flow: row wrap;
    }

    &__card-title {
      font-weight: bold;
      font-size: $font-size-body-l;
      line-height: 20px;
      flex-basis: $msv-checkout-payment-ready-flex-basis;
      text-transform: uppercase;
    }

    &__add {
      body {
        margin: 16px 16px 8px 0;
      }
    }

    p {
      margin: 0;
      width: 100%;
      font-size: $font-size-body-s;
      color: $color-dovegray;
    }
  }
}

// Paypal ready styling
.ms-checkout-payment-instrument-paypal .ms-checkout-payment-instrument {
  &__show {
    margin-top: -1rem;
  }
  &__card-title {
    font-weight: bold;
    text-transform: uppercase;
  }
  &__card-type-id {
    display: block;
  }
  &__card-title,
  &__card-name,
  &__card-type-id,
  &__card-expiration {
    margin-bottom: 0;
    font-size: $font-size-body-s;
    color: $color-dovegray;
  }
}