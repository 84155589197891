//==============================================================================
// GLOBAL VARIABLES
//==============================================================================

$page-width: 80%;
$max-marketing-page-width: none;
$max-shop-page-width: 1025px;
$max-aad-page-width: 600px;
$max-landing-page-width: 1280px;
$max-landing-hero-width: 1440px;
$margin-aad-container: -2rem auto 2rem;
$margin-aad-container-mobile: -1rem auto;
$breakpoint-s: 767px;
$breakpoint-xs: 450px;
$breakpoint-xxs: 300px;
$msv-breakpoint-xs: 0px;
$msv-breakpoint-s: 0px;
$msv-breakpoint-m: 768px;
$msv-breakpoint-l: 992px;
$msv-breakpoint-xl: 1440px;
$msv-breakpoint-ipad: 1024px;


$breakpoints: (
    xxs: 300px,
    xs: 450px,
    small: 576px, 
    s: 767px,  
    m: 1024px,  
    l: 992px,   
    xl: 1440px
);
//change rule for min-width mobile first and max-width desktop first approach
@mixin breakpoint($bp, $rule: min-width) {
    @media screen and (#{$rule}: map-get($breakpoints, $bp)) {
        @content;
    }
}

//example
//@include breakpoint(m, max-width) {}
