$timeline-min-height: 700px;
$timeline-min-height__mobile: 500px;
$timeline-line-width: 2px;
$timeline-circle-size: 15px;
$timeline-circle-size__mobile: 10px;
$timeline-entry-max-width__mobile: 70%;
$timeline-entry-max-width__tablet: 400px;
$timeline-entry-max-width__desktop: 500px;
$timeline-entry-max-width: 600px;
$timeline-image-height__mobile: 200px;
$timeline-image-height__tablet: 260px;
$timeline-image-height__desktop: 325px;
$timeline-image-height: 400px;

.timeline {
    display: flex;
    justify-content: center;
    position: relative;
    min-height: $timeline-min-height__mobile;

    @include media-breakpoint-up(md) {
        min-height: $timeline-min-height;
    }

    &-scroll-hidden {
        position: relative;
        overflow-y: scroll;
        height: $timeline-min-height__mobile;
        padding-left: 1rem;
        margin-right: 1rem;

        @include media-breakpoint-up(md) {
            position: absolute;
            top: 0;
            left: 0;
            height: $timeline-min-height;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &-scroll-container {
        transition: all ease 0.5s;
        padding: calc(#{$timeline-min-height__mobile} / 2) 0 !important;
        border-left: $timeline-line-width solid $color-border-accent;

        @include media-breakpoint-up(md) {
            padding: calc(#{$timeline-min-height} / 2) 0 !important;
        }
    }

    &-year {
        display: flex;
        align-items: center;
        margin-left: calc((#{$timeline-circle-size__mobile} + #{$timeline-line-width}) / -2);

        @include media-breakpoint-up(md) {
            margin-left: calc((#{$timeline-circle-size} + #{$timeline-line-width}) / -2);
        }

        &:not(:last-child) {
            margin-bottom: 0.5rem;

            @include media-breakpoint-up(md) {
                margin-bottom: 1.5rem;
            }
        }

        &::before {
            content: '';
            display: block;
            width: $timeline-circle-size__mobile;
            height: $timeline-circle-size__mobile;
            margin-right: 1rem;
            border-radius: 50%;
            background-color: $color-text-accent--shop;

            @include media-breakpoint-up(md) {
                width: $timeline-circle-size;
                height: $timeline-circle-size;
            }
        }

        h2 {
            margin-bottom: 0;
            color: $color-text-body;
            font-size: $font-size-heading-s;

            @include media-breakpoint-up(md) {
                font-size: $font-size-heading-m;
            }
        }
    }

    &-current-selector {
        &::before {
            background-color: $color-link;
        }

        h2 {
            color: $color-link;

            @include media-breakpoint-up(md) {
                font-size: $font-size-heading-l;
            }
        }
    }

    &-previous-button {
        @include add-icon($msv-ChevronUp);
    }

    &-next-button {
        @include add-icon($msv-ChevronDown);
    }

    &-previous-button,
    &-next-button {
        color: $color-link;
        font-size: $font-size-icon-xl / 2;
        text-align: center;

        &:disabled,
        &.disabled {
            color: $color-border-accent;
            pointer-events: none;
        }
    }

    &-entry {
        max-width: $timeline-entry-max-width__mobile;
        text-align: center;

        @include media-breakpoint-up(md) {
            max-width: $timeline-entry-max-width__tablet;
            margin-left: 3rem;
        }

        @include media-breakpoint-up(lg) {
            max-width: $timeline-entry-max-width__desktop;
            margin-left: 0;
        }

        @include media-breakpoint-up(xl) {
            max-width: $timeline-entry-max-width;
        }
    }

    @keyframes timeline-fade-in {
        0% {
            opacity: 0;
        }

        25% {
            // Hack to cover up image load time -- remove this when there's a better load solution
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    &-item-show {
        @extend %styles-marketing;
        color: $color-text-body;
        animation: timeline-fade-in 0.6s;

        > div {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: $color-heading-title;
            }

            h1 + p,
            h2 + p,
            h3 + p,
            h4 + p,
            h5 + p,
            h6 + p {
                margin-top: 1rem;
            }

            p {
                margin-bottom: 0.5rem;
                color: $color-text-body;
            }

            > *:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-item-image {
        width: auto !important;
        height: $timeline-image-height__mobile !important;
        margin: 0 auto 1rem;

        @include media-breakpoint-up(md) {
            height: $timeline-image-height__tablet !important;
        }

        @include media-breakpoint-up(lg) {
            height: $timeline-image-height__desktop !important;
        }

        @include media-breakpoint-up(xl) {
            height: $timeline-image-height !important;
        }
    }
}
