$msc-account-welcome-title-margin-bottom: 40px;
$msc-account-welcome-title-border-bottom-color: $color-silver;
$msc-account-welcome-title-border-bottom-style: solid;
$msc-account-welcome-title-border-bottom-thickness: 1px;

$msc-account-tile-heading-icon-padding: 5px;

$msc-generic-tile-heading-margin-bottom: 0.5rem;
$font-size-heading: 24px;
.ms-account {
  &-welcome-tile {
    display: flex;
    flex-wrap: wrap;
    padding: 0 2rem;
    width: 100%;
    margin-bottom: 0.5rem;
    &__heading {
      @include font-content-xl();
    }
  }

  &-generic-tile {
    &__link {
      @include vfi();
    }
    &.order-tile {
      .ms-account-generic-tile__heading {
        @include add-icon($msv-DeliveryTruck, before);
        &:before {
          padding-right: $msc-account-tile-heading-icon-padding;
        }
      }
    }

    &.profile-tile {
      .ms-account-generic-tile__heading {
        @include add-icon($msv-Contact, before);
        &:before {
          padding-right: $msc-account-tile-heading-icon-padding;
        }
      }
    }
  }

  &-wishlist-tile__heading {
    @include add-icon($msv-HeartFill, before);
    &:before {
      padding-right: $msc-account-tile-heading-icon-padding;
    }
  }

  &-address-tile__heading {
    @include add-icon($msv-MapPin, before);
    &:before {
      padding-right: $msc-account-tile-heading-icon-padding;
    }
  }

  &-generic-tile,
  &-wishlist-tile,
  &-address-tile {
    margin-bottom: $msc-account-welcome-title-margin-bottom;
    &__heading {
      @include font-content-l($font-weight-bold);
      margin-bottom: $msc-generic-tile-heading-margin-bottom;
    }

    &__links {
      @include font-content-m-underline();
    }

    &__link {
      @include vfi();
    }
  }
}
.account {
  .container-with-header-header {
    @extend %text-shop-heading-container;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .container-with-header-title {
      @extend %text-shop-heading-primary;
    }

    .container-with-header-title,
    .container-with-header-text p {
      margin-bottom: 0;
    }
  }
  .container-with-header-slots {
    display: flex;
    flex-wrap: wrap;

    > div:nth-child(2) {
      flex: 0 0 100%;

      @include media-breakpoint-up(lg) {
        flex: 1 1 60%;
      }
    }

    > div:nth-child(3) {
      flex: 0 0 100%;

      @include media-breakpoint-up(lg) {
        flex: 1 1 39.5%;
        margin-left: 0.5%;
      }

      .content-block-with-title {
        padding: 2rem 2rem 0;
      }

      .content-block-with-title-text p:first-child {
        font-size: 26px;
      }
      .content-block-with-title-text {
        p {
          font-size: 14px;
        }
      }
    }
  }
  .ms-account-welcome-tile {
    .ms-account-welcome-tile__heading {
      @extend %text-shop-heading-secondary;
      margin-bottom: 1.5rem;
      font-weight: normal;
      text-transform: none;
      line-height: 1;
    }
  }
}
