$club-checkout-text-color: $color-kabul;
$club-checkout-field-color: $color-dovegray;

.club-checkout .ms-checkout__guided-card-body {
    margin-top: 0;
}

.club-checkout .ms-checkout__guided-card.ready .checkout-step-club-info {
    margin-top: -23px;
}

.checkout-step-club-info {
    &-heading {
        color: $club-checkout-text-color;
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
    }

    &-control {
        margin-top: 1rem;
        line-height: 0;

        label {
            font-size: 14px;
            line-height: 1.5;
            font-weight: 400;
            margin-bottom: 0;
            color: $club-checkout-field-color;
        }

        input,
        select {
            @include vfi();
            @include form-control-14hands;
            @include form-input-el();
        }
    }
}

.checkout-club-info-collector {
    &-display-field {
        color: $club-checkout-field-color;
        font-size: 14px;
        margin-right: 0.25rem;
        margin-top: 10px;
    }

    &-display-field-name {
        font-weight: bold;
    }

    &-display-field-name,
    &-display-field-value {
        display: block;
    }
}