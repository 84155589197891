.shop.buyboxlite {
  background-color: $color-white;
  #renderPage {
    flex-direction: inherit;
  }
  #main {
    width: 100%;
    margin: 0;
    height: 100%;
    .ms-buybox {
      padding-top: 0;
      flex-flow: row;
      margin-right: -15px;
      margin-left: -15px;
      margin-top: 0;
    }
    .ms-media-gallery {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 1rem;
      img {
        max-width: 157px;
        max-height: 387px;
      }
    }
    .ms-buybox__add-to-cart-container {
      .msc-alert-danger {
        @include quantity-alert();
        font-size: 12px;
        top: -3.5rem;
        .msc-alert__header {
          display: inline;
          float: left;
          margin-right: 0.5rem;
        }
        .msc-alert__line {
          display: flex;
          margin: 0;
        }
      }
      .msc-add-to-cart {
        left: 0 !important;
        @include primary-button();
        padding: 0.35rem 0.5rem 0.55rem;
        line-height: 1.2;
        border: none;
        &:before {
          content: none;
        }
        &:disabled,
        &.disabled {
          font-size: 12.5px;
          line-height: 1;
          padding: 0.4rem 0.5rem 0.5rem;
          cursor: default;
          text-align: center;
          border: 1px solid $color-silver;
          background-color: $color-gallery;
          color: $color-silver;
          &:before {
            display: none;
          }
        }
      }
    }
    .col-12 {
      .product-attribute {
        display: none;
      }
    }
    .media-gallery-indicator-strip {
      display: none;
    }
    .ms-buybox__content {
      max-width: 500px;
      padding: 0;
    }
    .product-attribute-nv {
      padding-top: 0.5rem;
    }
    .product-link {
      font-size: 11px;
      @media screen and (min-width: 720px) {
        font-size: 14px;
      }
    }
    .product-link,
    .product-attribute-nv-value {
      display: block;
    }
    .ms-buybox {
      .sku-selector-container {
        padding: 0;
        margin: 4rem 0 1rem;
        border-bottom: 1px solid $color-border--shop;
        position: relative;

        .table {
          table-layout: fixed;
          width: 100%;
        }

        .table-responsive .table {
          background-color: transparent;
          display: table;

          th {
            margin: 0;
            text-align: left;
          }

          tbody {
            display: table-header-group;
          }
          .sku-selector-add-to-cart {
            left: 0;
            position: inherit;
          }
          thead {
            display: table-header-group;
          }

          tr {
            display: table-row;
          }
        }

        .sku-selector-product-title {
          width: auto;
          @media screen and (min-width: 720px) {
            width:180px;
          }
        }

        .sku-selector-product-quantity {
          height: auto;
          justify-content: flex-start;
        }

        th.sku-selector-product-quantity {
          padding-top: 0.5rem;
        }

        .product-price input {
          width: 100%;
        }

        .product-price.keyed-in {
          margin-top: -5px;
        }

        .quantity,
        .msc-quantity-input {
          background-color: $color-input-background;
          width: 50px;
        }

        .product-add-to-cart {
          padding: 0;

          .call-to-action__glyph {
            display: none;
          }

          p {
            margin: -0.5rem;
            font-size: $font-size-body-xxs - 1px;
          }

          .alert {
            top: -1.5rem;
            width: 100%;
            padding: 0.25rem 0.75rem 0.1rem;
          }
        }
      }
      &__media-gallery {
        border: none;
        max-width: 28%;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
    .ms-media-gallery {
      &__carousel {
        padding: 0;
        margin-bottom: 1rem;
      }
    }
    .acclaim-list {
      padding: 0;
    }
    .acclaim-entry {
      padding: 1rem 0;
    }
  }
  .message {
    .message-alert {
      margin: 0;
      padding: 0;
    }
  }
}


