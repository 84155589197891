$msv-checkout-margin-bottom: 28px;
$msv-checkout-margin-top: 28px;
$msv-checkout-margin-left-right: auto;

$msv-checkout-title-font-size: 32px;
$msv-checkout-title-line-height: 32px;
$line-height-15: 1.5;
$msv-checkout-plain-container-padding-left: 0px;

$msv-checkout-main-max-width: calc(100% - 400px);
$msv-checkout-side-width: 400px;
$msv-checkout-empty-image-width: 100%;

$msv-checkout-standard-margin-padding: 32px;

$msv-checkout-main-panel-padding-right: 40px;
$msv-checkout-guided-form-margin-bottom: 20px;
$msv-checkout-guided-card-padding-top: 20px;
$msv-checkout-guided-card-padding-bottom: 20px;
$msv-checkout-guided-card-padding-left: 32px;
$msv-checkout-guided-card-padding-right: 32px;
$msv-checkout-guided-card-border-bottom-width: 1px;
$msv-checkout-guided-card-body-margin-top: 1rem;
$msv-checkout-guided-card-footer-margin-top: 1.5rem;

$msv-checkout-button-height: 32px;
$msv-checkout-place-order-button-margin: 10px;
$msv-checkout-side-control-margin-top: 20px;
$msv-checkout-side-control-margin-bottom: 20px;
$msv-checkout-side-control-first-margin-top: 0px;
$msv-checkout-side-control-first-margin-auto: 15px auto;
$msv-checkout-side-control-place-order-button-width: 100%;
$msv-checkout-save-button-margin-left: 10px;
$msv-checkout-active-color: #323130;
$msv-checkout-inactive-color: #a19f9d;

$msv-checkout-shipping-image-width: 80px;
$msv-checkout-shipping-margin-right: 12px;
$msv-checkout-shipping-margin-top: 5px;
$msv-checkout-shipping-title-margin-top: 40px;

// checkout__lines styling
$msv-checkout-line-items-background-color: $color-dustygray;
$msv-checkout-line-items-padding: 1rem 1.5rem;
$msv-checkout-line-image-size: 108px;
$msv-checkout-line-font-style: $font-family-link;
$msv-checkout-line-font-size: 12px;
$msv-checkout-line-line-height: 20px;
$msv-checkout-line-margin-top: 20px;
$msv-checkout-line-margin-left: 20px;
$msv-checkout-line-content-width: calc(100% - 128px);

$msv-checkout-line-header-margin-bottom: 20px;
$msv-checkout-line-heading-font-size: 20px;
$msv-checkout-line-heading-font-weight: 400;
$msv-checkout-line-heading-line-height: 28px;
$msv-checkout-edit-link-color: $color-kabul;

$msv-checkout-payment-instrument-error-max-width: 512px;
$msv-checkout-payment-instrument-error-padding: 0;
$msv-checkout-payment-instrument-error-title-margin-bottom: 1rem;
$msv-checkout-payment-instrument-error-margin-top: 2px;
$msv-checkout-payment-instrument-message-margin-buttom: 1rem;

$msv-checkout-guided-card-title-font-size: 24px;
$msv-checkout-guided-card-title-line-height: 28px;
$msv-checkout-guided-card-title-step-width: 20px;
$msv-checkout-guided-card-title-text-padding-left: 12px;

$msv-checkout-edit-btn-padding: 0;
$msv-checkout-edit-btn-margin-left: auto;

$msv-checkout-cart-line-quantity-margin-left: 0;
$msv-checkout-cart-line-quantity-margin-left-auto: auto;
$msv-checkout-cart-line-quantity-label-margin-right: 5px;
$msv-checkout-cart-line-price-strikethrough-margin-right: 10px;

$msv-checkout-pick-up-at-store-margin-left: 128px;
$msv-checkout-pick-up-at-store-margin-top: 10px;
$msv-checkout-pick-up-at-store-margin-bottom: 20px;

// Mobile styles
$msv-mobile-checkout-min-width: 320px;
$msv-mobile-checkout-max-width: 100%;
$msv-mobile-checkout-padding-zero: 0px;
$msv-mobile-checkout-margin: 0px;
$msv-mobile-checkout-max-button-width: 100%;
$msv-mobile-checkout-place-order-margin-left: 0px;
$msv-mobile-checkout-place-order-margin-top: 10px;
$msv-mobile-checkout-keep-shopping-margin-top: 20px;

// Alert
$msv-checkout-error-message-padding: 18px;
$msv-checkout-error-message-margin-bottom: 20px;
$msv-checkout-error-message-width: 100%;

$msv-checkout-icon-margin-right: 8px;

.ms-checkout {
  font-size: $font-size-heading-l;
  background-color: $color-white;
  .content-block-with-title-text {
    font-size: 14px;
  }
  .msc-order-summary__heading {
    font-family: $font-family-link;
  }
  .checkout-heading > .row {
    .content-block-with-title {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 1rem 0;
      margin-bottom: 2rem;
      border-bottom: 1px solid $color-silver;
    }
    .content-block-with-title-title {
      font-size: $msv-checkout-title-font-size;
      text-transform: uppercase;
      color: $color-kabul;
      margin-bottom: 0;
    }
  }
  &__body {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  &__error-message {
    @extend %error-alert;
    width: 100%;
    padding: 0.5rem 0.75rem;
    flex-basis: 100%;
    order: 1;
    margin-bottom: 2rem;
    @include media-breakpoint-up(lg) {
      order: unset;
    }
    @include add-icon($msv-Warning);
    &::before {
      margin-right: 0.5rem;
    }
  }

  &-section-container {
    padding-left: $msv-checkout-plain-container-padding-left;

    &__item:not(:first-child) {
      margin-bottom: 5px;
    }

    &__item.hidden {
      display: none;
    }
  }

  &__title {
    font-size: $msv-checkout-title-font-size;
    line-height: $msv-checkout-title-line-height;
    align-items: center;
    margin-bottom: 0;
    font-family: $font-family-link;
    font-size: 24px;
    text-transform: uppercase;
    color: $color-black;
  }

  &__main {
    flex: auto;
    padding-right: $msv-checkout-main-panel-padding-right;
    width: 100%;
    @media (min-width: $msv-breakpoint-l) {
      flex: 1 1 50%;
      padding-right: 1rem;
    }
  }

  &__main-control {
    .ms-checkout__btn-place-order {
      margin-right: 20px;
      margin-left: 0;
      font-size: $msv-checkout-line-font-size;
      line-height: 18px;
      text-shadow: none;
    }

    .msc-btn {
      width: auto;
    }
  }
  &-shipping-address {
    line-height: 20px;
    font-size: 18px;
  }
  &__side-control-first {
    display: block;
    background-color: $color-pampas;
    justify-content: center;
    margin-top: $msv-checkout-side-control-first-margin-top;
    padding-bottom: $msv-checkout-standard-margin-padding;
    display: none;
    .ms-checkout__btn-place-order,
    .ms-checkout__btn-keep-shopping {
      width: $msv-checkout-side-control-place-order-button-width;
      margin: $msv-checkout-side-control-first-margin-auto;
    }
  }

  &__side-control-second {
    display: none;
  }

  &__main-control {
    display: flex;
    @include media-breakpoint-down(xs) {
      display: block;
    }
    .ms-checkout__btn-place-order {
      margin-right: 0.3rem;
      @include media-breakpoint-down(xs) {
        width: 100%;
        margin: 2rem 0 1rem;
      }
    }
    .ms-checkout__btn-keep-shopping {
      color: $color-dustygray;
      font-size: $font-size-body-s;
      border: 0;
      text-transform: initial;
      width: auto;
      display: inline-block;
      @include media-breakpoint-down(xs) {
        width: 100%;
        text-align: left;
        padding-left: 0;
      }
    }
  }

  &__btn-place-order {
    &.is-busy {
      &:before {
        @include msv-icon();
        content: $msv-Spinner;
        margin-right: $msv-checkout-icon-margin-right;
        -webkit-animation: spin 1s steps(8) infinite;
        animation: spin 1s steps(8) infinite;
      }
      cursor: progress;
    }

    @extend .btn-primary;
    margin-left: $msv-checkout-place-order-button-margin;
  }

  &__side {
    width: 100%;
    @media (min-width: $msv-breakpoint-l) {
      flex: 1 1 50%;
      padding-left: 1rem;
    }
  }

  &__guided-form {
    margin-bottom: $msv-checkout-guided-form-margin-bottom;
  }

  &__guided-card {
    border-bottom: $msv-checkout-guided-card-border-bottom-width solid
      $color-silver;
    padding-top: $msv-checkout-guided-card-padding-top;
    color: $msv-checkout-inactive-color;
    padding: 2rem 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border: 0;
      padding-bottom: 0;
    }
    &-header {
      display: flex;
    }

    &.hidden {
      display: none;
    }

    &.visted,
    &.active {
      color: $msv-checkout-active-color;
      text-decoration: none;
    }

    &-title {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: $font-weight-500;
      font-size: $msv-checkout-guided-card-title-font-size;
      line-height: $msv-checkout-guided-card-title-line-height;
      font-family: $font-family-link;
      color: $color-kabul;
      margin-bottom: 0;
    }

    &-title-step {
      width: $msv-checkout-guided-card-title-step-width;
    }

    &-title-text {
      padding-left: 0;
      font-family: $font-family-link;
      line-height: 1;
    }

    &-body {
      margin-top: $msv-checkout-guided-card-body-margin-top;

      &.hidden {
        display: none;
      }
    }

    &-btn-save {
      @extend .btn-primary;
      float: left;
    }

    &-btn-cancel {
      @extend .btn-secondary;
    }

    &-btn-cancel,
    &-btn-save {
      min-height: $msv-checkout-button-height;
      margin-right: 20px !important;
      font-size: 12px;
      width: auto;
      &.is-submitting {
        &:before {
          @include msv-icon();
          content: $msv-Spinner;
          margin-right: $msv-checkout-icon-margin-right;
          -webkit-animation: spin 1s steps(8) infinite;
          animation: spin 1s steps(8) infinite;
        }
        cursor: progress;
      }

      @media (min-width: $msv-breakpoint-m) {
        margin-left: 0;
      }
    }

    &-btn-edit {
      padding: $msv-checkout-edit-btn-padding;
      margin-left: $msv-checkout-edit-btn-margin-left;
      background-color: transparent;
      border: 1px solid transparent;
      font-size: 14px;
      position: relative;
      top: 1.5rem;
      z-index: 1;
      &:focus {
        border-color: lightskyblue;
      }
      &:hover {
        text-decoration: none;
      }
    }

    &-footer {
      margin-top: $msv-checkout-guided-card-footer-margin-top;
      line-height: $line-height-15;
      justify-content: space-between;
      display: inline-flex;
      @include media-breakpoint-up(md) {
        width: max-content;
      }
    }
  }

  &__side-control {
    &-first,
    &-second {
      margin-bottom: $msv-checkout-side-control-margin-bottom;
    }
  }

  &__line-items {
    background-color: $color-pampas;
    font-size: $msv-checkout-line-font-size;
    font-style: $msv-checkout-line-font-style;
    padding: $msv-checkout-line-items-padding;
    margin-top: 2rem;

    &::after {
      clear: both;
      content: "";
      display: table;
    }

    &-edit-cart-link {
      padding: $msv-checkout-edit-btn-padding;
      margin-left: auto;
      &:focus {
        @include default-outline;
      }
    }

    &-header {
      .ms-checkout__line-items-heading {
        display: inline;
        font-size: $msv-checkout-line-heading-font-size;
        font-weight: $msv-checkout-line-heading-font-weight;
        line-height: $msv-checkout-line-heading-line-height;
      }

      .ms-checkout__line-items-edit-cart-link {
        float: right;
        padding: 0;
        border: 0;
        font-family: $font-family-link;
        font-size: 14px;
        letter-spacing: 0;
        text-transform: none;
        text-decoration: none;
        font-weight: 400;
        line-height: $line-height-15;
        text-align: left;
        color: $color-dustygray;
      }
    }
    &-delivery-group {
      .ms-checkout__line-items-group-title {
        font-weight: bold;
        margin-bottom: 0.5rem;
        font-family: $font-family-link;
        color: $color-dovegray;
        font-size: 15px;
        letter-spacing: 0;
        text-transform: none;
      }

      .ms-checkout__pick-up-at-store {
        margin-left: $msv-checkout-pick-up-at-store-margin-left;
        margin-top: $msv-checkout-pick-up-at-store-margin-top;
        margin-bottom: $msv-checkout-pick-up-at-store-margin-bottom;

        .ms-checkout__store-location {
          font-weight: $font-weight-600;
        }
      }

      .msc-cart-line {
        &__product-image {
          position: relative;
          width: $msv-checkout-line-image-size;
          height: $msv-checkout-line-image-size;
          margin-right: unset;
        }
        &__content {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .msc-cart-line__product {
            flex-basis: 100%;
          }
          .msc-cart-line__product-title {
            font-weight: $font-weight-normal;
            line-height: $msv-checkout-line-line-height;
            width: 100%;
            color: $color-limedoak;
            background-color: transparent;
            box-sizing: border-box;
            font-size: 14px;
            text-align: left;
            font-family: $font-family-link;
          }

          .msc-cart-line__quantity {
            margin-left: 1rem;

            .quantity-label {
              display: inline-block;
              font-size: $font-size-body-s;
              line-height: $msv-checkout-line-line-height;
            }

            .quantity-value {
              display: inline;
              text-align: left;
            }
          }

          .msc-cart-line__product-price {
            margin-left: $msv-checkout-cart-line-quantity-margin-left-auto;

            .msc-price__strikethrough {
              font-size: 14px;
              display: inline-block;
              margin-right: 5px;
            }

            .msc-price__actual {
              font-size: $font-size-body-s;
              line-height: $line-height-15;
            }
          }
        }
      }
    }
  }
  .msc-empty_image {
    @include image-placeholder($msv-checkout-empty-image-width);
  }

  .ms-checkout__side {
    .msc-order-summary-wrapper {
      background-color: $color-white;
    }
  }

  @media screen and (max-width: $msv-breakpoint-m) {
    &__main {
      max-width: $msv-mobile-checkout-max-width;
      padding-right: $msv-mobile-checkout-padding-zero;
      flex: 1 1 50%;
    }

    &__side {
      width: $msv-mobile-checkout-max-width;
    }

    &__guided-card {
      &-body {
        padding-left: $msv-mobile-checkout-padding-zero;
        padding-right: $msv-mobile-checkout-padding-zero;
      }
      &-btn-save {
        width: auto;
        margin-right: 1rem !important;
      }
      &-btn-cancel {
        width: auto;
        margin-left: 0;
      }
    }

    &__body {
      width: $msv-mobile-checkout-max-button-width;
    }
    margin-right: $msv-mobile-checkout-margin;
    margin-left: $msv-mobile-checkout-margin;

    &__side-control-second {
      display: none;
      .ms-checkout__btn-keep-shopping,
      .ms-checkout__btn-place-order {
        width: $msv-mobile-checkout-max-button-width;
      }
      .ms-checkout__btn-place-order {
        margin-left: $msv-mobile-checkout-place-order-margin-left;
        margin-top: $msv-mobile-checkout-place-order-margin-top;
      }

      .ms-checkout__btn-keep-shopping {
        margin-top: $msv-mobile-checkout-keep-shopping-margin-top;
        width: auto;
        display: inline-block;
      }
    }
    .ms-checkout__side {
      .msc-order-summary-wrapper {
        background-color: $color-white;
        padding: 0;
        margin-top: 0;
      }
    }
    .ms-checkout__guided-form {
      margin-top: $msv-checkout-shipping-title-margin-top;
    }
  }

  .msc-cart-line__product-image,
  .msc-cart-line__product-variants {
    display: none;
  }
  .ms-checkout__title,
  .ms-checkout-billing-address__heading,
  .ms-checkout__guided-card-title-step,
  .ms-address-form__item-county,
  .ms-checkout-line-items__heading,
  .ms-checkout-line-items__item-picture {
    display: none;
  }
}
.ms-checkout__line-item {
  .cart-line-item {
    list-style: none;
    .cart-line-item-product {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .cart-line-item-product-details {
      width: 100%;
    }
    .msc-cart-line__quantity {
      label {
        font-size: $font-size-body-m;
      }
      .quantity-value {
        display: inline;
      }
    }
    .cart-line-item-price {
      font-size: $font-size-body-m;
    }
  }
  .msc-order-summary__heading {
    font-family: $font-family-link;
  }
  .ms-checkout__line-item {
    .cart-line-item {
      list-style: none;
      .cart-line-item-product {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .cart-line-item-product-details {
        width: 100%;
      }
      .msc-cart-line__quantity {
        label {
          font-size: $font-size-body-m;
        }
        .quantity-value {
          display: inline;
        }
      }
      .cart-line-item-price {
        font-size: $font-size-body-m;
      }
      .quantity-value {
        display: inline;
      }
    }
    .cart-line-item-price {
      font-size: $font-size-body-m;
    }
  }

  .chckt-checkbox,
  .chckt-radio {
    &:focus {
      @include default-outline;
    }
  }
}
.checkout {
  .checkout-heading {
    .content-block-with-title {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      padding: 1rem 0;
      margin-bottom: 2rem;
      border-bottom: 1px solid $color-silver;
      .content-block-with-title-title {
        color: $color-kabul;
        text-transform: uppercase;
        margin: 0;
        font-size: $font-size-heading-l;
      }
    }
  }

  //prop65 warning
 .msc-p65-warning-icon > picture > img {
  max-width: 50px;
  text-align: right;
  }

  .msc-p65-warning-text  {
    padding: 3px;
    font-size: smaller;
    border: 2px solid black;
  }
  
  .msc-address-select__input-radio {
    position: relative;
    top: -50px;
    margin-right: 0.5rem;
  }
  .msc-address-select__button-add {
    @include secondary-button();
  }
}
