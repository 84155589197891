.msc-promo-code {
  &__applied-code-header {
    @include font-content-l();
  }

  &__line {
    &-container {
      border-bottom: 1px solid $color-black;
      padding-bottom: 2px;
    }

    &-value {
      display: inline-block;
    }
  }
  &__input-box {
    @include vfi();
  }
}
