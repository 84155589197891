#renderPage {
    height: 100vh;
    display: flex;
    flex-direction: column;

    header {
        z-index: 10;
        flex: 0 1 auto;
        width: 100%;
        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }
    .ms-header {
        margin: 0;
    }
    main {
        flex: 1 0 auto;
        max-width: none;
        background-color: transparent;
        background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/imageFileData/MA1NT1);
        background-repeat: no-repeat;
        background-position: center top;

        > * {
            position: relative;
        }
    }

    footer {
        z-index: 5;
        flex: 0 1 auto;
        width: 100%;
        margin-top: auto;
    }
}