$msv-password-reset-verification-button-line-height: 18px;
$msv-password-reset-verification-button-letter-spacing: 0.85px;
$msv-password-reset-verification-button-padding: 0.4rem 0.8rem 0.5rem;
$msv-password-reset-verification-button-margin-top-large: 1rem;
$msv-password-reset-verification-button-margin-top-small: 0.5rem;
$msv-password-reset-verification-width-m: 100%;
.ms-password-reset-verification {
  @include font-content-m();
  line-height: 1.6;
  &__heading {
    font-size: 32px;
    color: $color-kabul;
    border-bottom: 1px solid $color-silver;
    padding-bottom: 1rem;
    text-transform: uppercase;
  }
  &__account-item {
    label {
      margin-bottom: 0;
      color: $color-dovegray;
      font-size: $font-size-body-m;
    }
    input {
      @include form-input-el();
    }
  }
  &__email-verification-button,
  &__continue-button {
    @include primary-button();
    width: 100%;
    line-height: 1.5;
    border-style: solid;
  }
  &__cancel-button,
  #email_ver_but_edit {
    @include secondary-button();
    padding: $msv-password-reset-verification-button-padding;
    width: 100%;
    line-height: 1.5;
  }
  &__verify-email-verify,
  &__verify-email-resend,
  &__continue-button:disabled {
    display: none;
  }
  &__verify-email-edit {
    display: none !important;
  }
  #email_ver_but_send,
  #email_ver_but_verify,
  #email_ver_but_edit {
    margin-top: $msv-password-reset-verification-button-margin-top-large;
  }
  #email_ver_but_resend,
  #continue,
  #cancel {
    margin-top: $msv-password-reset-verification-button-margin-top-small;
  }
  // invalid email error has different size
  &__account-item-email p {
    line-height: 1.5;
  }
  // hide verification code input box and label on initial load
  &__account-items {
    div:nth-of-type(2) {
      label,
      input {
        display: none;
      }
    }
  }
  // hide continue and verify buttons on first load
  #email_ver_but_edit {
    display: none;
  }
  @media screen and (max-width: $msv-breakpoint-m) {
    &__container {
      width: $msv-password-reset-verification-width-m;
    }
  }
}
.ms-sign-up__page-success,
.ms-password-reset-verification__page-success,
.ms-password-reset__page-success {
  @include page-success();
  &:not(:empty)[aria-hidden="false"] {
    display: block !important;
    p {
      margin-bottom: 0;
    }
  }
}
.ms-sign-up__page-error,
.ms-sign-up__item-error,
.ms-password-reset-verification__page-error,
.ms-password-reset-verification__item-error,
.ms-account-profile-edit__item-error,
.ms-password-reset__page-error,
.ms-password-reset__item-error {
  @include page-error();
  &:not(:empty)[aria-hidden="false"] {
    display: block !important;
  }
  p {
    margin-bottom: 0;
  }
}
