.event-list {
    display: flex;
    flex-wrap: wrap;

    &-empty {
        @extend %styles-marketing;
        padding: 1rem;
        font-size: $font-size-body-l;
    }

    &-event {
        @extend %styles-marketing-inverse;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 0 0 100%;
        position: relative;
        padding: 1rem;
        background-color: $color-background-inverse;
        font-size: $font-size-body-m;
        line-height: 1.3;

        &:not(:first-child) {
            margin-top: 1rem;
        }

        @include media-breakpoint-up(md) {
            flex: 1 1 calc(96% / 2);
            max-width: calc(96% / 2);
            margin: 1%;

            &:not(:first-child) {
                margin-top: 1%;
            }
        }

        > * {
            display: block;
            width: calc(100% - 125px);
            margin-left: auto;
        }
    }

    &-date {
        position: absolute;
        top: 0;
        left: 0;
        width: 125px;
        height: 100%;
        margin: 0;
        background-color: $color-link;
        font-family: $font-family-link;
        font-size: $font-size-link-m;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
    }
    &-single,
    &-range {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 100%;
    }
    &-range {
        .event-list-month-M {
            margin-bottom: 0;
        }
    }
    &-end {
        .event-list-month-M,
        .event-list-day {
            @extend h4;
            display: inline-block;
        }
        .event-list-day {
            font-size: $font-size-body-xs !important;
        }
        .event-list-month-M {
            margin-right: 5px;
            &::before {
                content: 'Until ';
            }
        }
    }
    &-month-M {
        @extend h3;
        display: block;
        color: $color-heading-title !important;
    }

    &-day {
        @extend h1;
        font-size: ($font-size-heading-xxl + 4) !important;
        display: block;
        margin-bottom: 2px;
        color: $color-heading-title !important;
    }

    &-category,
    &-location,
    &-time {
        @extend h4;
        margin-bottom: 0 !important;
    }

    &-name {
        @extend h2;
        color: $color-link-inverse !important;
        font-size: $font-size-body-xl !important;
        margin-bottom: 0 !important;
        &:hover,
        &:focus,
        &:active {
            color: $color-link-inverse-hover !important;
        }
    }

    &-sold-out {
        margin-top: 0.5rem !important;
        color: $color-link;
        font-weight: bold;
        text-transform: uppercase;
    }

    &-image,
    &-month-S,
    &-month-L,
    &-year-S,
    &-year-L,
    &-intro,
    &-price,
    &-button,
    &-separator {
        display: none;
    }
}
