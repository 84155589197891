$msv-checkout-delivery-options-description-font-size: 14px;
$msv-checkout-delivery-options-description-line-height: 20px;

$msv-checkout-delivery-options-height: 18px;
$msv-checkout-delivery-options-width: 18px;

$msv-checkout-description-margin-left: 9px;

$msv-checkout-option-margin-bottom: 0;

$msv-checkout-delivery-options-price-margin-left: 12px;
$msv-checkout-delivery-options-description-margin-left: 0px;

.ms-checkout-delivery-options {
  &__price {
    margin-left: 1.25rem;
    font-weight: $font-weight-400;
  }

  &__description {
    margin-left: $msv-checkout-description-margin-left;
  }

  &__option {
    margin-bottom: $msv-checkout-option-margin-bottom;
    display: flex;
    line-height: 1.5;
    font-weight: $font-weight-400;
    padding-left: 1.25rem;

    &-selected {
      display: flex;
      align-items: flex-start;
      .ms-checkout-delivery-options__price {
        margin-left: $msv-checkout-delivery-options-price-margin-left;
      }

      .ms-checkout-delivery-options__description {
        margin-left: $msv-checkout-delivery-options-description-margin-left;
      }
    }
    label {
      line-height: 0;
      margin-bottom: 0.25rem;
    }
  }
  
  &__input-radio {
    @include vfi();
    margin-top: 0.3rem;
    &:focus {
      @include default-outline;
    }
  }

  &__price,
  &__description {
    font-size: $font-size-body-s;
    line-height: 20px;
    align-self: center;
    color: $color-dovegray;
  }
}
