$msv-cart-item-root-background: $color-black;
$msv-cart-item-root-color: $color-white;
$cart-cartline-item-padding-bottom: 0px;
$cart-cartline-product-title-font-size: 18px;
$cart-cartline-product-title-line-height: 21px;
$cart-cartline-product-title-font-weight: bold;

$cart-cartline-remove-button-margin: 20px;
$cart-cartline-product-variants-line-height: 20px;
$cart-cart-line-bopis-container-line-height: 20px;

.msc-cart {
  &-lines-item {
    padding-bottom: $cart-cartline-item-padding-bottom;
    .cart-line-item-error {
      @include quantity-alert();
      position: relative;
    }
  }

  &-line {
    display: flex;

    &__content {
      display: flex;
      width: 100%;
    }

    &__product {
      flex: 1 1 300px;

      &-image {
        margin-right: 20px;
      }

      &-variant {
        &-size,
        &-color,
        &-style,
        &-config {
          .name {
            padding-left: 5px;
            color: $color-dovegray;
          }
        }
      }
    }

    &__promo-codes {
      display: inline-block;
    }

    &__quantity {
      &__select-menu {
        width: 63px;
        height: 30px;
        padding: 0.25rem 0.5rem;
        border: 1px solid $color-swisscoffee;
        border-radius: 0;
        background-color: $color-pampas;
        color: $color-kabul;
        font-family: $font-family-link;
        font-size: 14px;
        @include vfi();

        &:focus {
          @include default-outline;
        }
      }

      &:focus {
        @include default-outline;
      }

      margin-left: 1.5rem;
      display: inline-block;
      float: left;

      .quantity-label {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
        &::after {
          content: ":";
          display: inline-block;
        }
      }

      .quantity-value {
        display: inline-block;
        font-size: 14px;
        margin-left: 4px;
      }
    }

    &__product-price {
      text-align: right;
      justify-content: center;

      .msc-price {
        &__actual {
          font-family: $font-family-link;
          font-weight: 400;
          $font-size: 14px;
        }
      }
    }

    &__product-title {
      @include vfi();
      font-family: $font-family-link;
      font-weight: normal;
      $font-size: 14px;
      $line-height: 24px;
      $text-transform: none;
    }

    &__product-variants {
      @include font-content-s();
      line-height: $cart-cartline-product-variants-line-height;
    }

    &__remove-item,
    &__add-to-wishlist {
      font-family: $font-family-link;
      // @include button-link();
      @include vfi();
      margin-right: $cart-cartline-remove-button-margin;
      text-decoration: none;
      padding: 0;

      &:hover {
        text-decoration: underline;
      }

      &:after {
        content: "";
      }
    }

    &__bopis-container {
      @include font-content-s();
      display: flex;
      flex-direction: column;
      margin: 12px 0;
      line-height: $cart-cart-line-bopis-container-line-height;
    }

    &__bopis-shipping {
      margin-bottom: 10px;

      input {
        @include vfi();
      }
    }

    &__bopis-changestore {
      // @include button-link();
      display: block;
      padding: 5px 0;
      font-size: 12px;
      line-height: 16px;
    }

    &__bopis__fullfilment {
      margin-left: 20px;

      &-store {
        margin-top: 3px;
        font-size: 12px;
        line-height: 16px;
        color: $color-dovegray;
      }
    }
  }
}
.cart-line-item-price {
  display: inline-block;
  float: right;
}
.msc-cart-price {
  font-size: 14px;
}
