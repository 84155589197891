$artist-series-logo-asset: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/imageFileData/MA2ojn');
$artist-series-logo-size: 175px;
$artist-series-hero-content-width: 400px;

.artist-series {
    &__hero-alt {
        @extend .hero-marketing-top;
        background-color: $color-black;

        .ms-content-block__details {
            padding: 14rem 1rem 2rem;

            @include media-breakpoint-up(md) {
                padding: 5rem 0;
            }

            > * {
                max-width: none;
                text-align: center;

                @include media-breakpoint-up(md) {
                    max-width: $artist-series-hero-content-width;
                }
            }

            &::before {
                content: '';
                display: block;
                width: $artist-series-logo-size;
                height: $artist-series-logo-size;
                background-image: $artist-series-logo-asset;
                background-repeat: no-repeat;
                background-size: $artist-series-logo-size $artist-series-logo-size;
                margin: 0 auto 1rem;

                @include media-breakpoint-up(md) {
                    margin-left: calc((#{$artist-series-hero-content-width} - #{$artist-series-logo-size}) / 2);
                }
            }
        }
    }

    &__hero img {
        @extend %image-marketing-max;
        max-height: 600px;
        object-fit: cover;
    }

    &__headline {
        @extend .content-marketing;
        padding-top: 0;
        margin-top: calc(-#{$artist-series-logo-size} / 2);

        .ms-content-block__image {
            max-width: $artist-series-logo-size;
            margin: 0 auto 2rem;
        }
    }

    &__video .youtube-modal-clickable {
        @extend .content-marketing-promo-featured;
        flex-direction: column-reverse;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            justify-content: flex-start;
        }

        > *:first-child {
            @include media-breakpoint-up(lg) {
                flex: 0 1 32%;
                max-width: 32%;
            }
        }

        > *:last-child {
            @include media-breakpoint-up(lg) {
                flex: 0 1 60%;
                max-width: 60%;
            }
        }

        .youtube-modal-textblock {
            @extend %content-marketing-promo-block-text;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: $color-heading-title;
            }

            h2 {
                @extend %text-marketing-heading-subtitle;
                margin-bottom: 1.5rem;
                letter-spacing: 1px;
                line-height: 0.9;
                text-transform: uppercase;
                overflow-wrap: normal;

                @include media-breakpoint-up(lg) {
                  font-size: $font-size-heading-xl;
                }
            }

            h3 {
                font-size: $font-size-link-l;
            }

            h4 {
                @extend %link-marketing;
            }

            p {
                color: $color-background-body;
                font-size: $font-size-body-s;
            }
        }
    }

    &__content {
        @extend %content-marketing-promo;
        @extend %content-marketing-promo-background;

        .ms-content-block__image {
            @extend %image-marketing-max;
        }

        .ms-content-block__details {
            @extend %styles-marketing-inverse;
            padding: 1rem;

            @include media-breakpoint-up(md) {
                padding: 1.5rem 2rem;
            }

            @include media-breakpoint-up(xl) {
                padding: 2.5rem 3rem;
            }

            .ms-content-block__text > *:last-child {
                margin-bottom: 0;
            }
        }

        .ms-content-block__cta {
            margin-top: 1rem;
        }
    }

    &__products .ms-product-collection {
        @extend %content-marketing-promo;
        background-color: $color-background-body--shop;
        padding: 1rem 1.5rem 0;
        margin-bottom: 0;

        &__item:first-child {
            padding-left: 0;
        }

        .msc-ss-carousel {
            padding-top: 0;

            &-slide {
                margin-bottom: 0;
            }

            &-item {
                white-space: normal;
            }
        }
    }

    &__timeline {
        @extend %content-marketing-promo;

        .container-with-header-header {
            @extend %content-marketing-promo-header;
        }
    }
}
