//==============================================================================
// SIZE VARIABLES
//==============================================================================

// Widths
$width-page: 100%;
$min-width-page: 0;
$max-width-page: none;

$width-content: 80%;
$max-width-content: 1000px;

$width-page--shop: 80%;
$min-width-page--shop: calc(347px + 2rem);
$max-width-page--shop: 1025px;

$width-page--aad: 100%;
$min-width-page--aad: 0;
$max-width-page--aad: 600px;

$width-page--landing: 80%;
$min-width-page--landing: 0;
$max-width-page--landing: 1280px;
$max-width-hero--landing: 1440px;

// Margins
$margin-page: 0;
$margin-page-mobile: 0;

$margin-page--shop: 0;
$margin-page-mobile--shop: 0;

$margin-page--aad: 5rem auto 2rem;
$margin-page-mobile--aad: 1rem auto;

// Paddings
$padding-page: 0;
$padding-page-mobile: 0;

$padding-page--shop: 0;
$padding-page-mobile--shop: 0;

$padding-page--aad: 2rem auto 0;
$padding-page-mobile--aad: 1rem auto 0;

// Additional Breakpoints
$breakpoint-xs: 400px;
$breakpoint-xxs: 320px;

//==============================================================================
// FONT VARIABLES
//==============================================================================

// Font Families
$font-family-system: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto",
  "Helvetica", "Arial", sans-serif;
$font-family-body: "Lora", "Georgia", "Times", "Times New Roman", serif;
$font-family-heading: $font-family-body;
$font-family-featured: $font-family-body;
$font-family-link: $font-family-body;

$font-family-header: $font-family-body;
$font-family-footer: $font-family-body;

$font-family-body--shop: $font-family-body;
$font-family-heading--shop: $font-family-heading;
$font-family-featured--shop: $font-family-featured;
$font-family-link--shop: $font-family-link;

$font-family-body--landing: "Lato", "Lucida Grand", "Helvetica", "Arial",
  sans-serif;
$font-family-heading--landing: "Lora", "Times", "Times New Roman", "Georgia",
  serif;
$font-family-link--landing: $font-family-body--landing;
$font-family-button--landing: $font-family-body--landing;

//Font Weight
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight: bold;
$font-weight-normal: normal;

// Font Sizes
$font-size-body-xxl: 26px;
$font-size-body-xl: 20px;
$font-size-body-l: 18px;
$font-size-body-m: 15px;
$font-size-body-s: 14px;
$font-size-body-xs: 13px;
$font-size-body-xxs: 12px;

$font-size-heading-xxl: 44px;
$font-size-heading-xl: 40px;
$font-size-heading-l: 32px;
$font-size-heading-m: 28px;
$font-size-heading-s: 18px;
$font-size-heading-xs: 12.5px;

$font-size-featured-l: 32px;
$font-size-featured-m: 22px;
$font-size-featured-s: 18px;

$font-size-link-xxl: 38px;
$font-size-link-xl: 18px;
$font-size-link-l: 16px;
$font-size-link-m: 12.5px;
$font-size-link-s: 11px;

$font-size-icon-xl: 80px;
$font-size-icon-l: 24px;
$font-size-icon-m: 22px;
$font-size-icon-s: 16px;
$font-size-icon-xs: 12px;

$font-size-header-m: 16px;
$font-size-header-s: 15px;
$font-size-subheader: 15px;
$font-size-topheader: 13px;
$font-size-footer-m: 15px;
$font-size-footer-s: 12.5px;

$font-size-search: 12px;
$font-size-search-icon: 12px;

$font-size-heading-shop-xl: 34px;
$font-size-heading-shop-l: 32px;
$font-size-heading-shop-m: 24px;
$font-size-heading-shop-s: 18px;
$font-size-heading-shop-xs: 16px;

$font-size-heading--landing: 42px;
$font-size-appellation--landing: 18px;
$font-size-body--landing: 14px;
$font-size-link--landing: 13px;
$font-size-button--landing: 10px;
$body-font-sizes: (
  null: 14px,
  1200px: 15px,
);
//==============================================================================
// BASE COLOR VARIABLES
//==============================================================================

// Standard
$color-white: #fff;
$color-black: #000;
$color-gallery: #efefef;
$color-silver: #ccc;
$color-dustygray: #999;
$color-dovegray: #666;
$color-codgray: #0a0a0a;
$color-verdungreen: #4a7801;

// Theme
$color-pampas: #f1ece9;
$color-swisscoffee: #ded4d0;
$color-thatch: #bba7a1;
$color-pharlap: #a48c84;
$color-limedoak: #aa8255;
$color-kabul: #564a43;
$color-woodybrown: #42322c;
$color-oil: #261c16;
$color-lightchoco: #997e75;

$color-font-error: #721c24;
$color-bg-error: #f8d7da;
$color-border-error: #f5c6cb;
$color-darklimedoak: #816240;
$color-darkbrown: #261C16;
$color-darkgray: #8C858B;

//==============================================================================
// COMPONENT COLOR VARIABLES
//==============================================================================

// Background
$color-background-body: $color-oil;
$color-background-accent: $color-kabul;
$color-background-inverse: $color-pampas;

$color-background-body--shop: $color-white;
$color-background-accent--shop: $color-pampas;
$color-background-inverse--shop: $color-oil;

// Border
$color-border: $color-kabul;
$color-border-accent: $color-woodybrown;
$color-border-inverse: $color-swisscoffee;
$color-border-accent--shop: $color-limedoak;
$color-border--shop: $color-silver;
$color-border-inverse--shop: $color-kabul;

// Text
$color-text-body: $color-thatch;
$color-text-accent: $color-pampas;
$color-text-inverse: $color-kabul;
$color-text-muted: $color-dovegray;

$color-text-body--shop: $color-dovegray;
$color-text-accent--shop: $color-pharlap;
$color-text-inverse--shop: $color-thatch;
$color-text-muted--shop: $color-dovegray;

// Heading
$color-heading-title: $color-pampas;
$color-heading-subtitle: $color-pampas;
$color-heading-appellation: $color-pampas;
$color-heading-title-inverse: $color-kabul;
$color-heading-title-inverse-accent: $color-oil;
$color-heading-subtitle-inverse: $color-kabul;
$color-heading-subtitle-inverse-accent: $color-pharlap;
$color-heading-appellation-inverse: $color-kabul;
$color-heading-appellation-inverse-accent: $color-pharlap;

$color-heading-title--shop: $color-kabul;
$color-heading-subtitle--shop: $color-kabul;
$color-heading-appellation--shop: $color-kabul;
$color-heading-title-inverse--shop: $color-pampas;
$color-heading-title-inverse-accent--shop: $color-thatch;
$color-heading-subtitle-inverse--shop: $color-pampas;
$color-heading-subtitle-inverse-accent--shop: $color-thatch;
$color-heading-appellation-inverse--shop: $color-pampas;
$color-heading-appellation-inverse-accent--shop: $color-thatch;
$msv-heading-text-size: 20px;
$msv-heading-line-height: 28;

// Breadcrumb
$color-breadcrumb--shop: $color-limedoak;
$color-breadcrumb-hover--shop: $color-kabul;
$color-breadcrumb-active--shop: $color-kabul;

// Link
$color-link: $color-darklimedoak;
$color-link-hover: $color-pampas;
$color-link-accent: $color-thatch;
$color-link-accent-hover: $color-pampas;
$color-link-inverse: $color-limedoak;
$color-link-inverse-hover: $color-kabul;
$color-link-muted: $color-dustygray;
$color-link-muted-hover: $color-dovegray;

$color-link--shop: $color-limedoak;
$color-link-hover--shop: $color-kabul;
$color-link-accent--shop: $color-pharlap;
$color-link-accent-hover--shop: $color-kabul;
$color-link-inverse--shop: $color-limedoak;
$color-link-inverse-hover--shop: $color-pampas;
$color-link-muted--shop: $color-dustygray;
$color-link-muted-hover--shop: $color-dovegray;

// Button
$color-button-text: $color-pampas;
$color-button-background: $color-woodybrown;
$color-button-border: $color-woodybrown;
$color-button-text-hover: $color-pampas;
$color-button-background-hover: $color-kabul;
$color-button-border-hover: $color-kabul;

$color-button-inverse-text: $color-pampas;
$color-button-inverse-background: $color-limedoak;
$color-button-inverse-border: $color-limedoak;
$color-button-inverse-text-hover: $color-pampas;
$color-button-inverse-background-hover: $color-woodybrown;
$color-button-inverse-border-hover: $color-woodybrown;

$color-button-primary-text: $color-pampas;
$color-button-primary-background: $color-limedoak;
$color-button-primary-border: $color-limedoak;
$color-button-primary-text-hover: $color-pampas;
$color-button-primary-background-hover: $color-woodybrown;
$color-button-primary-border-hover: $color-woodybrown;

$color-button-secondary-text: $color-darklimedoak;
$color-button-secondary-background: transparent;
$color-button-secondary-border: $color-darklimedoak !important;
$color-button-secondary-text-hover: $color-kabul;
$color-button-secondary-background-hover: transparent;
$color-button-secondary-border-hover: $color-kabul;

$color-button-disabled-text: $color-silver;
$color-button-disabled-background: $color-gallery;
$color-button-disabled-border: $color-silver;

$focus: #007bff auto 1px;

// Input
$color-input-text: $color-kabul;
$color-input-background: $color-pampas;
$color-input-border: $color-swisscoffee;

$color-input-marketing-text: $color-swisscoffee;
$color-input-marketing-background: $color-kabul;
$color-input-marketing-border: $color-kabul;

//logo
$msv-logo-top-margin: 1.45rem;

// Header
$color-background-header: $color-oil;
$color-background-subheader: $color-oil;
$color-link-header-toggler: $color-pampas;
$color-link-header: $color-thatch;
$color-link-header-hover: $color-pampas;
$color-link-header-active: $color-pampas;
$color-link-subheader: $color-thatch;
$color-link-subheader-hover: $color-pampas;
$color-link-subheader-active: $color-pampas;
$color-link-topheader: $color-thatch;
$color-link-topheader-hover: $color-pampas;

// Footer
$color-background-footer: $color-pampas;
$color-text-footer: $color-kabul;
$color-text-footer-heading: $color-kabul;
$color-link-footer: $color-darklimedoak;
$color-link-footer-hover: $color-darklimedoak;
$color-link-footer-links: $color-darklimedoak;
$color-link-footer-links-hover: $color-kabul;
$color-link-footer-legal: $color-darklimedoak;
$color-link-footer-legal-hover: $color-kabul;
$color-link-footer-social: $color-limedoak;
$color-link-footer-social-hover: $color-kabul;

// Search
$color-background-search: $color-pampas;
$color-background-search-results: $color-pampas;
$color-border-search: $color-swisscoffee;
$color-text-search: $color-kabul;
$color-text-search-results: $color-kabul;
$color-link-search-results: $color-limedoak;
$color-link-search-results-hover: $color-kabul;
$color-link-search-close: $color-dustygray;
$color-link-search-close-hover: $color-dovegray;

// AAD
$color-background-container--aad: $color-white;

// Landing
$color-background-body--landing: $color-oil;
$color-background-container--landing: $color-pampas;
$color-heading--landing: $color-pampas;
$color-appellation--landing: $color-pharlap;
$color-text--landing: $color-kabul;
$color-text-footer--landing: $color-thatch;
$color-link--landing: $color-limedoak;
$color-link-hover--landing: $color-pampas;
$color-button-text--landing: $color-pampas;
$color-button-background--landing: $color-limedoak;
$color-button-border--landing: $color-limedoak;
$color-button-text-hover--landing: $color-pampas;
$color-button-background-hover--landing: $color-woodybrown;
$color-button-border-hover--landing: $color-woodybrown;

// Shipping Map
$color-map-fill--shipping-map: $color-silver;
$color-map-fill-active--shipping-map: $color-verdungreen;
$color-map-stroke--shipping-map: $color-white;

//==============================================================================
// ASSETS
//==============================================================================
$asset-background-shop: url("https://images-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/imageFileData/MA1Llt?q=80");

//Layout
$msv-layout-h-padding-s: 16px;
$msv-layout-h-padding-m: 40px;
$msv-layout-h-padding-l: 80px;
$msv-layout-h-padding-xl: 120px;
$msv-layout-v-padding-s: 16px;
$msv-layout-v-padding-m: 40px;
$msv-layout-v-padding-l: 64px;
$msv-layout-v-padding-xl: 80px;
$msv-layout-page-clamp-width: $msv-breakpoint-xl;
$msv-layout-container-width: $msv-breakpoint-xl;

//Direction
$left: left;
$right: right;
$top: top;
$bottom: bottom;

//Error Alert
$error-alert-padding: 0.75rem 1.25rem;
$error-alert-margin-bottom: 1rem;
$error-alert-border-radius: 0.25rem;
$error-alert-color: #721c24;
$error-alert-background-color: #f8d7da;
$error-alert-border-color: #f5c6cb;

//Warning Alert
$warning-alert-color: #856404;
$warning-alert-background-color: #fff3cd;
$warning-alert-border-color: #ffeeba;

//Success Alert
$success-alert-color: #004085;
$success-alert-background-color: #cce5ff;
$success-alert-border-color: #b8daff;