$msv-checkout-guest-profile-input-margin-top: 4px;
$msv-checkout-guest-profile-input-height: 30px;
$msv-checkout-guest-profile-input-padding: 0.25rem .5rem;

$msv-checkout-guest-profile-input-label-width: 50%;

$msv-checkout-guest-profile-input-text-width: 100%;

$msv-checkout-guest-profile-error-max-width: 512px;
$msv-checkout-guest-profile-error-padding: 8px;
$msv-checkout-guest-profile-error-margin-bottom: 10px;
$msv-checkout-guest-profile-error-margin-top: 2px;
$msv-checkout-guest-profile-error-icon-margin-right: 8px;

.ms-checkout-guest-profile {
  display: flex;
  &__add-contact-info
  {
    width: 100%;
  }

  &__input-text {
    @include vfi();
    @include form-control-14hands;
    margin-top: $msv-checkout-guest-profile-input-margin-top;
  }

  &__input-label {
    font-size: $font-size-body-s;
    display: flex;
    align-items: flex-start;
    color: $color-dovegray;
    margin-bottom: 0.5rem; 
    line-height: 20px;
    width: $msv-checkout-guest-profile-input-label-width;
  }

  &__input-error {
    @extend %input-error;
  }
  &__selected-email {
    font-size: $font-size-body-s;
    line-height: 20px;
    align-self: center;
    color: $color-dovegray;
  }
}
.checkout-date-of-birth {
  display: flex;
  flex-direction: column;

  input {
    @include vfi();
    @include form-control-14hands;
  }
  label {
    font-size: 14px;
    color: $color-dovegray;
    width: 100%;
  }
}
.checkout-terms-and-conditions {
  margin-left: 1.5rem;
  font-size: 14px;
  margin-top: 1.5rem;
  label {
    display: block;
    margin-bottom: 0;
  }
}
 //prop65 warning
 .msc-p65-warning-icon > picture > img {
  max-width: 50px;
  text-align: right;
  }

  .msc-p65-warning-text  {
    padding: 3px;
    font-size: smaller;
    border: 2px solid black;
  }

.checkout-date-of-birth__input-error {
  @extend %input-error;
  display: block;
}
