$carousel-width: 100%;
$carousel-strip-width: 100%;
$flipper-color: $color-black;
$flipper-top-position: -22px;
$flipper-right-position: 56px;
$flipper-next-right-position: 0.5px;
$flipper-width: 48px;
$flipper-disabled-opacity: 0.4;
$flipper-outline-offset: 2px;
$flipper-border-radius: 50%;
$flipper-border-color: transparent;
$msc-carousel-background-image: url(https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MA1NSf);

$vert-carousel-height: 100%;
$vert-carousel-strip-width: 100%;
$vert-flipper-height: 40px;

.msc-ss-carousel {
  position: relative;
  touch-action: pan-y pinch-zoom;
  width: $carousel-width;
  height: auto;

  .msc-flipper {
    position: absolute;
    .msi-chevron-left {
      @include add-icon($msv-ChevronLeft);
    }

    .msi-chevron-right {
      @include add-icon($msv-ChevronRight);
    }
  }

  &__flipper {
    color: $flipper-color;
    background: inherit;
    border: $flipper-border-color;
    border-radius: $flipper-border-radius;
    left: auto;
    outline-offset: $flipper-outline-offset;
    right: $flipper-right-position;
    top: $flipper-top-position;
    width: $flipper-width;
  }

  &__flipper:disabled {
    opacity: $flipper-disabled-opacity;
  }

  &__flipper {
    z-index: 1;
    transform: translate(0, -50%);
  }

  &__flipper--next {
    right: $flipper-next-right-position;
  }

  &-strip {
    width: $carousel-strip-width;
    height: auto;
    overflow: hidden;
  }

  &-slide {
    display: inline-block;
    left: 0;
    padding-inline-start: 0;
    position: relative;
    transition: left cubic-bezier(0.16, 1, 0.29, 0.99) 0.667s;
    white-space: nowrap;
  }
}

.msc-ss-carousel-vert {
  position: relative;
  width: auto;
  height: $vert-carousel-height;
  display: inline-block;
  touch-action: pan-x pinch-zoom;

  &-slide {
    white-space: nowrap;
    height: auto;
    position: relative;
  }

  &-item {
    display: block;
    white-space: normal;
  }

  &-strip {
    height: $vert-carousel-strip-width;
    width: auto;
    overflow: hidden;
  }

  &__flipper {
    color: $flipper-color;
    background: inherit;
    border: $flipper-border-color;
    border-radius: $flipper-border-radius;
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translate(-50%, 0);
    top: -($vert-flipper-height / 2);
    @include add-icon($msv-ChevronUp);

    &:disabled {
      opacity: $flipper-disabled-opacity;
    }
  }

  &__flipper--next {
    top: auto;
    bottom: -($vert-flipper-height / 2);
    @include add-icon($msv-ChevronDown);
  }
}
.visit-us {
  .msc-carousel {
    margin: 0 -3rem;
    &::before {
      background-image: $msc-carousel-background-image;
    }
    .ms-content-block__image {
      img {
        object-fit: cover;
        width: 100%;
        height: 600px;
      }
    }
  }
}
