$content-block-min-height: 150px;
$hero-body-color: $color-black;
$hero-body-font-size: 16px;
$hero-body-line-height: 21px;
$hero-body-font-weight: normal;
$hero-body-max-length: 30em;
$hero-body-top-margin: 8px;
$hero-content-text-margin-top: 16px;
$hero-content-button-margin-top: 32px;
$hero-heading-color: $black;
$hero-heading-font-size: 32px;
$hero-heading-line-height: 43px;
$hero-heading-font-weight: normal;
$hero-heading-max-length: 30em;
$hero-link-to-link-spacing: 16px;
$hero-links-top-margin: 32px;
$hero-module-reflow-breakpoint: $msv-breakpoint-l;

@mixin overlay-text-on-image {
  .ms-content-block__details {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    left: 50%;
    top: 54%;
    transform: translate(-50%, -50%);
    margin-top: 0;
    padding: 5rem 3rem;
    max-width: 800px;
  }
}

.ms-content-block {
  .ms-content-block__image:empty,
  .ms-content-block__image img[height="1px"],
  .ms-content-block__title:empty,
  .ms-content-block__text:empty,
  .ms-content-block__cta:empty {
    display: none !important;
  }
  &.hero-marketing-overfeature {
    @media screen and (max-width: $msv-breakpoint-m) {
      min-height: 0;
    }
  }

  &[data-m-layout="full-width"] {
    position: relative;

    .ms-content-block__image,
    .ms-content-block__image img {
      width: 100%;
      height: 100%;
    }

    &.textplacement__center .ms-content-block__details {
      align-items: center;
      text-align: center;
    }

    .ms-content-block__title {
      color: $color-white;
      font-weight: $hero-heading-font-weight;
      max-width: $hero-heading-max-length;
      font-size: 23px;
      text-transform: uppercase;
      font-family: "Nexa Rust Sans Black";
      letter-spacing: 5px;
      margin-bottom: 5rem;
      text-align: center;

      @include media-breakpoint-up(sm) {
        font-size: $hero-heading-font-size;
        letter-spacing: 10px;
        margin-bottom: 2rem;
      }
    }

    .ms-content-block__text {
      color: $color-white;
      font-weight: $hero-body-font-weight;
      font-size: $hero-body-font-size;
      line-height: $hero-body-line-height;
      text-align: center;
      margin: 0 auto;

      p {
        font-size: 20px;
        line-height: 1.3;

        @include media-breakpoint-up(md) {
          font-size: 23px;
          line-height: 1.3;
          margin-bottom: 1rem;
        }

        &:nth-child(2) {
          margin-bottom: 0;
        }
      }
    }

    &.texttheme__light .ms-content-block__title,
    &.texttheme__light .ms-content-block__text {
      color: $color-white;
    }

    &.texttheme__dark .ms-content-block__title,
    &.texttheme__dark .ms-content-block__text {
      color: $color-black;
    }

    /* body should have top margin only when there's something above it */
    * + .ms-content-block__text {
      margin-top: $hero-content-text-margin-top;
    }

    .ms-content-block__cta {
      // background: $msv-primary;
      // border-radius: 2px;
      // min-width: 164px;
      // display: flex;
      // align-items: flex-end;
      // justify-content: center;
      // padding-right: $hero-link-to-link-spacing;
      // padding-left: $hero-link-to-link-spacing;
      @include primary-button();
      background-color: transparent;
      border: 1px solid $color-white;
      text-align: center;
      display: inline-block;
    }

    /* links should have top margin only when there's something above it */
    * + .ms-content-block__cta {
      margin-top: $hero-content-button-margin-top;
    }

    .ms-content-block__details {
      .msc-cta__primary {
        margin: $hero-link-to-link-spacing / 2;
        color: $color-white;
        font-size: 14px;
        line-height: 20px;
      }

      .msc-cta__secondary {
        margin: $hero-link-to-link-spacing / 2;
      }
    }

    /**
   * Text over image by default only in modern browsers.
   * We must reflow the text to bottom in IE 11 because it doesn't support srcset and the image will be too short to put text over it.
   */
    @supports (object-fit: cover) {
      .ms-content-block__image {
        object-fit: cover;
      }
    }

    /**
   * When the image is tall enough for IE 11 to display text on top of it. 
   */
  }
}
