// Affects the entire list of ratings
.acclaim-list {
  padding: 1rem 0;
  color: $color-text-body--shop;
}

// Style for every acclaim entry
.acclaim-entry {
  display: inline-block;
  min-width: 140px;
  min-height: 72px;
  margin-right: 12px;
}

// Style for the rating value
.acclaim-rating {
  min-height: 23px;
  margin-bottom: 0.25rem;
  color: $color-link--shop;
  font-family: $font-family-body;
  font-size: $font-size-body-xxl - 2px;
  line-height: 0.9;
  text-transform: lowercase;
}

// Style for the reviewer / rating source
.acclaim-source {
  display: block;
  font-size: $font-size-body-xxs;
  line-height: 1;
  text-transform: uppercase;
}

// Style for the review date
.acclaim-date {
  font-size: $font-size-body-s;
  font-style: italic;
  line-height: 1;
}

