.hero {
  &.ms-content-block {
    &[data-m-layout="full-width"] {
      .ms-content-block__details {
        .msc-cta__primary {
          margin: 0;
        }
      }
    }
  }

  .ms-content-block__cta {
    .msc-cta__primary {
      text-align: center;
      padding: 8px 24px;
    }
  }
}
