.show-password-button {
    padding: 1px 6px!important;
    line-height: unset!important;
    float: right;
    margin: -29px 5px;
    position: relative;
    z-index: 1;
    cursor: pointer;
    background: none;
    border: none;
}

.ms-sign-up__account-item-input {
    &.ms-sign-up__account-item-newPassword,
    &.ms-sign-up__account-item-reenterPassword {
        padding-right: 35px;
    }
}

.ms-sign-up__pw-requirements {
    margin-top: 20px;

    p {
        margin-bottom: 0;
    }
}

.icon-show-password {
    @extend .fas;
    @extend .fa-eye;
}

.icon-hide-password {
    @extend .fas;
    @extend .fa-eye-slash;
}

