//==============================================================================
// MARKETING STYLES
//==============================================================================

%styles-marketing {
  h1 {
    @extend %text-marketing-h1;
  }

  h2 {
    @extend %text-marketing-h2;
  }

  h3 {
    @extend %text-marketing-h3;
  }

  h4 {
    @extend %text-marketing-h4;
  }

  h5 {
    @extend %text-marketing-h5;
    margin-bottom: 1rem;
  }

  h6 {
    @extend %text-marketing-h6;
    margin-bottom: 1rem;
    font-size: $font-size-featured-s;
    line-height: 1.6;
  }

  .btn-marketing {
    @extend %btn-marketing;
  }

  .hero-cta button,
  .hero-cta a,
  .call-to-action {
    @extend %link-marketing;
    width: 100%;

    @media (min-width: $breakpoint-xxs) {
      width: auto;
    }

    &:not(:first-child) {
      margin-top: 0.5rem;

      @media (min-width: $breakpoint-xxs) {
        margin-top: 0;
        margin-left: 1rem;
      }
    }
  }

  .link-marketing {
    @extend %link-marketing;
    color: $color-link;
    font-family: $font-family-link;
    font-size: $font-size-link-m;
  }

  .btn-marketing,
  .link-marketing {
    margin-top: 0.5rem;
  }

  img {
    @extend %image-marketing;
  }
}

%styles-marketing-inverse {
  @extend %styles-marketing;
  color: $color-text-inverse;

  h1 {
    @extend %text-marketing-h1-inverse;
  }

  h2 {
    @extend %text-marketing-h2-inverse;
  }

  h3 {
    @extend %text-marketing-h3-inverse;
  }

  h4 {
    @extend %text-marketing-h4-inverse;
  }

  h5 {
    @extend %text-marketing-h5-inverse;
  }

  h6 {
    @extend %text-marketing-h6-inverse;
  }

  a {
    //color: $color-link-inverse;
    color: $color-darklimedoak;
    border-color: $color-darklimedoak;

    &:hover,
    &:focus,
    &:active {
      color: $color-link-inverse-hover;
    }
  }

  .hero-cta button,
  .hero-cta a,
  .msc-cta__primary,
  .call-to-action,
  .link-marketing {
    @extend %link-marketing-inverse;
  }

  .btn-marketing {
    @extend %btn-marketing-inverse;
  }
}

.content-marketing,
.hero-marketing-top {
  @extend %styles-marketing;
}

%content-marketing-promo,
.content-marketing-calendar {
  @extend %styles-marketing-inverse;
}

//==============================================================================
// CONTENT MODULES
//==============================================================================

.content-marketing {
  @extend %width-marketing-margin;
  padding: 1rem 0;
  text-align: center;

  @include media-breakpoint-up(sm) {
    padding: 2rem;
  }

  @include media-breakpoint-up(md) {
    padding: 2rem 3rem;
  }

  @include media-breakpoint-up(lg) {
    padding: 3rem 4rem;
  }

  @include media-breakpoint-up(xl) {
    padding: 4rem 10rem;
  }

  a {
    color: $color-link-hover;
  }
}

.content-marketing-calendar {
  @extend %width-marketing-content;
  @extend %height-marketing;

  > .container-with-header-header {
    @extend %styles-marketing;
    margin-bottom: 2rem;
    text-transform: uppercase;
    text-align: center;
  }
}

.content-marketing-list {
  margin-top: 1rem;

  .content-block-with-title-title:empty {
    display: none;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0;
    border-width: 1px 0;
    border-style: solid;
    border-color: $color-border-inverse;

    &:not(:first-child) {
      border-top-width: 0;
    }

    .link-marketing {
      margin-top: 0;
    }
  }
}

%content-marketing-promo {
  @extend %width-marketing-content;
  @extend %height-marketing;

  &-background {
    background-color: $color-background-inverse;
  }

  &-header {
    @extend %styles-marketing;

    > .container-with-header-title {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 2rem;
      text-align: center;

      &::before,
      &::after {
        content: "";
        flex: 1 1 auto;
        height: 1px;
        background-color: $color-border;
      }

      &::before {
        margin-right: 0.5rem;
      }

      &::after {
        margin-left: 0.5rem;
      }

      &:empty {
        display: none;
      }
    }
  }

  &-block-text {
    padding: 1rem;

    @include media-breakpoint-up(sm) {
      padding: 2rem;
    }

    @include media-breakpoint-up(xl) {
      padding: 2rem 3rem;
    }
  }
}

.content-marketing-promo-team,
.content-marketing-promo-double,
.content-marketing-promo-triple,
.content-marketing-promo-article,
.content-marketing-promo-featured,
.content-marketing-promo-club,
.content-marketing-promo-single,
.content-marketing-mailing-list,
.content-marketing-honors {
  @extend %content-marketing-promo;
}

.content-marketing-promo-team,
.content-marketing-promo-double,
.content-marketing-promo-triple,
.content-marketing-promo-article,
.content-marketing-promo-club {
  > .container-with-header-header {
    @extend %content-marketing-promo-header;
  }
}

.content-marketing-promo-team,
.content-marketing-promo-double,
.content-marketing-promo-triple,
.content-marketing-promo-article {
  > .container-with-header-slots {
    .ms-content-block {
      @extend %content-marketing-promo-background;
      & .ms-content-block__text p {
        text-align: left;
      }
      > .hero-picture img,
      > .ms-content-block__image img {
        @extend %image-marketing-max;
      }

      > .hero-text-alignment-container,
      > .ms-content-block__details {
        @extend %content-marketing-promo-block-text;

        .hero-heading,
        .ms-content-block__title,
        h1,
        h2,
        h3,
        h4,
        .ms-content-block__text {
          text-align: center;
        }
      }
    }

    > a {
      cursor: pointer;

      .hero-heading,
      .ms-content-block__title,
      h3 {
        color: $color-link-inverse;
        transition: all 0.3s ease;
      }

      h2 {
        color: $color-darkbrown !important;
      }

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        .hero-heading,
        .ms-content-block__title,
        h2,
        h3 {
          color: $color-link-inverse-hover;
        }
      }
    }
    > .clickable-container {
      @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
.content-marketing-promo-double {
  .container-with-header-slots {
    .ms-content-block {
      height: 100%;
      .ms-content-block__text p {
        text-align: center;
      }
    }
  }
}

.content-marketing-promo-team > .container-with-header-slots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .ms-content-block {
    min-height: 100%;
    .ms-content-block__details {
      padding: 1rem;
    }
  }
  > * {
    flex: 0 1 240px;
    max-width: 240px;
    margin: 0.5rem;
  }

  > .hero,
  .ms-content-block,
  > a > div > .hero,
  > a > .ms-content-block {
    display: flex;
    flex-direction: column;

    > .hero-text-alignment-container,
    .ms-content-block__details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      @include media-breakpoint-up(sm) {
        padding: 2rem;
      }

      > *:last-child > *:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.content-marketing-promo-team-alternate {
  @extend .content-marketing-promo-team;

  > .container-with-header-slots {
    > * {
      margin: 0.3rem;
    }

    > .hero > .hero-text-alignment-container h3,
    > .ms-content-block > .ms-content-block__details h3,
    > a > div > .hero > .hero-text-alignment-container h3,
    > a > .ms-content-block > .ms-content-block__details h3 {
      @extend %text-marketing-inverse;
    }

    > a > div > .hero > .hero-text-alignment-container h3,
    > a > .ms-content-block > .ms-content-block__details h3 {
      color: $color-link-inverse;
    }

    &:hover,
    &:focus,
    &:active {
      > a > div > .hero > .hero-text-alignment-container h3,
      > a > .ms-content-block > .ms-content-block__details h3 {
        color: $color-link-inverse-hover;
      }
    }
  }
}

.content-marketing-promo-double > .container-with-header-slots,
.content-marketing-promo-article > .container-with-header-slots {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > * {
    flex: 0 0 100%;

    @include media-breakpoint-up(lg) {
      flex: 0 1 49.5%;
      max-width: 49.5%;
    }

    &:not(:first-child) {
      margin-top: 1rem;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }
  }
}

.content-marketing-promo-triple > .container-with-header-slots {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .ms-content-block {
    min-height: 100%;
  }

  > * {
    flex: 0 0 100%;

    @include media-breakpoint-up(lg) {
      flex: 0 1 32.5%;
      max-width: 32.5%;
    }

    &:not(:first-child) {
      margin-top: 1rem;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }
  }
}

.content-marketing-promo-article > .container-with-header-slots {
  > * {
    @include media-breakpoint-up(lg) {
      &:not(:first-child):not(:nth-child(2)) {
        margin-top: 1rem;
      }
    }

    > .hero-text-alignment-container,
    .ms-content-block__details {
      h1,
      h2 {
        color: $color-heading-title-inverse-accent;
      }

      h1 {
        @include media-breakpoint-up(lg) {
          font-size: $font-size-heading-xl * 2;
        }
      }

      p:first-of-type {
        padding-top: 1.5rem;
        margin-top: 1.5rem;
        border-top: 1px solid $color-border-inverse;
      }
      .ms-content-block__text {
        margin-top: 0.5rem;
      }
    }
  }
}

.content-marketing-promo-featured {
  display: flex;
  flex-wrap: wrap;
  background-color: $color-link;

  @include media-breakpoint-up(lg) {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  > * {
    flex: 0 0 100%;

    &:first-child {
      @include media-breakpoint-up(lg) {
        flex: 0 1 60%;
        max-width: 60%;
      }
    }

    &:last-child {
      @include media-breakpoint-up(lg) {
        flex: 0 1 32%;
        max-width: 32%;
      }
    }
  }

  > .hero-picture img,
  > .ms-content-block__image img {
    @extend %image-marketing-max;
    height: 100%;
  }

  > .hero-text-alignment-container,
  > .ms-content-block__details {
    @extend %content-marketing-promo-block-text;
    @extend %styles-marketing;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.msc-cta__primary {
      text-align: center;
      @extend %link-marketing;

      &:hover,
      &:active {
        color: $color-kabul;
      }
    }
    .hero-heading,
    h2 {
      @extend %text-marketing-heading-subtitle;
    }
    .ms-content-block__title {
      font-size: $font-size-link-l;
    }

    h2 {
      margin-bottom: 1.5rem;
      letter-spacing: 1px;
      line-height: 0.9;
      overflow-wrap: normal;
      @include media-breakpoint-up(lg) {
        font-size: $font-size-heading-xl;
      }
    }

    p {
      color: $color-background-body;
      font-size: $font-size-body-s;
    }

    a,
    button {
      color: $color-link-hover;
    }
  }

  &-alternate {
    @extend .content-marketing-promo-featured;

    > * {
      &:first-child {
        @include media-breakpoint-up(lg) {
          flex: 0 1 68%;
          max-width: 68%;
        }
      }

      &:last-child {
        @include media-breakpoint-up(lg) {
          flex: 0 1 32%;
          max-width: 32%;
        }
      }
    }

    > .hero-text-alignment-container,
    > .ms-content-block__details {
      .hero-heading,
      .ms-content-block__title,
      h2 {
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
          font-size: $font-size-heading-m;
        }
      }

      h1 {
        font-size: $font-size-heading-xl;

        @include media-breakpoint-up(lg) {
          font-size: $font-size-heading-xl * 2;
        }
      }
    }
  }
}
.promo-featured-custom {
  .ms-content-block__text {
    margin-top: 0.5rem;
    @include media-breakpoint-up(xl) {
      margin-top: 0.75rem;
    }
  }
  .msc-cta__primary {
    text-align: center;
    position: relative;
    top: 2px;
    @include media-breakpoint-up(md) {
      top: 1px;
    }
    @include media-breakpoint-up(lg) {
      top: -2px;
    }
    @include media-breakpoint-up(xl) {
      top: -3px;
    }
  }
}

.content-marketing-promo-club > .container-with-header-slots .ms-content-block {
  @extend %content-marketing-promo-background;
  display: flex;
  flex-wrap: wrap;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  > * {
    flex: 0 0 100%;

    @include media-breakpoint-up(lg) {
      flex: 0 1 50%;
      max-width: 50%;
    }
  }

  > .hero-picture img,
  > .ms-content-block__image img {
    @extend %image-marketing-max;
    height: 100%;
  }

  > .hero-text-alignment-container,
  > .ms-content-block__details {
    @extend %content-marketing-promo-block-text;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .hero-heading,
    .ms-content-block__title,
    h2 {
      color: $color-link;
    }
  }
}
.content-marketing-mailing-list,
.content-marketing-promo-single {
  @extend %content-marketing-promo-background;

  > .container-with-header-slots {
    display: flex;
    flex-wrap: wrap;

    > .hero,
    > .ms-content-block {
      @extend %styles-marketing;
      position: relative;
      flex: 0 0 100%;

      img {
        @extend %image-marketing-max;
        min-height: 200px;
      }

      .hero-text-alignment-container,
      .ms-content-block__details {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: -0.5px;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 1rem;

        @include media-breakpoint-up(md) {
          padding: 1rem 2rem;
          top: 0;
        }

        @include media-breakpoint-up(xl) {
          padding: 1rem 4rem;
          top: 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-bottom: 0;
        }

        .hero-heading,
        .ms-content-block__title,
        h2 {
          margin-bottom: 0.3rem;
        }
        .ms-content-block__text {
          @include media-breakpoint-down(lg) {
            margin-top: 0.1px;
          }
        }
      }
    }
    .image-gradient-radial-dark .ms-content-block__image::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        transparentize($color-black, 0.2) 0%,
        transparentize($color-black, 0.5) 10%,
        transparentize($color-black, 0.65) 20%,
        transparentize($color-black, 0.725) 30%,
        transparentize($color-black, 0.8) 40%,
        transparent 50%
      );
    }
    .float-left,
    .mb-4 {
      min-height: auto;
      .ms-content-block__details {
        position: relative !important;
        padding: 0.2rem 0 0;
        top: 0;
      }
      picture {
        &::before {
          display: none !important;
        }
      }
      img {
        min-height: auto;
        width: auto;
      }
    }

    > .carousel .hero-text-alignment-container .hero-heading:empty {
      display: none;
    }

    > .container-with-header {
      flex: 0 0 100%;
      padding: 1rem;

      @include media-breakpoint-up(md) {
        padding: 2rem;
      }

      @include media-breakpoint-up(xl) {
        padding: 4rem;
      }

      &:nth-child(2) {
        @include media-breakpoint-up(md) {
          flex: 0 1 40%;
          max-width: 40%;
          padding-right: 1rem;
        }

        @include media-breakpoint-up(xl) {
          padding-right: 2rem;
        }
      }

      &:nth-child(3) {
        padding-top: 0;

        @include media-breakpoint-up(md) {
          flex: 0 1 60%;
          max-width: 60%;
          padding-left: 1rem;
          padding-top: 2rem;
        }

        @include media-breakpoint-up(xl) {
          padding-left: 2rem;
          padding-top: 4rem;
        }
      }

      &.content-marketing-promo-single-video {
        padding: 0;
        cursor: pointer;

        .container-with-header-header > *:empty,
        .hero-text-alignment-container > *:empty {
          display: none;
        }

        .container-with-header-slots {
          position: relative;
        }

        .youtube-modal-clickable {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          padding: 1rem;
          text-align: center;

          .youtube-modal-textblock {
            @extend %text-marketing-h4;
            font-weight: bold;

            &::before {
              @extend %fa-icon;
              @extend .fas;
              content: fa-content($fa-var-play-circle);
              font-size: $font-size-icon-l;
              margin-bottom: 1rem;
            }
          }
        }
        .ms-content-block__details {
          display: none;
        }
      }
    }

    > .content-block-with-title {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 1rem;

      @include media-breakpoint-up(md) {
        padding: 2rem;
      }

      @include media-breakpoint-up(xl) {
        padding: 4rem;
      }

      .content-block-with-title-title:empty {
        display: none;
      }

      &:nth-child(2) {
        @include media-breakpoint-up(xl) {
          padding-bottom: 2rem;
        }
      }

      &:not(:nth-child(2)) {
        padding-top: 0;
      }
    }
  }

  p + h3,
  p + h4,
  h6 + h3,
  h6 + h4 {
    margin-top: 1.5rem;
  }

  .link-marketing {
    display: block;
  }

  .ms-accordion .drawer__button {
    padding: 0.5rem 0;
    text-align: left;

    .drawer__glyph {
      &::before {
        @extend %fa-icon;
        @extend .fas;
        font-size: $font-size-icon-s;
        margin-left: 0.5rem;
        color: $color-link;
      }

      &.collapse-glyph::before {
        content: fa-content($fa-var-chevron-down);
      }

      &.expand-glyph::before {
        content: fa-content($fa-var-chevron-up);
      }
    }
  }
}

.content-marketing-promo-full {
  @extend .content-marketing-promo-single;

  > .container-with-header-slots {
    > .hero img {
      min-height: initial;
    }

    > .container-with-header {
      &:nth-child(2) {
        @include media-breakpoint-up(md) {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 2rem;
        }

        @include media-breakpoint-up(xl) {
          padding: 4rem;
        }
      }

      .container-with-header-title:empty {
        display: none;
      }

      .ms-accordion {
        margin-top: 2rem;
        .ms-accordion-item {
          border-style: solid;
          border-color: transparentize($color-link-accent, 0.6);
          border-width: 0 0 1px 0;

          &:first-child {
            border-top-width: 1px;
          }
        }
      }

      .ms-accordion-item button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0;
        border-width: 1px 0;
        border-style: solid;
        border-color: $color-border-inverse;
        font-size: $font-size-link-xl;
        letter-spacing: 1.35px;

        &::after {
          @extend %fa-icon;
          @extend .fas;
          content: fa-content($fa-var-chevron-down);
          font-size: $font-size-icon-s;
          color: $color-link-inverse;
          transition: all 0.3s ease;
        }

        &:hover,
        &:focus,
        &:active {
          &::after {
            color: $color-link-inverse-hover;
          }
        }
      }
    }
  }
}

.content-marketing-venue > .container-with-header-slots > {
  .content-marketing-venue-table table td:first-child {
    min-width: 100px;

    @include media-breakpoint-up(md) {
      min-width: 130px;
    }

    @include media-breakpoint-up(lg) {
      min-width: 140px;
    }

    @include media-breakpoint-up(xs) {
      min-width: 150px;
    }
  }

  .container-with-header {
    &:nth-child(3) {
      @include media-breakpoint-up(md) {
        flex: 0 1 40%;
        max-width: 40%;
        padding-left: 2rem;
        padding-right: 1rem;
        padding-top: 0;
      }

      @include media-breakpoint-up(xl) {
        padding-left: 4rem;
        padding-right: 2rem;
      }
    }

    &:nth-child(4) {
      @include media-breakpoint-up(md) {
        flex: 0 1 60%;
        max-width: 60%;
        padding-right: 2rem;
        padding-left: 1rem;
        padding-top: 0;
      }

      @include media-breakpoint-up(xl) {
        padding-right: 4rem;
        padding-left: 2rem;
      }
    }
  }
}

.content-marketing-venue-table {
  .content-block-with-title-text {
    overflow-x: auto;
  }

  table {
    width: 100%;

    th {
      padding: 0.5rem 0.2rem;
      font-size: $font-size-link-s;
      font-weight: normal;
      line-height: 1.3;
    }

    tr {
      &:first-child td {
        border-top: 1px solid $color-border-inverse;
      }

      td {
        min-height: 75px;
        padding: 1rem 0.5rem;
        border-bottom: 1px solid $color-border-inverse;

        a {
          color: $color-link-inverse-hover;
          font-size: $font-size-body-xxs;

          @include media-breakpoint-up(sm) {
            font-size: $font-size-body-xs;
          }

          @include media-breakpoint-up(md) {
            font-size: $font-size-body-s;
          }

          @include media-breakpoint-up(lg) {
            font-size: $font-size-body-m;
          }
        }

        .event-venues-room-name {
          line-height: 1.3;

          a {
            color: $color-link-inverse;

            &:hover,
            &:focus,
            &:active {
              color: $color-link-inverse-hover;
            }

            @include media-breakpoint-up(xl) {
              font-size: $font-size-body-l;
            }
          }
        }

        .callout-link a {
          @extend %link-marketing-inverse;
          color: $color-link-inverse-hover;
          font-size: $font-size-link-s;
        }
      }
    }
  }
}

.content-marketing-event > .container-with-header-slots {
  display: flex;
  flex-wrap: wrap;

  .ms-content-block {
    &__details {
      padding: 0.25rem;
      @include media-breakpoint-up(md) {
        padding: 0;
      }
    }
  }

  > * {
    flex: 0 0 100%;
  }

  > *:first-child {
    @include media-breakpoint-up(md) {
      flex: 0 1 33%;
      max-width: 33%;
      margin-top: 0.5rem;
      margin-right: 1.5rem;
    }

    img {
      @extend %image-marketing-max;
    }
  }

  > *:last-child {
    padding-top: 1rem;
    border-top: 1px solid $color-border--shop;

    @include media-breakpoint-up(md) {
      flex: 0 1 53%;
      max-width: 53%;
      padding-top: 0;
      margin-right: auto;
      border: 0;
    }
  }

  img {
    @extend %image-marketing;
  }

  h1 {
    color: $color-link--shop;
    font-size: $font-size-body-xl;

    @include media-breakpoint-up(md) {
      font-size: $font-size-heading-shop-xl;
    }
  }

  .tasting-tours {
    padding-top: 2rem;

    .tasting-tours-header {
      color: $color-link--shop;
      font-size: $font-size-heading-shop-s;
    }

    ul {
      font-size: $font-size-body-xxs;
    }

    .tasting-tours-app {
      padding: 1rem 0;
    }

    .rc-legend {
      display: none;
    }
    .errorBox[hidden] {
      display: block !important;
    }
    .errorBox:empty {
      display: none !important;
    }
  }
}

.content-marketing-honors > .container-with-header-slots {
  @extend %styles-marketing;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > * {
    flex: 0 0 100%;

    &:not(:first-child) {
      margin-top: 1rem;

      @include media-breakpoint-up(md) {
        margin-top: 0.5rem;
      }
    }
  }

  > .hero,
  > .ms-content-block {
    position: relative;

    > .hero-picture,
    > .ms-content-block__image > img {
      @extend %image-marketing-max;
    }

    > .hero-text-alignment-container,
    > .ms-content-block__details {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0.5rem;
      color: $color-heading-title;
      text-shadow: 0 0 5px $color-black;
      text-align: center;

      @include media-breakpoint-up(sm) {
        padding: 1rem;
      }

      @include media-breakpoint-up(xl) {
        padding: 2rem;
      }

      > *:last-child > *:last-child {
        margin-bottom: 0;
      }

      h1 {
        font-size: $font-size-heading-xxl;

        @include media-breakpoint-up(lg) {
          font-size: $font-size-heading-xl * 2;
        }
      }

      h2 {
        font-size: $font-size-heading-m;
      }
    }
  }
}

.content-marketing-honors-double,
.content-marketing-honors-quadruple {
  @extend .content-marketing-honors;
  .ms-content-block__text {
    margin-top: 0.5rem;
  }
}

.content-marketing-honors-double > .container-with-header-slots > * {
  @include media-breakpoint-up(lg) {
    flex: 0 1 49.5%;
    max-width: 49.5%;
  }

  &:nth-child(2) {
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
}

.content-marketing-honors-quadruple > .container-with-header-slots > * {
  flex: 0 1 49.5%;
  max-width: 49.5%;

  @include media-breakpoint-up(md) {
    flex: 0 1 24.25%;
    max-width: 24.25%;
  }

  &:not(:first-child) {
    margin-top: 0.5rem;
  }

  &:nth-child(2) {
    margin-top: 0;
  }

  &:nth-child(3),
  &:nth-child(4) {
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }
  .ms-content-block__image img {
    @extend %image-marketing-max;
  }
}

.content-marketing-vineyards-map .ms-content-block__details {
  padding: 0;

  p {
    margin: 0;
  }

  a {
    position: absolute;
    padding: 0.1rem 0.25rem;
    background-color: $color-button-inverse-background;
    color: $color-button-inverse-text;
    font-size: $font-size-link-s - 4px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 1.2;
    text-transform: uppercase;
    transition: all 0.3s ease;

    @include media-breakpoint-up(sm) {
      padding: 0.1rem 0.3rem;
      font-size: $font-size-link-s - 3px;
    }

    @include media-breakpoint-up(md) {
      padding: 0.15rem 0.4rem;
      font-size: $font-size-link-s - 2px;
    }

    @include media-breakpoint-up(lg) {
      padding: 0.2rem 0.45rem;
      font-size: $font-size-link-s - 1px;
    }

    @include media-breakpoint-up(xl) {
      padding: 0.25rem 0.5rem;
      font-size: $font-size-link-s;
    }

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent $color-button-inverse-background;
      transition: all 0.3s ease;
    }

    &:not([href]):not([tabindex]),
    &:hover,
    &:focus,
    &:active {
      background-color: $color-background-accent;
      color: $color-button-inverse-text;

      &::before,
      &::after {
        border-color: transparent $color-background-accent;
      }
    }

    &.vineyards-map-chateau-ste {
      top: 30%;
      left: 33%;
    }

    &.vineyards-map-indian-wells {
      top: 62%;
      right: 40%;
    }

    &.vineyards-map-cold-creek {
      top: 65%;
      left: 62%;
    }

    &.vineyards-map-horse-heaven {
      top: 83%;
      right: 35%;
    }

    &.vineyards-map-canoe-ridge {
      top: 88%;
      left: 61%;
    }

    &.vineyards-map-chateau-ste,
    &.vineyards-map-cold-creek,
    &.vineyards-map-canoe-ridge {
      &::before {
        content: "";
        left: -5px;
        border-width: 5px 5px 5px 0;
      }
    }

    &.vineyards-map-indian-wells,
    &.vineyards-map-horse-heaven {
      &::after {
        content: "";
        right: -5px;
        border-width: 5px 0 5px 5px;
      }
    }
  }
}

//==============================================================================
// HERO MODULES
//==============================================================================

.marketing-home {
  .carousel,
  .msc-carousel {
    width: 100%;
    img {
      @extend %image-marketing-max;
    }

    .hero,
    .ms-content-block {
      position: relative;

      %hero-gradient {
        @include media-breakpoint-up(lg) {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      .hero-picture.image-gradient-radial-dark::before,
      &.gradient-top .ms-content-block__image::after,
      &.gradient-bottom .ms-content-block__image::after {
        @extend %hero-gradient;
      }

      &.gradient-top .ms-content-block__image::after {
        @include media-breakpoint-up(lg) {
          background-image: linear-gradient(
            0deg,
            transparent 0%,
            transparentize($color-black, 0.2) 85%
          );
        }
      }

      &.gradient-bottom .ms-content-block__image::after {
        @include media-breakpoint-up(lg) {
          background-image: linear-gradient(
            0deg,
            transparentize($color-black, 0.05) 0%,
            transparent 50%
          );
        }
      }

      .ms-content-block__image picture {
        position: relative;
      }

      &.textplacement__left .ms-content-block__details {
        @include media-breakpoint-up(lg) {
          left: 0;
          transform: none;
          margin-left: 2rem;
          text-align: left;

          .ms-content-block__cta {
            justify-content: flex-start;
          }
        }
      }

      &.textplacement__right .ms-content-block__details {
        @include media-breakpoint-up(lg) {
          left: unset;
          right: 0;
          transform: none;
          margin-right: 2rem;
          text-align: right;

          .ms-content-block__cta {
            justify-content: flex-end;
          }
        }
      }

      &.textplacement__v-center .ms-content-block__details {
        @include media-breakpoint-up(lg) {
          top: 50%;
          transform: translate(-50%, -50%);
          margin-top: 0;
        }
      }

      &.textplacement__left.textplacement__v-center .ms-content-block__details,
      &.textplacement__right.textplacement__v-center .ms-content-block__details {
        @include media-breakpoint-up(lg) {
          transform: translateY(-50%);
        }
      }

      &.textplacement__v-bottom .ms-content-block__details {
        @include media-breakpoint-up(lg) {
          top: unset;
          bottom: 0;
          margin-top: 0;
          margin-bottom: 2rem;
        }
      }

      .hero-text-alignment-container,
      .ms-content-block__details {
        @extend %styles-marketing;
        position: relative;
        padding: 2rem 1rem;
        margin-bottom: 2rem;
        text-shadow: 0 0 5px $color-black;
        text-align: center;
        color: $color-text-accent;

        @include media-breakpoint-up(sm) {
          padding: 2rem;
        }

        @include media-breakpoint-up(lg) {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          max-width: 600px;
          margin-bottom: 0;
          margin-top: 2rem;
        }

        .ms-content-block__cta {
          display: flex;
          justify-content: center;
          margin-top: 1rem;

          > *:not(:last-child) {
            margin-right: 0.5rem;
          }
        }

        .hero-cta a,
        .hero-cta button,
        .ms-content-block__cta a,
        .ms-content-block__cta button {
          padding: 0.5rem 1.5rem;
          border: 1px solid $color-link-hover;
          color: $color-link-hover;
          text-transform: none;
          font-weight: bold;
          letter-spacing: 0.85px;
          font-size: $font-size-link-m;
          line-height: 1.5;

          &:hover,
          &:focus,
          &:active {
            border-color: $color-link;
            color: $color-link;
          }
        }
      }

      &.texttheme__dark .ms-content-block__details {
        @include media-breakpoint-up(lg) {
          text-shadow: none;
          color: $color-text-inverse;
        }

        .hero-heading,
        .ms-content-block__title,
        .ms-content-block__text {
          @include media-breakpoint-up(lg) {
            color: $color-black;
          }
        }

        .ms-content-block__text a {
          @include media-breakpoint-up(lg) {
            color: $color-link-inverse;

            &:hover,
            &:focus,
            &:active {
                color: $color-link-inverse-hover;
            }
          }
        }

        .hero-cta a,
        .hero-cta button,
        .ms-content-block__cta a,
        .ms-content-block__cta button {
          @include media-breakpoint-up(lg) {
            border-color: $color-black;
            color: $color-black;
          }

          &:hover,
          &:focus,
          &:active {
            @include media-breakpoint-up(lg) {
              border-color: $color-link-inverse;
              color: $color-link-inverse;
            }
          }
        }
      }
    }
  }
  .content-marketing-promo-double > .container-with-header-slots {
    .ms-content-block {
      height: 100%;
    }
    .ms-content-block__details {
      p {
        @extend %text-marketing-heading-appellation;
        color: $color-kabul;
      }
    }
  }

  #main {
    background-image: none;

    > *:not(:first-child) {
      background-color: $color-background-body;
      width: 100%;
      max-width: none;
    }

    .content-marketing {
      padding: 0;
      margin: 0;

      > .content-block-with-title-title:empty {
        display: none;
      }

      > .content-block-with-title-text {
        @extend %width-marketing-margin;
        display: flex;
        justify-content: center;
        padding: 2rem 1rem;
        text-align: center;

        @include media-breakpoint-up(md) {
          padding: 2rem 3rem;
        }

        @include media-breakpoint-up(lg) {
          padding: 3rem 4rem;
        }

        @include media-breakpoint-up(xl) {
          padding: 4rem 10rem;
        }

        h2 {
          color: $color-heading-title-inverse-accent;
          font-size: $font-size-featured-m;
          line-height: 1.4;
        }

        > * {
          max-width: 650px;
        }
      }
    }

    .content-marketing-promo-double,
    .content-marketing-promo-triple {
      margin: 0;
      padding: 0 0 3rem;

      > * {
        @extend %width-marketing-content;
      }
    }
  }
}

.hero-marketing-home {
  @extend %width-marketing-hero-home;

  &.carousel-item {
    margin-right: -100%;
  }
}

%hero-marketing-top-text {
  @extend %width-marketing-content;
  position: relative;
  padding: 14rem 1rem 0;
  color: $color-text-accent;

  @include media-breakpoint-up(md) {
    padding: 8rem 0;
  }

  > * {
    max-width: 400px;
  }

  h1 {
    font-size: $font-size-heading-m;

    @include media-breakpoint-up(xl) {
      font-size: $font-size-heading-xxl;
    }
  }

  p {
    opacity: 0.75;
  }
}

.hero-marketing-top {
  @extend %width-marketing-hero-top;

  .hero-text-alignment-container,
  .ms-content-block__details {
    @extend %hero-marketing-top-text;
  }
}

.hero-marketing-top-carousel {
  .msc-carousel__indicators {
    bottom: -2rem;

    @include media-breakpoint-up(sm) {
      bottom: 0;
    }
  }

  .msc-carousel__inner {
    @include media-breakpoint-up(sm) {
      height: 500px;
    }

    @include media-breakpoint-up(lg) {
      height: 550px;
    }

    @include media-breakpoint-up(xl) {
      height: 600px;
    }

    .hero-picture img,
    .ms-content-block__image img {
      height: 200px;

      @include media-breakpoint-up(sm) {
        height: 575px;
      }

      @include media-breakpoint-up(md) {
        height: 675px;
      }

      @include media-breakpoint-up(lg) {
        height: 775px;
      }
    }
  }
}

.hero-marketing-top-video {
  text-shadow: 0 0 5px $color-black;

  .container-with-header-title:empty {
    display: none;
  }

  > .container-with-header-slots {
    > .hero-marketing-top {
      position: absolute;
    }

    > .container-with-header {
      @extend %hero-marketing-top-text;
      position: relative;
    }
  }

  .youtube-modal-clickable {
    margin-top: 2rem;

    .youtube-modal-textblock {
      display: flex;
      align-items: center;

      > *,
      &::before {
        transition: all 0.3s ease;
      }

      > * {
        @extend %link-marketing;
        margin: 0;
      }

      &::before {
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-play-circle);
        margin-right: 0.5rem;
        color: $color-link;
        font-size: $font-size-icon-m;
      }

      &:hover,
      &:focus,
      &:active {
        > *,
        &::before {
          color: $color-link-hover;
        }
      }
    }
  }
}

.hero-marketing-collection {
  @extend %width-marketing-hero-collection;

  .hero-text-alignment-container,
  .ms-content-block__details {
    padding: 2rem 1rem;
    color: $color-text-accent;
    .ms-content-block__cta {
      a.msc-cta__primary {
        color: $color-pampas;
        border: none;
        background-color: transparent;
        font-size: 12.5px;
        font-weight: 700;
        padding-left: 0px;
        margin-top: 0.3rem;
      }
      a:not([href]) {
        font-size: 12.5px;
        color: $color-pampas;
      }
    }

    @include media-breakpoint-up(sm) {
      padding: 2rem;
    }

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      padding: 1rem;
      margin-left: 3rem;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 5%;
    }

    @include media-breakpoint-up(xl) {
      margin-left: 15%;
    }

    > * {
      @include media-breakpoint-up(md) {
        max-width: 350px;
      }
    }

    .hero-cta a,
    .hero-cta button {
      @extend %link-marketing;
      color: $color-link-hover;
    }
  }
}

//==============================================================================
// REUSABLE ELEMENTS
//==============================================================================

.image-marketing-responsibility {
  .ms-content-block {
    &__image img {
      width: 160px !important;
      height: auto !important;
      min-height: auto !important;
    }
    &__details {
      padding: 0.25rem;
    }
  }
}

//==============================================================================
// MISC ELEMENTS
//==============================================================================

.MsoTableGrid {
  margin-bottom: 1rem;
  font-size: $font-size-body-xxs;
  line-height: 1.5;

  td {
    border: 1px solid $color-border-inverse;
    width: 50%;
    vertical-align: top;
    padding: 0.25rem 0.4rem;
  }

  p:last-child {
    margin-bottom: 0;
  }
}
.back-to-venue {
  .ms-content-block__details {
    padding: 0 !important;
    position: relative !important;
    h1 {
      display: none;
    }
    .msc-cta__primary {
      @extend %link-marketing-inverse;
      margin-top: 0.75rem;
    }
  }
}
.mb-5 {
  .ms-content-block__details {
    .msc-cta__primary {
      @extend .btn-primary;
      display: inline-block;
      font-weight: 500;
    }
  }
}
.content-marketing-venue-table {
  padding-top: 0;
  .ms-content-block__image {
    img {
      width: auto !important;
    }
  }
  .ms-content-block__details {
    padding: 0.25rem;
  }
}
.content-marketing-promo-single {
  .ms-accordion {
    .ms-accordion-item {
      border-style: solid;
      border-color: transparentize($color-link-accent, 0.6);
      border-width: 0 0 1px 0;

      &:first-child {
        border-top-width: 1px;
      }
    }
    .drawer__button {
      @extend %link-marketing-inverse;
      display: flex;
      justify-content: space-between;
      padding: 1.25rem 0;
      font-size: $font-size-body-xxs;
      text-align: left;
      .__start {
        margin-right: 0.5rem;
      }
    }
  }
  .ms-accordion-item button[aria-expanded="false"] {
    &::after {
      content: fa-content($fa-var-chevron-down);
    }
  }
  .ms-accordion-item button[aria-expanded="true"] {
    &::after {
      content: fa-content($fa-var-chevron-up);
    }
  }
  .ms-accordion-item button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-width: 1px 0;
    border-style: solid;
    border-color: $color-border-inverse;
    font-size: $font-size-link-xl;
    letter-spacing: 1.35px;

    &::after {
      @extend %fa-icon;
      @extend .fas;
      content: fa-content($fa-var-chevron-down);
      font-size: $font-size-icon-s;
      color: $color-link-inverse;
      transition: all 0.3s ease;
    }
    &.collapse-glyph::after {
      content: fa-content($fa-var-chevron-down);
    }

    &.expand-glyph::after {
      content: fa-content($fa-var-chevron-up);
    }
    &:hover,
    &:focus,
    &:active {
      &::after {
        color: $color-link-inverse-hover;
      }
    }
  }
  .content-marketing-venue-table + .msc-carousel {
    .ms-content-block__details {
      display: none;
    }
  }
}
.rc-logo-reserve {
  width: 40px !important;
}
#rc-portal img {
  width: auto;
}
.content-marketing-promo-club {
  .ms-content-block__cta {
    margin-top: 1px;
  }
}
.content-marketing-promo-full {
  .container-with-header-slots {
    .ms-content-block__image img {
      min-height: initial;
    }
  }
}
.content-marketing-mailing-list {
  background-color: $color-background-inverse;
  .col-12 {
    padding: 1rem;
  }
  .content-block-with-title-title {
    color: $color-text-accent--shop;
  }
  p {
    color: $color-text-inverse;
  }
  .mailing-list-form-list {
    @include media-breakpoint-up(md) {
      margin-left: 2rem;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 5rem;
    }
  }

  .text-input-group {
    label {
      margin-bottom: 0.25rem;
      color: $color-text-inverse;
      font-weight: bold;
    }

    input.form-control {
      @include form-input-el();
    }
  }

  .text-input-validation-error {
    margin-top: 0.5rem;
  }

  .input-group-mailing-list-button {
    @extend .btn;
    @extend .btn-primary;
  }

  .mailing-list-success-message {
    margin-top: 0.5rem;

    p {
      display: inline-block;
    }
  }
}

//==============================================================================
// CUSTOM HELPER CLASSES
//==============================================================================
.pb-dot-3 {
  padding-bottom: 0.3rem;
}
