.ms-nav {
  text-align: left;
  margin-top: 3rem;
  position: absolute;
  width: 100%;
  border-bottom: 1px solid $color-kabul;
  @include media-breakpoint-up(lg) {
    text-align: right;
    margin: 0;
    position: relative;
    border: none;
  }
  &__list {
    margin: 0;
    &:first-child {
      padding: 1rem 2rem;
      width: 100%;
      background-color: $color-oil;
      @include media-breakpoint-up(lg) {
        border-top: 1px solid $color-kabul;
        display: inline-flex;
        padding: 0;
        width: auto;
      }
      li {
        @include media-breakpoint-up(lg) {
          position: relative;
        }
        .ms-nav__list__item__button {
          @extend %link-header;
          padding: 0.5rem 0;
          letter-spacing: 0.85px;
          width: 100%;
          @include media-breakpoint-up(lg) {
            padding: 1.25rem 1rem;
            width: auto;
          }
          &[aria-expanded="true"] {
            color: $color-white;
          }
          &:focus {
            outline-width: 0;
            @include media-breakpoint-up(lg) {
              outline-width: 1px;
            }
          }
        }
        &:first-child {
          .ms-nav__list__item__button {
            @include media-breakpoint-up(lg) {
              padding-left: 0;
            }
          }
        }
        &:last-child {
          .ms-nav__list__item__button {
            padding-right: 0;
          }
        }
      }
    }
    &__item {
      &:nth-child(4) {
        ul {
          @include media-breakpoint-up(lg) {
            min-width: 15rem;
          }
        }
      }
      ul {
        @include media-breakpoint-up(lg) {
          @include fadeIn(0.3s ease all);
          display: block;
          left: 50%;
          min-width: intrinsic; /* Safari/WebKit */
          min-width: -moz-max-content; /* Firefox/Gecko */
          min-width: -webkit-max-content; /* Chrome */
          min-width: max-content;
          transform: translateX(-50%);
          padding: 0.75rem 0;
          margin: 0;
          border: 1px solid $color-kabul;
          border-radius: 0;
          background-color: $color-oil;
          position: absolute;
          transition: visibility 0s, opacity 0.5s linear;
          top: 100%;
          z-index: 1000;
          float: left;
          font-size: 1rem;
          color: #212529;
          text-align: left;
          list-style: none;
          background-clip: padding-box;
          li {
            position: static !important;
          }
        }
        li {
          width: 100%;
          .ms-nav__list__item__link {
            @extend %link-subheader;
            padding: 0.5rem 0 0.75rem;
            line-height: 1;
            display: block;
            @include media-breakpoint-up(lg) {
              padding: 0.25rem 1.5rem;
              line-height: 1.6;
            }
          }
        }
      }
      &:last-child {
        ul {
          transform: none;
          right: 0;
          left: inherit;
        }
      }
    }
  }
  .collapse:not(.show) {
    display: block;
  }
  .selected {
    color: $color-pampas !important;
  }
}

// Mobile Navigation
.navbar-collapse {
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
  @include transition(all 0.5s ease);
  &.nav-open {
    @include transition(all 0.5s ease);
    max-height: 696px;
    visibility: visible;
  }
}
.nav-open,
.subnav-show {
  display: block !important;
}
.subnav-menu {
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
  @include transition(all 0.5s ease);
  &.subnav-show {
    padding-left: 0;
    @include transition(all 0.5s ease);
    visibility: visible;
  }
  .ms-nav__list {
    padding: 0;
  }
}
.mobile-vp {
  .ms-nav__list {
    .ms-nav__list__item {
      .subnav-hide {
        max-height: 0;
      }
      .subnav-show {
        max-height: var(--subnavHeight);
      }
    }
  }
}
@media (max-width: ($msv-breakpoint-l - 1)) {
  .subnav-show {
    .ms-nav {
      text-align: left;
      &__list {
        margin: 0;
        &:first-child {
          padding: 0;
          li {
            padding: 0;
            .ms-nav__list__item__button {
              padding: 0;
            }
            &:first-child {
              ul {
                padding: 0;
                li {
                  padding: 0.5rem 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
